import { Api, ApiResponse, PagedData } from './Api';

export interface ProductModel {
  id: string;
  productNumber?: string;
  description?: string;
  type?: string;
  liftPowerNumber?: number;
  reachLengthNumber?: number;
  weightNumber?: number;
  workHeightNumber?: number;
  productGroup: ProductGroupModel;
  productTariff: ProductTariffModel;
  productDiscountTariff?: ProductDiscountTariffModel;
  productImage?: ProductImageModel;
}

export interface ProductGroupModel {
  id?: string;
  description?: string;
}

export interface ProductTariffModel {
  hourlyTariff: number;
  dailyTariff: number;
  weeklyTariff: number;
  monthlyTariff: number;
}

export interface ProductDiscountTariffModel extends ProductTariffModel {
  discountPercentage: number;
}

export interface ProductImageModel {
  uri?: string;
}

export interface ProductFilterParams {
  pageSize?: number;
  nextToken?: string;
  productNumber?: string;

  search?: string;
  productGroup?: string;
  workHeightSteps?: string;
  reachLengthSteps?: string;
  liftPowerSteps?: string;
  weightSteps?: string;
  driveTypes?: string;
}

export enum ProductStepType {
  ZeroToFive,
  ZeroToOneHundred,
  ZeroToOneThousand,
  FiveToTen,
  TenToFifteen,
  FifteenToTwenty,
  FifteenToTwentyFive,
  TwentyToFifty,
  TwentyFiveToFifty,
  FiftyToOneHundred,
  OneHundredToTwoHundredAndFifty,
  TwoHundredAndFiftyToFiveHundred,
  FiveHundredToOneThousand,
  OneThousandToTwoThousand,
  TwoThousandToThreeThousandFiveHundred,
  ThreeThousandFiveHundredToTenThousand,
  OverTenThousand
}

export interface ProductStep {
  min: number;
  max: number;
  type: ProductStepType;
}

export enum DriveTypes {
  Electrical, Diesel, Hybrid, LngCngLpg, Other, NotApplicable
}

export interface ProductFilterModel {
  reachLengthSteps: ProductStep[];
  workHeightSteps: ProductStep[];
  liftPowerSteps: ProductStep[];
  weightSteps: ProductStep[];
  productGroups: ProductGroupModel[];
  driveTypes: DriveTypes[];
}

export const productFilterParams: Record<keyof ProductFilterModel, string> = {
  reachLengthSteps: 'reachLengthSteps',
  driveTypes: 'driveTypes',
  liftPowerSteps: 'liftPowerSteps',
  productGroups: 'productGroups',
  weightSteps: 'weightSteps',
  workHeightSteps: 'workHeightSteps'
};

export class ProductService extends Api {
  getProducts = async (params?: ProductFilterParams) => {
    return this.get<ApiResponse<PagedData<ProductModel>>>(
      '/api/products',
      {},
      this.normalizeQueryParameters(params)
    );
  };

  getProductByProductNumber = async (productNumber: string) => {
    return this.get<ApiResponse<ProductModel>>(
      `/api/products/number/${productNumber}`,
      {}
    );
  };

  getProductFilters = async () => {
    return this.get<ApiResponse<ProductFilterModel>>(
      'api/products/filters',
      {}
    );
  };
}
