import { AddCircleOutline } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import theme from '../../theme';
import Breadcrumb from './navigation/Breadcrumb';

interface PageTitleProps {
  title?: string;
  buttonTitle?: string;
  buttonRoute?: string;
  showButton?: boolean;
  showBreadcrumbs?: boolean;
  children?: ReactNode;
}

export default function PageTitle({
                                    title,
                                    buttonTitle,
                                    children,
                                    buttonRoute,
                                    showButton,
                                    showBreadcrumbs = true
                                  }: PageTitleProps) {
  const history = useHistory();

  const routeChange = () => {
    history.push(buttonRoute || '');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 0 100%',
        flexDirection: 'row',
        marginTop: 2,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={title ? 4 : 3}>
          {title ? (
            <Typography variant="h1">{title}</Typography>
          ) : (
            <div style={{ height: 35 }} />
          )}
          {showBreadcrumbs && <Breadcrumb />}
        </Grid>
        <Grid item xs={12} md={6}>
          {children ? <Box>{children}</Box> : ''}
        </Grid>
        <Grid item xs={12} md={title ? 2 : 3}>
          {buttonTitle && showButton && (
            <Box sx={{ justifyContent: 'end', display: 'flex' }}>
              <IconButton
                sx={{
                  marginTop: 1.5,
                  borderRadius: 3,
                  color: theme.palette.primary.main,
                  ':hover': {
                    color: 'white'
                  }
                }}
                size="small"
                onClick={routeChange}
              >
                {buttonTitle} <AddCircleOutline />
              </IconButton>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
