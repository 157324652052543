import { ProductModel } from '../services/ProductService';
import { OrderModel } from '../services/models/Offer';

export const getDailyPrice = (product: ProductModel) => {
  return getPrice(product.productTariff.dailyTariff, product.productDiscountTariff?.dailyTariff, product.productDiscountTariff?.discountPercentage);
};

export const getWeeklyPrice = (product: ProductModel) => {
  return getPrice(product.productTariff.weeklyTariff, product.productDiscountTariff?.weeklyTariff, product.productDiscountTariff?.discountPercentage);
};

export const getTotalPrice = (order?: OrderModel) => {
  return getPrice(order?.amount || 0);
};

const getPrice = (tariff: number, discountTariff?: number, discountPercentage?: number): number => {
  if (discountPercentage && discountPercentage === 0.0 && discountTariff && discountTariff !== 0.0) {
    return discountTariff;
  }
  if (discountPercentage && discountPercentage !== 0.0) {
    return tariff - (tariff * (discountPercentage / 100));
  }
  return tariff;
}
