import { CardContent } from "@material-ui/core";
import { Card, CardActions, Modal, SxProps, Theme } from "@mui/material"
import { PropsWithChildren, ReactNode } from "react"
import { colors } from "../../theme";

interface ModalProps {
  isOpen: boolean;
  actions?: ReactNode;
  onClose?: ((event: unknown, reason: "backdropClick" | "escapeKeyDown") => void)
}

const modalStyle : SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  backgroundColor: colors.white
};

const migrateLaterStyles = {
  padding: 20
}

const PageModal = ({ isOpen, actions, onClose, children } : PropsWithChildren<ModalProps>) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Card sx={modalStyle}>
        <CardContent style={migrateLaterStyles}>
          {children}
        </CardContent>
        <CardActions style={{ ...migrateLaterStyles, paddingTop: 0 }}>
          {actions}
        </CardActions>
      </Card>
    </Modal>
  )
}

export default PageModal;
