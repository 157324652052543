import { setHours, setMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTimeFromDate, getMsFromTimeString } from '../utils/dateFormat';

type DateErrorHelper = {
  error: boolean;
  errorText: string;
};

interface DateTimeData {
  startDate: number | null;
  setStartDate: (date: number) => void;
  endDate: number | null;
  setEndDate: (date: number | null) => void;
  startTime: string;
  setStartTime: (time: string) => void;
  endTime: string | null;
  setEndTime: (time: string | null) => void;

  dateError: DateErrorHelper;
  setDateError: (error: DateErrorHelper) => void;
  resetDateError: () => void;
  resetEndDate: () => void;
  validate: () => boolean;
}

const initialErrorState : DateErrorHelper = {
  error: false,
  errorText: ''
};

const defaultEndTime = '17:00';

export function useDateTime(
  cartStartTime: number | null,
  cartEndTime: number | null,
  setCartStart: (start: number) => void,
  setCartEnd: (end: number | null) => void,
): DateTimeData {

  const [startDate, setStartDate] = useState<number | null>(null); // epoch timestamp
  const [startTime, setStartTime] = useState<string>(getTimeFromDate(cartStartTime ?? null));

  const [endDate, setEndDate] = useState<number | null>(null); // hh:mm
  const [endTime, setEndTime] = useState<string | null>(
    cartEndTime ? getTimeFromDate(cartEndTime) : defaultEndTime
  );

  const [dateError, setDateError] = useState<DateErrorHelper>(initialErrorState);

  const { t } = useTranslation('common');

  const getCombinedDateTime = (date: number, time: string) => {
    const [hours, minutes] = time.split(':');
    return setHours(setMinutes(date, Number(minutes)), Number(hours));
  }

  useEffect(() => {
    setStartDate(cartStartTime || setHours(new Date(), 0).getTime());
    setEndDate(cartEndTime || null);
  }, []);

  useEffect(() => {
    if (startDate) {
      setCartStart(getCombinedDateTime(startDate, startTime).getTime());
    }
  }, [startDate, startTime]);

  useEffect(() => {
    if (endDate && endTime) {
      setCartEnd(getCombinedDateTime(endDate, endTime).getTime());
    } else {
      setCartEnd(null);
    }
  }, [endDate, endTime]);

  const validate = () => {
    if (startDate && startTime && endDate && endTime) {

      if (
        startDate + getMsFromTimeString(startTime) >
        endDate + getMsFromTimeString(endTime)
      ) {
        setDateError({
          error: true,
          errorText: t('error.dates')
        });
        return false;
      }
    }

    setDateError(initialErrorState);
    return true;
  };

  const resetDateError = () => setDateError(initialErrorState);

  const resetEndDate = () => {
    setEndDate(null);
    setEndTime(defaultEndTime);
  }

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startTime,
    setStartTime,
    endTime,
    setEndTime,

    dateError,
    setDateError,
    resetDateError,
    resetEndDate,
    validate
  };
}
