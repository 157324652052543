import { Api, ApiResponse, PagedData, QueryOptions } from './Api';
import { AddressModel } from './SiteService';

export interface OrganizationModel{
  id?: string;
  name?: string;
  relationNumber?:	string;
  phoneNumber?:	string;
  billingAddress: AddressModel;
}

export interface RelationIdsQuery {
  relationIds: string[];
}

export class OrganizationService extends Api {
  getOrganizationsByParams = async (params: QueryOptions) => {
    return this.get<ApiResponse<PagedData<OrganizationModel>>>(
      '/api/organizations', {},
      params
    );
  };

  getOrganizationById = async (id: string) => {
    return this.get<ApiResponse<OrganizationModel>>(
      `/api/organizations/${id}`
    );
  };

  getOrganizationByRelationId = async (id: string) => {
    return this.get<ApiResponse<OrganizationModel>>(
      `/api/organizations/relation/${id}`
    );
  };

  getMultipleOrganizationsByRelationIds = async (query: RelationIdsQuery) => {
    return this.get<ApiResponse<OrganizationModel[]>>(
      `/api/organizations/relations`, {},
      query
    );
  }
}
