import { Card, Grid, Typography, Box, Button } from '@mui/material';
import { initContact, initSite, useCart } from '../../../hooks/useCart';
import BlueBox from '../../layout/BlueBox';
import { useHistory, useParams } from 'react-router-dom';
import { usePopup } from '../../../hooks/usePopupMessage';
import { ChangeEvent, useEffect, useState } from 'react';
import { useKeycloak } from '../../../hooks/useKeycloak';
import { ProductModel } from '../../../services/ProductService';
import { generateClusterIdHash } from '../../../utils/clusterIdHash';
import { useConfirmCart, useSaveCart, useSaveJobSurvey } from '../../../hooks/api/useCheckout';
import { useGetOfferByClusterIdLazy } from '../../../hooks/api/useOffers';
import { currencyFormat } from '../../../utils/currencyFormat';
import Loader from '../../common/Loader';
import { CheckoutStepProps, CheckoutStepType } from './index';
import { useTranslation } from 'react-i18next';
import CheckoutStep from './CheckoutStep';
import { CartItem, CartModel, SaveCartModel } from '../../../hooks/models/cart';
import TextFieldShrink from '../../layout/TextFieldShrink';
import { CardContent } from '@material-ui/core';
import CartItemDetailRow from '../../common/CartItemDetailRow';
import { formatToLocalTime } from '../../../utils/dateFormat';
import { useJobSurvey } from '../../../hooks/useJobSurvey';
import { SiteModel } from '../../../services/SiteService';
import { ApiResponse } from '../../../services/Api';
import { useCreateSite } from '../../../hooks/api/useSites';
import { RouteParams } from '../../layout/navigation/Breadcrumb';
import { OfferModel, OfferStatus, UsageType, OfferType } from '../../../services/models/Offer';
import { cloneDeep, isEqual } from 'lodash';

export default function VerifyCheckout({ onPrevious, type = 'cart' }: CheckoutStepProps) {
  const { id } = useParams<RouteParams>();
  const { cart, resetCart, offers, setOffers, setCart, setCartItemsClone, cartItemsClone, customerReferenceChanged, setCustomerReferenceChanged } = useCart();
  const { jobSurvey, setJobSurveyCustomerReference } = useJobSurvey();

  const cartMutation = useSaveCart();
  const jobSurveyMutation = useSaveJobSurvey(id || '');
  const confirmCartMutation = useConfirmCart();
  const siteService = useCreateSite();

  const { relationNumber } = useKeycloak();
  const { setMessage } = usePopup();
  const history = useHistory();

  const [hash, setHash] = useState<string>('');
  const [context, setContext] = useState<CartModel>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [priceCalculated, setPriceCalculated] = useState(false);
  const [customerReferenceTooLong, setCustomerReferenceTooLong] = useState(false);

  const [offerClusterId, setOfferClusterId] = useState<string | undefined>(
    offers.length > 0 ? offers[0].clusterIdentifier : undefined
  );
  const { query: offerQuery } = useGetOfferByClusterIdLazy(offerClusterId || '');
  const { data } = offerQuery;

  const { t } = useTranslation(['common', 'checkout', 'products', 'orders']);
  const { isAccountManager } = useKeycloak();

  // const isJobSurveyOwner = false;

  useEffect(() => {
    setCustomerReferenceTooLong((cart.customerReference?.length ?? 0) > 30);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEqual(cart.items, cartItemsClone)) {
      setPriceCalculated(true);
    }

    handleContext();
  }, [jobSurvey, cart]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOffers(data && data.result ? data.result.entries : []);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hash === '' && id) {
      setHash(id);
      return;
    }
    if (hash === '') {
      generateClusterIdHash(relationNumber + String(Date.now())).then((clusterId) => {
        setHash(clusterId);
      });
    }
  }, [hash]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleContext = () => {
    if (type === 'cart') {
      setContext(cart);
    }

    if (type === 'job-survey') {
      setContext(jobSurvey);
    }
  };

  const handleConfirm = (): void => {
    setIsSubmitting(true);
    const model = createCartModel();

    if (model.site.id === undefined || model.site.id === '' || model.site.id === '-1') {
      model.site.name = `${model.site.street} ${model.site.city}`;
      siteService.mutate(model.site,
        {
          onSuccess: (response: ApiResponse<SiteModel>) => {
            model.site.siteNumber = response.result.siteNumber;
          }
        }
      );
    }

    let returnUrl = '';

    if (type === 'cart') {
      returnUrl = '/huuropdrachten/' + hash;
    }

    if (type === 'job-survey') {
      if (isAccountManager()) {
        returnUrl = '/werkopnames/' + hash;
      }
    }

    const options = {
      onSuccess: () => {
        resetCart();
        setCustomerReferenceChanged(false);

        history.push(returnUrl);
        setMessage({
          message: t('checkout:verification.create-offer.success'),
          type: 'success',
          open: true
        });
        setIsSubmitting(false);
      },
      onError: () => {
        setMessage({
          message: t('checkout:verification.create-offer.error'),
          type: 'error',
          open: true
        });
        setIsSubmitting(false);
      }
    };

    if (priceCalculated && !customerReferenceChanged && type === 'cart') {
      const confirmModel = { offerIds: offers.map((o) => o.id || '') };

      if (type === 'cart') {
        confirmCartMutation.mutate(confirmModel, options);
      }
    } else {
      model.confirmImmediately = true;

      switch (type) {
        case 'job-survey': {
          if (isAccountManager()) {
            jobSurveyMutation.mutate(model, options);
          }

          // if (isJobSurveyOwner) {
          //   cartMutation.mutate(model, options);
          // }
          break;
        }
        case 'cart': {
          cartMutation.mutate(model, options);
          break;
        }
      }
    }
  };

  const handleGetProjectPrice = () => {
    setCartItemsClone(cloneDeep(cart.items));
    setCustomerReferenceChanged(false);

    const model = createCartModel();

    if (!priceCalculated) {
      cartMutation.mutate(model, {
        onSuccess: () => {
          setOfferClusterId(hash);
        },
        onError: () => {
          setMessage({
            message: t('checkout:verification.retrieve-estimated-price-error'),
            type: 'error',
            open: true
          });
        }
      });
    }
  };

  const handleCustomerReferenceChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCustomerReferenceChanged(true);
    setCustomerReferenceTooLong(e.target.value.length > 30);

    if (!id) {
      setHash('');
    }

    if (type === 'cart') {
      setCart({
        ...cart,
        customerReference: e.target.value ?? null
      });
    }

    if (type === 'job-survey') {
      setJobSurveyCustomerReference(e.target.value);
    }
  };

  const createCartModel = () => {
    const offers = context?.items
      .map((item) => {
        return createOfferModel(item.product, 1, item.start, item.end, item.alternateDate);
      })
      .concat(
        context?.accessoires.map((item) => {
          return createOfferModel(item.product, 1, item.start, item.end, item.alternateDate);
        })
      );

    const saveCartModel: SaveCartModel = {
      offers: offers ?? [],
      confirmImmediately: false,
      site: context?.site ?? initSite,
      organisationId: context?.organisation ?? '',
      contact: context?.contact ?? initContact,
      contact2: context?.contact2,
      driverInformation: context?.driver,
      isTransportedByUs: !context?.siteFromPickup,
      isInsured: true,
      isEarlyDelivery: context?.earlyDelivery ?? false
    };

    return saveCartModel;
  };

  const createOfferModel = (
    product: ProductModel,
    count: number,
    start: number,
    end: number | null,
    altDate: boolean
  ): OfferModel => {
    return {
      siteNumber: context?.site?.siteNumber || '0',
      description: '',
      quantity: count,
      customerReference: context?.customerReference,
      siteDescription: context?.siteDescription,
      productNumber: product.productNumber,
      relationNumber: relationNumber,
      offerDate: new Date().toISOString(),
      startDate: altDate ? new Date(start).toISOString() : context?.start ? new Date(context?.start).toISOString() : undefined,
      endDate: altDate
        ? end
          ? new Date(end).toISOString()
          : undefined
        : context?.end
          ? new Date(context?.end).toISOString()
          : undefined,
      status: OfferStatus.New,
      usageType: UsageType.Unknown,
      clusterIdentifier: hash,
      offerType: OfferType.Offer
    };
  };

  // Date calculations
  const calculateStartDate = (item: CartItem) =>
    formatToLocalTime(new Date(item.alternateDate ? item.start : context?.start || 0));

  const calculateEndDate = (item: CartItem) => {
    if (item.alternateDate && item.end) {
      return formatToLocalTime(new Date(item.end));
    } else if (!item.alternateDate && context?.end) {
      return formatToLocalTime(new Date(context?.end));
    } else {
      return undefined;
    }
  };

  const nextLabel = type === 'job-survey' ? 'update-survey' : 'order';

  return (
    <CheckoutStep
      currentStep={CheckoutStepType.VERIFICATION}
      onNext={handleConfirm}
      onPrevious={onPrevious}
      previousDisabled={cartMutation.isLoading}
      nextDisabled={cartMutation.isLoading || customerReferenceTooLong}
      previousLabel={`${t('common:button.previous')}`}
      nextLabel={`${t(`checkout:verification.${nextLabel}`)}`}
      hideAlert={type === 'job-survey'}
      submitting={isSubmitting}
    >
      <Card sx={{ flex: 1 }}>
        <CardContent>
          <Typography variant="subtitle1">{t('checkout:verification.label')}</Typography>
          <BlueBox>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {t(`checkout:location.${context?.siteFromPickup ? 'to-pick-up' : 'delivery-by-us'}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        {context?.site.street} {context?.site.houseNumber}
                        {context?.site.houseNumberAddition}
                      </Grid>
                      <Grid item xs={12}>
                        {context?.site.postalCode}
                      </Grid>
                      <Grid item xs={12}>
                        {context?.site.city}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2">{t('checkout:verification.other-remarks')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {context?.siteDescription ? context?.siteDescription : '-'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BlueBox>
          <BlueBox>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{t('common:contact-person.label')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {`${context?.contact.name} (${context?.contact.phoneNumber})`}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2">{t('common:contact-person.label-2')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {context?.contact2 && context?.contact2.name ? `${context?.contact2.name} (${context?.contact2.phoneNumber})` : '-'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BlueBox>
          {context?.driver.name && (
            <BlueBox>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{t('common:driver.label-driver')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {`${context?.driver.name}`}
                    </Grid>
                    <Grid item xs={12}>
                      {`${context?.driver.phoneNumber}`}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2">{t('checkout:verification.other-remarks')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {context?.driver.misc || '-'}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </BlueBox>
          )}
          <Typography variant="subtitle1">{t('products:machines.label')}</Typography>
          {context?.items.map((item) => {
            return (
              <CartItemDetailRow
                key={item.identifier}
                product={item.product}
                startDate={calculateStartDate(item)}
                endDate={calculateEndDate(item)}
                hideProperties={false}
              />
            );
          })}
          {context?.accessoires && context?.accessoires.length > 0 && (
            <Typography variant="subtitle1">{t('products:accessories.label')}</Typography>
          )}
          {context?.accessoires.map((item) => {
            return (
              <CartItemDetailRow
                key={item.identifier}
                product={item.product}
                startDate={calculateStartDate(item)}
                endDate={calculateEndDate(item)}
                hideProperties={true}
              />
            );
          })}
          {type === 'cart' && (
            <Box>
              <Typography
                variant="subtitle1">{t('checkout:verification.price-overview')}</Typography>
              <BlueBox>
                <Grid container>
                  <Grid item xs={10}>
                    {(offerQuery.isFetching || cartMutation.isLoading) &&
                      (<Loader />)
                    }
                    {(!offerQuery.isFetching && !cartMutation.isLoading) && priceCalculated && (
                      <>
                        {t('checkout:verification.estimated-price')}
                        <br />
                        {currencyFormat(
                          offers
                            .map((e) => e.offerAmount as number)
                            .reduce((entry, accumulator) => entry + accumulator, 0)
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    {(!offerQuery.isFetching && !cartMutation.isLoading) && !priceCalculated && (
                      <Button
                        disabled={offerQuery.isFetching || cartMutation.isLoading || context?.items.some(item => !item.end) || customerReferenceTooLong}
                        variant="contained"
                        onClick={() => handleGetProjectPrice()}
                      >
                        {t('checkout:verification.retrieve-estimated-price')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </BlueBox>
            </Box>
          )}
          <Box>
            <Typography variant="subtitle1">{t('orders:other-information')}</Typography>
            <BlueBox>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="subtitle2">{t('orders:customer-reference')}</Typography>
                  <TextFieldShrink
                    fullWidth
                    error={customerReferenceTooLong}
                    helperText={customerReferenceTooLong ? t('orders:validation.customer-reference-max-30') : null}
                    sx={{ backgroundColor: 'white' }}
                    placeholder={t('orders:your-reference') ?? ''}
                    value={context?.customerReference}
                    onChange={(e) => handleCustomerReferenceChange(e)}
                  />
                </Grid>
              </Grid>
            </BlueBox>
          </Box>
        </CardContent>
      </Card>
    </CheckoutStep>
  );
};
