import { createContext, PropsWithChildren, useContext, useState } from 'react';

interface PopupContextModel {
  popup: Message;
  setMessage: (message: Message) => void;
}

interface Message {
  message: string,
  type: 'success' | 'info' | 'warning' | 'error',
  open: boolean
}

const PopupContext = createContext<PopupContextModel | undefined>(undefined);

export function usePopup() {
  const context = useContext(PopupContext);
  if (context === undefined) {
    throw new Error('`useMessagePopup` must be used with an `PopupContextProvider`');
  }
  return context;
}

const initPopup = {
  message: '',
  type: 'success',
  open: false
} as Message;

export const PopupContextProvider = (props: PropsWithChildren<unknown>) => {
  const [popup, setPopup] = useState<Message>(initPopup);

  const setMessage = (message: Message): void => {
    setPopup(message);
  };

  const context = {
    popup,
    setMessage
  };

  return <PopupContext.Provider value={context}>{props.children}</PopupContext.Provider>;
};
