import { useState } from 'react';

const dateParser = function (key: string, value: any) {
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
  if (typeof value === 'string') {
    if (reISO.exec(value)) {
      return new Date(value);
    }
  }
  return value;
};

// TODO: refactor?
// There's a lot of try catching here we shouldn't be doing + empty catches
// We can benefit from defensive programming here
// We also are prone to just returning the initialValue even if try throws an error
// Baseline example used for this was: https://usehooks.com/useLocalStorage/

export function useLocalStorage<T>(key: string, initialValue: T): [T, (s: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item, dateParser) : initialValue;
    }
    catch (error) {
      // If error also return initialValue
      return initialValue; // TODO: why try-catch then?
    }
  });

  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    }
    catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export function useVersionedLocalStorage<T>(version: number, key: string, initialValue: T): [T, (s: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const versionKey = 'version-'+key;
      const storageVersion = window.localStorage.getItem(versionKey);

      if (Number(storageVersion) === version){
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item, dateParser) : initialValue;
      }
      else {
        window.localStorage.removeItem(key);
        window.localStorage.setItem(versionKey, String(version));
      }

      return initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue; // TODO: why try-catch then?
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    }
    catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}
