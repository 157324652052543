import { Api, ApiResponse } from './Api';

export interface DocumentFile {
  fileName: string;
  filePaths: string[];
  id: string;
  referenceNumber: number;
  relation: string;
  topic: string;
  versionNumber: number;
}

interface DocumentFileResponse {
  entries: DocumentFile[];
}

export class OrderService extends Api {
  getDocuments = async (orderId: string) => {
    return this.get<ApiResponse<DocumentFileResponse>>(
      `/api/orders/${orderId}/documents`
    );
  }

  getConfigurations = async (configurationNr: string) => {
    return this.get<ApiResponse<DocumentFileResponse>>(
      `/api/orders/${configurationNr}/configurations`
    );
  }
}
