import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useCart } from '../../../hooks/useCart';
import AccessoryRow from '../AccessoryRow';
import CartRow from '../CartRow';
import EmptyCart from '../EmptyCart';
import { useAccessories } from '../../../hooks/api/useProducts';
import Loader from '../../common/Loader';
import CheckoutStep from './CheckoutStep';
import { CheckoutStepProps, CheckoutStepType } from './index';
import { useTranslation } from 'react-i18next';
import { spacing } from '../../../theme';
import { measurement } from '../../../utils/const/measurement';
import { useEffect, useState } from 'react';
import { ProductModel } from '../../../services/ProductService';
import RoadPlatesAccessoryRow from '../RoadPlatesAccessoryRow';

export default function CheckoutTable({ onNext }: CheckoutStepProps) {
  const { t } = useTranslation(['products', 'common']);
  const { data, isLoading } = useAccessories();
  const { cart, isEmpty, isEmptyAccessories, addToCart, removeFromCart } = useCart();

  const [filteredData, setFilteredData] = useState<{ data: ProductModel[] }>({ data: [] });
  const [roadPlates, setRoadplates] = useState<ProductModel[]>([]);

  useEffect(() => {
    if (data) {
      const newData = [...data.data];
      const roadplatesArray: ProductModel[] = [];

      roadplatesArray.push(newData.splice(newData.findIndex(acc => acc.productNumber === 'Rijplaten'), 1)[0]);
      roadplatesArray.push(newData.splice(newData.findIndex(acc => acc.productNumber === 'Rijplaten (8)'), 1)[0]);
      roadplatesArray.push(newData.splice(newData.findIndex(acc => acc.productNumber === 'Rijplaten (10)'), 1)[0]);
      roadplatesArray.push(newData.splice(newData.findIndex(acc => acc.productNumber === 'Rijplaten (12)'), 1)[0]);
      roadplatesArray.push(newData.splice(newData.findIndex(acc => acc.productNumber === 'Rijplaten (18)'), 1)[0]);

      setRoadplates(roadplatesArray);
      setFilteredData({ data: newData });
    }
  }, [data]);

  return (
    <CheckoutStep
      currentStep={CheckoutStepType.CART}
      onNext={() => onNext && onNext()}
      previousDisabled
      hideNavigation={isEmpty() && isEmptyAccessories()}
      nextLabel={t('common:button.next') ?? ''}
    >
      <Card sx={{ flex: 1 }}>
        {isEmpty() && isEmptyAccessories() && <EmptyCart />}

        {!isEmpty() && (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('kind-of-machine')}</TableCell>
                    <TableCell>{t('work-height')} ({measurement.length})</TableCell>
                    <TableCell>{t('reach')} ({measurement.length})</TableCell>
                    <TableCell>{t('lift-power')} ({measurement.weight})</TableCell>
                    <TableCell>{t('weight')} ({measurement.weight})</TableCell>
                    <TableCell>{t('day-rent')}</TableCell>
                    <TableCell align="right">{t('week-rent')}</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart.items.map((item) => {
                    return <CartRow key={item.identifier} item={item} onRemove={removeFromCart} />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Card>

      {!isEmptyAccessories() && (
        <Card sx={{ flex: 1 }}>
          <Typography variant="subtitle1"
                      sx={spacing.card.tableTitle}>{t('accessories.label')}</Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('accessories.type-label')}
                  </TableCell>
                  <TableCell align="right">{t('common:rent-price')}</TableCell>
                  <TableCell sx={{ width: 160 }}>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.accessoires.map((item) => (
                  <CartRow key={item.identifier} item={item}
                           hideCells={{ productInfo: true, dailyPrice: true }}
                           onRemove={removeFromCart} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}

      <Card sx={{ flex: 1 }}>
        {!isLoading && filteredData && filteredData?.data.length > 0 && (
          <Typography variant="subtitle1"
                      sx={spacing.card.tableTitle}>{t('accessories.available-label')}</Typography>
        )}
        {isLoading && (
          <>
            <Loader />
          </>
        )}
        {!isLoading && filteredData && filteredData?.data.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('accessories.type-label')}</TableCell>
                  <TableCell align="right">{t('common:rent-price')}</TableCell>
                  <TableCell sx={{ width: 160 }}>{t('common:quantity')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                <RoadPlatesAccessoryRow description={t('products:accessories.HWS-H-road-plates')}
                                        name={t('products:accessories.road-plates')}
                                        items={roadPlates}
                                        onAdd={(item) => addToCart(item, true)} />

                {filteredData?.data
                  .filter((accessory) => accessory.productNumber !== 'RST')
                  .map((accessory, index) => {
                    return (
                      <AccessoryRow
                        key={index}
                        item={accessory}
                        onAdd={(item, count) => {
                          for (let i = 1; i <= count; i++) {
                            addToCart(item, true);
                          }
                        }}
                      />
                    );
                  })}
                {filteredData?.data
                  .filter((accessory) => accessory.productNumber === 'RST')
                  .map((accessory, index) => {
                    return (
                      <AccessoryRow
                        key={index}
                        item={accessory}
                        onRequest={true}
                        disabled={cart.accessoires.some((acc) => acc.product.productNumber === 'RST')}
                        onAdd={(item, count) => {
                          for (let i = 1; i <= count; i++) {
                            addToCart(item, true);
                          }
                        }}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
    </CheckoutStep>
  );
}
