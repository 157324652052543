import { useState } from 'react';
import { Typography, Box, Card, CardContent, Button, TextField } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import BlueBox from '../../components/layout/BlueBox';
import { RouteParams } from '../../components/layout/navigation/Breadcrumb';
import OfferDetailTop from '../../components/offers/offerDetailTop/OfferDetailTop';
import { useTranslation } from 'react-i18next';
import JobSurveyDetailRow from '../../components/orders/JobSurveyDetailRow';
import { usePopup } from '../../hooks/usePopupMessage';
import { ArrowForward } from '@mui/icons-material';
import { OfferService } from '../../services/OfferService';
import { useApi } from '../../hooks/api/useApi';
import { currencyFormat } from '../../utils/currencyFormat';
import { colors } from '../../theme';
import PageModal from '../../components/common/PageModal';
import { useJobSurvey } from '../../hooks/useJobSurvey';
import { ClusterModel, OfferModel, OfferStatus } from '../../services/models/Offer';
import { formatDateOnly } from '../../utils/dateFormat';
import { filterEntriesWithProducts } from '../../utils/offerFilterHelper';
import { useKeycloak } from '../../hooks/useKeycloak';
import { ProductModel, ProductService } from '../../services/ProductService';
import { useCart } from '../../hooks/useCart';
import { dummyProduct } from "../../utils/const/dummy";

interface DetailCardProps {
  data?: ClusterModel;
}

export default function JobSurveyDetailCard({ data }: DetailCardProps) {
  const { t } = useTranslation(['routes', 'orders', 'offers', 'products', 'common']);
  const { setMessage } = usePopup();
  const history = useHistory();

  const { isAccountManager } = useKeycloak();
  const { resetJobSurvey } = useJobSurvey();

  const productService = useApi<ProductService>(new ProductService());
  const offerService = useApi<OfferService>(new OfferService());
  const { id } = useParams<RouteParams>();

  const { addMultipleToCart } = useCart();

  const entries = data?.entries;

  const [hasItems, setHasItems] = useState(false);
  const [isAddingItems, setIsAddingItems] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [isRejecting, setIsRejecting] = useState(false);

  const onReorderClick = (offers?: OfferModel[]) => {
    if (!offers) return;

    setIsAddingItems(true);

    const productNumbers = offers
      .filter((offer) => offer.productNumber && offer.productNumber !== dummyProduct.productNumber)
      .map((offer) => offer.productNumber as string);

    if (productNumbers.length === 0) {
      setMessage({
        message: `${t('checkout:machines.no-items')}.`,
        type: 'info',
        open: true
      });
      setIsAddingItems(false);
      return;
    }
    const cartItems: { item: ProductModel, isAccessoire: boolean }[] = [];

    productNumbers.forEach((e) => {
      productService
        .getProductByProductNumber(e)
        .then((data) => {
          cartItems.push({ item: data.result, isAccessoire: false });
        })
        .finally(() => {
          if (cartItems) {
            setMessage({
              message: `${t('checkout:machines.multiple-to-cart')}`,
              type: 'success',
              open: true
            });

            addMultipleToCart(cartItems, true);
            setIsAddingItems(false);
            history.push('/checkout');
          }
        })
    });
  };

  const onClickReject = async () => {
    if (!entries) return;

    setIsRejecting(true);

    const offers = entries.map((entry) => {
      return { id: entry.id || '', cancelReason };
    });

    await offerService.cancelOffer(offers).then((data) => {
      if (data.result[0].status === OfferStatus.Terminated) {
        setModalOpen(false);
        history.push('/werkopnames');
      }
    }).finally(() => {
      setIsRejecting(false);
      setModalOpen(false);
    });
  };

  // Intakes have an 'empty' offer which holds their details. No need to show it
  const entriesWithProducts = filterEntriesWithProducts(entries);
  const machinesTotal = entriesWithProducts?.length;

  return (
    <>
      <Box sx={{ display: 'flex', gap: 5 }}>
        <Card sx={{ flex: 1, marginBottom: 4 }}>
          <CardContent>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">{t('orders:job-survey')} {id}</Typography>
              {entries && entries[0].status !== OfferStatus.Terminated && isAccountManager() && (
                <div>
                  <Button
                    sx={{ marginRight: '12px' }}
                    variant="outlined"
                    color="success"
                    onClick={() => {
                      history.push(`/werkopnames/${id}/aanvullen`);
                      resetJobSurvey();
                    }}
                  >
                    {t('common:button.complement')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setModalOpen(true)}
                  >
                    {t('common:button.reject')}
                  </Button>
                </div>
              )}
            </Box>
            {entries && entries[0].status === OfferStatus.Terminated && (
              <>
                <Typography color={colors.error} sx={{ justifySelf: 'center' }}>
                  {t('orders:x-is-rejected', { item: t('orders:this-job-survey') })}
                </Typography>
                {entries[0].cancelReason && (
                  <Typography color={colors.error} sx={{ justifySelf: 'center' }}>
                    "{entries[0].cancelReason}"
                  </Typography>
                )}
              </>
            )}
              {entries && (
                <>
                  { /* TODO: pass the other items of ClusterModel rather than entries[0] */ }
                  <OfferDetailTop offer={entries[0]} />
                  <BlueBox>
                    <Typography variant="subtitle2" sx={{ marginLeft: 0 }}>
                      {t('orders:description')}
                    </Typography>
                    {entries[0].comments || '-'}

                    <Typography variant="subtitle2" sx={{ marginLeft: 0, marginTop: 2 }}>
                      {t('common:labels.expected-date-period')}
                    </Typography>
                    {!data.expectedStartDate && !data.expectedEndDate
                      ? t('common:unknown')
                      : <>
                        {data.expectedStartDate && formatDateOnly(data.expectedStartDate)}
                        {(data.expectedStartDate && data.expectedEndDate) && " - "}
                        {data.expectedEndDate && formatDateOnly(data.expectedEndDate)}
                      </>}

                    <Typography variant="subtitle2" sx={{ marginLeft: 0, marginTop: 2 }}>
                      {t('orders:estimated-price')}
                    </Typography>
                    {entries && entries.length > 0 &&
                      currencyFormat(entries
                        .map((e) => e.offerAmount as number)
                        .reduce((entry, accumulator) => entry + accumulator, 0)
                      )}
                  </BlueBox>
                </>
              )}
              {entriesWithProducts && entriesWithProducts.length > 0 &&
                <Typography variant="subtitle2">{t('offers:machines')} ({machinesTotal})</Typography>}
              {
                entriesWithProducts?.map((offer, index) => {
                  return (
                    <Box key={index}>
                      <JobSurveyDetailRow
                        offer={offer}
                        setHasItems={setHasItems}
                        offerService={offerService} />
                    </Box>
                  );
                })
              }
              {hasItems && entries && (
                <Box sx={{ justifyContent: 'end', display: 'flex' }}>
                  <Button
                    variant="contained"
                    endIcon={<ArrowForward />}
                    onClick={() => onReorderClick(entries)}
                    disabled={isAddingItems}
                  >
                    {t('checkout:order-all-machines', { suffix: ' ' + t('checkout:from-job-survey') })}
                  </Button>
                </Box>
              )}
          </CardContent>
        </Card>
      </Box>

      <PageModal
        isOpen={modalOpen}
        actions={(
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => onClickReject()}
              disabled={isRejecting}
            >
              {t('common:button.reject')}
            </Button>
            <Button
              variant="text"
              color="info"
              onClick={() => setModalOpen(false)}
              sx={{ marginLeft: 2 }}
            >
              {t('common:button.cancel')}
            </Button>
          </Box>
        )}
      >
        <span style={{ display: 'block', marginBottom: 24 }}>
          {t('orders:x-reject-info',
            {
              item: (t('orders:job-survey')).toLowerCase(),
              id
            }
          )}
        </span>
        <TextField
          multiline
          fullWidth
          rows={3}
          InputLabelProps={{
            shrink: true
          }}
          onChange={(e) => setCancelReason(e.target.value)}
          value={cancelReason}
          label={t('orders:reject-reason')}
        />
      </PageModal>
    </>
  );
}
