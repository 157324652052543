import * as yup from 'yup';
import { ContactModel } from '../../../../hooks/models/cart';

export const phoneRegExp = /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/;

// @ts-ignore
const contactSchema = yup.object().shape<ContactModel>({
  name: yup
    .string()
    .required('Een naam is verplicht')
    .min(3, 'Het is verplicht om minimaal 3 karakters in te voeren'),
  phoneNumber: yup
    .string()
    .required('Een telefoonnummer is verplicht')
    .matches(
      phoneRegExp,
      'Geen geldig telefoonnummer'
    )
});

export default contactSchema;
