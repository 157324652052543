import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { PropsWithChildren } from 'react';
import { colors } from '../../theme';

interface SideBarProps{
  title?: string;
  sx?: SxProps<Theme>;
}

export default function SideBar({ title, sx, children }: PropsWithChildren<SideBarProps>){
  return (
    <Box sx={{
      display: 'flex',
      flex: '0 1 300px',
      minWidth: 200,
      flexDirection: 'column',
      padding: 3,
      bgcolor: colors.white,
      borderRadius: 1,
      alignSelf: 'flex-start',
      ...sx
    }}>
      {title && (<Typography variant="h2">{title}</Typography>)}
      {children}
    </Box>
  );
}
