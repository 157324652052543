import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loader from '../common/Loader';
import EmptyState from '../layout/feedback/EmptyState';
import ProductTableRow from './ProductTableRow';
import { ProductModel } from '../../services/ProductService';
import { useTranslation } from 'react-i18next';
import { measurement } from '../../utils/const/measurement';

interface ProductTableProps {
  products: ProductModel[];
  isLoading: boolean;
  onAddProduct: (item: ProductModel, isAccessoire: boolean) => void;
}

export default function ProductTable({ products, isLoading, onAddProduct }: ProductTableProps) {
  const { t } = useTranslation(['products']);

  if (isLoading) {
    return (
      <Loader />
    );
  }

  if (products.length === 0) {
    return (
      <EmptyState />
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('kind-of-machine')}</TableCell>
            <TableCell>{t('work-height')} ({measurement.length})</TableCell>
            <TableCell>{t('reach')} ({measurement.length})</TableCell>
            <TableCell>{t('lift-power')} ({measurement.weight})</TableCell>
            <TableCell>{t('weight')} ({measurement.weight})</TableCell>
            <TableCell>{t('day-rent')}</TableCell>
            <TableCell align="right">{t('week-rent')}</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <ProductTableRow key={product.productNumber} product={product}
                             onAddProduct={onAddProduct} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
