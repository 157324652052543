import {
  Box,
  Button,
  Card,
  CardContent, Checkbox,
  FormControlLabel,
  Grid,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import theme from '../../theme';
import { useDateTime } from '../../hooks/useDateTime';
import { useJobSurvey } from '../../hooks/useJobSurvey';
import { getSelectItems } from '../../components/cart/checkout/PlanningCheckout';
import React, { useEffect } from 'react';
import PlanningRow from '../../components/cart/PlanningRow';

interface ComplementJobSurveyEditPlanningProps {
  onNext: () => void;
  onPrevious: () => void;
}

export default function ComplementJobSurveyEditPlanning({
                                                          onNext,
                                                          onPrevious
                                                        }: ComplementJobSurveyEditPlanningProps) {
  const {
    jobSurvey,
    setJobSurveyEarlyDelivery,
    setJobSurveyStart,
    setJobSurveyEnd
  } = useJobSurvey();

  const { start: jobSurveyStartDate, end: jobSurveyEndDate } = jobSurvey;

  const dates = useDateTime(jobSurveyStartDate, jobSurveyEndDate, setJobSurveyStart, setJobSurveyEnd);

  const { t } = useTranslation(['checkout']);

  useEffect(() => {
    if (!jobSurvey.earlyDelivery) dates.setStartTime('8:00');
  }, [jobSurvey.earlyDelivery]);

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="subtitle1">{t('checkout:planning.label')}</Typography>
          <Grid container spacing={2} sx={{ paddingTop: 2.5 }}>
            <Grid container spacing={2} item xs={12} sm={6}>
              <Grid item xs={6}>
                <DatePicker
                  label={t('checkout:planning.start')}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      error={dates.dateError.error}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  )}
                  onChange={(date) => {
                    if (date) dates.setStartDate(date);
                  }}
                  inputFormat="dd/MM/yyyy"
                  value={dates.startDate}
                  minDate={new Date().getTime()}
                />
              </Grid>
              <Grid item xs={3}>
                {jobSurvey.earlyDelivery && <>
                  <Select
                    fullWidth
                    id="startTime"
                    value={dates.startTime}
                    style={{ height: '56px', lineHeight: '1.5em' }}
                    onChange={(e) => {
                      dates.setStartTime(String(e.target.value));
                    }}
                  >
                    {getSelectItems(6, 8)}
                  </Select>
                </>}
              </Grid>

              <Grid item xs={12}>
                <Typography>{t('checkout:planning.delivery-explanation')}</Typography>
                <FormControlLabel
                  sx={{ paddingY: 1 }}
                  control={
                    <Checkbox
                      checked={jobSurvey.earlyDelivery}
                      disabled={!dates.startDate}
                      onChange={(e) => {
                        setJobSurveyEarlyDelivery(e.target.checked);
                      }}
                    />
                  }
                  label={t('checkout:planning.deliver-early')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sm={6}>
              <Grid item xs={6}>
                <DatePicker
                  label={t('checkout:planning.end')}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      error={dates.dateError.error}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(date) => {
                    if (date) dates.setEndDate(date);
                  }}
                  inputFormat="dd/MM/yyyy"
                  value={dates.endDate}
                />
                <Button
                  disabled={!dates.endDate}
                  onClick={() => dates.resetEndDate()}
                  startIcon={<Close />}
                >
                  <Typography variant="body2">{t('common:button.clear-field')}</Typography>
                </Button>

              </Grid>
              <Grid item xs={12} sx={{ color: theme.palette.error.main }}>
                {dates.dateError.error && dates.dateError.errorText}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="subtitle1">{t('products:machines.label')}</Typography>
          {jobSurvey.items.map((item) => (
            <PlanningRow type={'job-survey'} key={item.identifier} item={item}
                         hideAlternateTime={jobSurvey.items.length < 2} />
          ))}
        </CardContent>
      </Card>

      <Box display={'flex'} justifyContent={'space-between'}>
        <Button
          type="submit"
          variant="contained"
          onClick={() => onPrevious()}
        >
          <ArrowBack sx={{ marginRight: 1 }} /> {t('common:button.previous')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={() => onNext()}
        >
          {t('common:button.next')} <ArrowForward sx={{ marginLeft: 1 }} />
        </Button>
      </Box>
    </>
  );
}
