import { Api, ApiResponse } from './Api';

export interface NotificationModel {
  totalCompletedIntakes: number;
  totalIntakesToComplete: number;
  totalActiveOrders: number;
  totalPastDueInvoices: number;
}

export class NotificationService extends Api {
  getNotifications = () =>
    this.get<ApiResponse<NotificationModel>>('/api/notifications')
}
