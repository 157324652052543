import { SetStateAction, useState } from 'react';
import { useSiteList } from '../../hooks/api/useSites';
import { useDebounce } from '../../utils/debounce';
import { formatSite } from '../../utils/siteFormatter';
import contactSchema from '../cart/checkout/schema/contactSchema';
import siteSchema from '../cart/checkout/schema/siteSchema';
import driverSchema from '../cart/checkout/schema/driverSchema';
import { useJobSurvey } from '../../hooks/useJobSurvey';
import {
  initAddressErrors, initContactErrors, initDriverErrors,
  MapData
} from '../cart/checkout/hooks/useLocationCheckoutForm';

interface FormProps {
  onNext: (() => void) | undefined,
  onPrevious: (() => void) | undefined
}

type SchemaName = 'contact' | 'site' | 'contact2' | 'driver';

export function useJobSurveyLocationCheckoutForm({ onNext }: FormProps) {
  const { jobSurvey, setJobSurvey, setJobSurveySite } = useJobSurvey();

  const [addressErrors, setAddressErrors] = useState(initAddressErrors);
  const [contactErrors, setContactErrors] = useState(initContactErrors);
  const [secondContactErrors, setSecondContactErrors] = useState(initContactErrors);
  const [driverErrors, setDriverErrors] = useState(initDriverErrors);

  const [hasDriver, setHasDriver] = useState(!!jobSurvey.driver.name);
  const [hasSecondContact, setHasSecondContact] = useState(!!jobSurvey.contact2.name);

  const [mapData, setMapData] = useState<MapData>({
    mapAddress: jobSurvey.site ? `${jobSurvey.site.street} ${jobSurvey.site.houseNumber}` : '',
    mapPostalCode: jobSurvey.site ? jobSurvey.site.postalCode : '',
    mapCity: jobSurvey.site ? jobSurvey.site.city : ''
  });

  const updateMapDebounce = useDebounce(
    (d: MapData) => setMapData({ ...mapData, ...d }), 400
  );

  const [search, setSearch] = useState(() => '');
  const { data, isLoading: sitesLoading } = useSiteList(search);

  const onChangeSiteInputValue = useDebounce(
    (newValue: string) => setSearch(newValue),
    500
  );

  const handleNext = () => {
    const items: SchemaName[] = ['contact'];
    const list = [contactSchema.validate(jobSurvey.contact, { abortEarly: false })];

    if (!jobSurvey.siteFromApi && !jobSurvey.siteFromPickup) {
      setJobSurveySite(formatSite(jobSurvey.site));
      items.push('site');
      list.push(siteSchema.validate(jobSurvey.site, { abortEarly: false }));
    }
    if (hasSecondContact) {
      items.push('contact2');
      list.push(contactSchema.validate(jobSurvey.contact2, { abortEarly: false }));
    }
    if (hasDriver) {
      items.push('driver');
      list.push(driverSchema.validate(jobSurvey.driver, { abortEarly: false }));
    }

    Promise.allSettled([...list]).then((result) => {
      let status = 'fulfilled';

      result.forEach((x, index) => {
        if (x.status === 'fulfilled') return;

        switch (items[index]) {
          case 'site': {
            handleErrorsHelper(initAddressErrors, x.reason, setAddressErrors);
            break;
          }
          case 'contact': {
            handleErrorsHelper(initContactErrors, x.reason, setContactErrors);
            break;
          }
          case 'contact2': {
            handleErrorsHelper(initContactErrors, x.reason, setSecondContactErrors);
            break;
          }
          case 'driver': {
            handleErrorsHelper(initDriverErrors, x.reason, setDriverErrors);
            break;
          }
        }
        status = 'rejected';
      });

      if (status === 'fulfilled') onNext && onNext();
    });
  };

  const handleErrorsHelper = (errorSchema: any, result: any, errorAction: SetStateAction<any>) => {
    for (const [i, value] of result['inner'].entries()) {
      errorSchema = {
        ...errorSchema,
        [String(value.path)]: { error: true, message: value.errors[0] }
      };
      if (i === result['inner'].length - 1) {
        errorAction(errorSchema);
      }
    }
  };

  return {
    jobSurvey,
    setJobSurvey,

    mapData,
    updateMapDebounce,

    handleNext,
    data,
    sitesLoading,
    onChangeSiteInputValue,

    contactErrors,
    setAddressErrors,
    addressErrors,
    setSecondContactErrors,
    secondContactErrors,
    setDriverErrors,
    driverErrors,

    setHasSecondContact,
    hasSecondContact,
    setHasDriver,
    hasDriver
  };
}
