import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SiteModel, SiteService } from '../../services/SiteService';
import { useQueryParams } from '../queryParams/useOfferFilters';
import { useApi } from './useApi';

export function useSiteList(search?: string) {
  const service = useApi<SiteService>(new SiteService());

  const options = { query: { keepPreviousData: true } };
  const query = useQuery({
    queryKey: ['sites', { search: search }],
    queryFn: async () => {
      const response = await service.getSites({
        search: search
      });
      return response;
    },
    ...options
  });

  return {
    ...query
  };
}

export function useSitePickupList() {
  const service = useApi<SiteService>(new SiteService());
  const options = { query: { keepPreviousData: true } };

  const query = useQuery({ queryKey: 'sitePickups', queryFn: async () => {
    return await service.getSitePickupLocations();
  }, ...options });

  return {
    ...query
  };
}

export function useSiteFilteredList() {
  const service = useApi<SiteService>(new SiteService());
  const { params } = useQueryParams();

  const options = { query: { keepPreviousData: true } };
  const query = useQuery({
    queryKey: ['sites', params],
    queryFn: async () => {
      const response = await service.getSites(params);
      return response;
    },
    ...options
  });

  return {
    ...query
  };
}

export function useCreateSite() {
  const service = useApi<SiteService>(new SiteService());
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries('sites');
    }
  };
  return useMutation((data: SiteModel) => service.createSite(data), options);
}
