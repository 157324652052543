import {
  Context,
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from 'react';

interface QueryContextModel<T> {
  params: T,
  setParams: Dispatch<SetStateAction<T>>,
  changeParam: (
    name: string,
    value: string,
    checked?: boolean
  ) => void;
  removeParam: (name: string) => void;
}

const createQueryParamsContext = <T, >() => {
  return createContext<QueryContextModel<T> | undefined>(undefined);
};

const createQueryParamsProvider = <T, >(Context: Context<QueryContextModel<T> | undefined>) => {
  return (props: PropsWithChildren<unknown>) => {
    const [params, setParams] = useState<T>({} as T);

    const changeParam = (
      name: string,
      value: string,
      checked?: boolean
    ): void => {
      if (value === 'on' && checked != null) { //checkboxes work like this
        value = String(checked);
      }
      if (value === '' || value === 'false') {
        setParams((prev: T) => ({ ...prev, [name]: undefined }));
      } else {
        setParams((prev: T) => ({ ...prev, [name]: value }));
      }
    };

    const removeParam = (name: string): void => {
      const newParams = Object.assign({}, params);

      // @ts-ignore
      delete newParams[name];

      setParams(newParams);
    }

    const providerContext = {
      params,
      setParams,
      removeParam,
      changeParam
    } as QueryContextModel<T>;

    return <Context.Provider value={providerContext}>{props.children}</Context.Provider>;
  };
};

export function createQueryParams<T = unknown>() {
  const context = createQueryParamsContext<T>(); // We type the Context based on the generic AuthService
  const QueryParamsProvider = createQueryParamsProvider<T>(context);

  const useQueryParams = () => {
    const queryContext = useContext(context);
    if (queryContext === undefined) {
      throw new Error('`useQueryParams` must be used with an `UseQueryParamsProvider`');
    }
    return queryContext;
  };

  return {
    context,
    QueryParamsProvider,
    useQueryParams
  };
}
