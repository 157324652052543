interface ContactNumber {
  location: ContactLocation;
  number: string;
}

type ContactLocation = 'Amsterdam' | 'Apeldoorn' | 'Elsloo' | 'Hoogvliet';

export const contactNumbers : ContactNumber[] = [
  { location: 'Amsterdam', number: '020 506 14 00' },
  { location: 'Apeldoorn', number: '055 303 08 60' },
  { location: 'Elsloo', number: '046 202 01 60' },
  { location: 'Hoogvliet', number: '010 295 50 30' }
];

export const planningContactNumber = contactNumbers.find((x) => x.location === 'Hoogvliet')?.number;
