import { useRef } from 'react';
import debounce from 'lodash/debounce';
import type { DebounceSettings } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce<T extends (...args: any) => any>(
  fn: T,
  wait = 300,
  options?: DebounceSettings
) {
  const ref = useRef(debounce<T>(fn, wait, options));
  return ref.current;
}
