import { SxProps, Theme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Search } from '@mui/icons-material';
import { useDebounce } from '../../utils/debounce';
import { colors } from '../../theme';
import { useTranslation } from 'react-i18next';

interface SidebarSearchFieldProps {
  name: string;
  changeParam: (name: string, value: string, checked?: boolean) => void;
  placeholder?: string;
}

const searchFieldSx: SxProps<Theme> = {
  borderRadius: 0,
  borderColor: colors.white,

  bgcolor: colors.lightBlue,
  color: colors.darkBlue,

  '::placeholder': {
    color: colors.darkBlue
  },
  '& label.Mui-focused': {
    color: colors.darkBlue
  },
  '& .MuiOutlinedInput-root': {
    '& ::placeholder':{
      color: colors.darkBlue,
      fontWeight: 'bold'
    },
    '& fieldset': {
      borderColor: colors.lightBlue
    },
    '&:hover fieldset': {
      borderColor: colors.darkBlue
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.lightBlue
    }
  }
}

export default function SidebarSearchField({ name, changeParam, placeholder }: SidebarSearchFieldProps){
  const { t } = useTranslation('common');
  const searchPlaceholder = placeholder || `${t('search')}`;

  const onChangeInputValue = useDebounce(
    (e: React.ChangeEvent<HTMLInputElement>) => changeParam(e.target.name, e.target.value), 500);

  return (
    <TextField
      name={name}
      onChange={onChangeInputValue}
      placeholder={searchPlaceholder}
      sx={searchFieldSx}
      InputLabelProps={{
        style: { color: colors.darkBlue }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search sx={{ color: colors.darkBlue }} />
          </InputAdornment>
        )
      }}
      variant="outlined"
    />
  );
}
