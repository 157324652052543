import { SiteModel } from '../../services/SiteService';
import { Autocomplete, AutocompleteChangeReason, TextField } from '@mui/material';
import { useDebounce } from '../../utils/debounce';
import { useSiteList } from '../../hooks/api/useSites';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SearchAddressDropdownProps {
  value: any;
  disabled: boolean;
  onAddressSelect: (e: any, site: SiteModel | null, reason: AutocompleteChangeReason) => void;
}

export default function SearchAddressDropdown({ value, disabled, onAddressSelect }: SearchAddressDropdownProps) {
  const [search, setSearch] = useState(() => '');

  const { t } = useTranslation(['common', 'checkout']);
  const { data, isLoading: sitesLoading } = useSiteList(search);

  const onChangeSiteInputValue = useDebounce(
    (newValue: string) => setSearch(newValue),
    500
  );

  const renderSiteDetails = (model: SiteModel) => {
    const { street, houseNumber, houseNumberAddition, postalCode, city } = model;
    return `${street} ${houseNumber}${houseNumberAddition || ''} ${postalCode} ${city}`;
  };

  return (
    <Autocomplete
      renderOption={(props, site) => {
        return (
          <li {...props} key={site.id}>
            {renderSiteDetails(site)}
          </li>
        );
      }}
      disabled={disabled}
      loading={sitesLoading}
      onInputChange={(_, newInputValue) => {
        onChangeSiteInputValue(newInputValue);
      }}
      options={data?.result.items || []}
      noOptionsText={t('common:no-results-found')}
      loadingText={t('common:loading')}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      getOptionLabel={(site: SiteModel) => {
        if (site && site.siteNumber !== '0' && site.id !== '-1') {
          return renderSiteDetails(site);
        } else {
          return '';
        }
      }}
      value={value}
      onChange={(_, site, reason) => {
        onAddressSelect(_, site, reason);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder: t('checkout:location.fill-label') ?? '',
            autoComplete: 'off' // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
