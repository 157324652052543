
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useKeycloak } from '../../hooks/useKeycloak';
import { colors } from '../../theme';

interface LoginLayoutProps {
  children?: React.ReactNode
}

const backgrounds = [
  'url(\'/images/kraan01.jpg\') center center no-repeat',
  'url(\'/images/kraan02.jpg\') 75% center no-repeat',
  'url(\'/images/vrachtwagen.jpg\') 27% center no-repeat',
  'url(\'/images/hijskraan.jpg\') 40% center no-repeat'
];

export default function LoginLayout({ children }: LoginLayoutProps) {
  const { isLoggedIn } = useKeycloak();
  const [randomBg, setRandomBg] = useState<number>();

  useEffect(() => {
    if (!randomBg) setRandomBg( Math.floor(Math.random() * backgrounds.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn()){
    return (
      <Redirect to="/" />
    );
  };

  return (
    <div className="login-box">
      <Box className="login-left" sx={{
        background: backgrounds[randomBg || 0],
        backgroundColor: colors.background,
        backgroundSize: 'cover'
      }}>
      </Box>
      <div className="login-form">
        <img src="/images/Logo.svg" className="App-logo" alt="logo" />
        {children}
      </div>
    </div>
  );
}
