import { Close } from '@mui/icons-material';
import { IconButton, Box, TableCell, TableRow } from '@mui/material';
import { CartItem } from '../../hooks/models/cart';
import { currencyFormat } from '../../utils/currencyFormat';
import { getDailyPrice, getWeeklyPrice } from '../../utils/priceHelper';

interface CartRowProps {
  item: CartItem;
  hideCells?: {
    productInfo?: boolean,
    dailyPrice?: boolean,
    weeklyPrice?: boolean,
  };
  onRemove: (identifier: string, isAccessoire: boolean) => void;
}

export default function CartRow({ item, hideCells, onRemove }: CartRowProps) {
  const { product, identifier, isAccessoire } = item;

  return (
    <TableRow>
      <TableCell>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row'
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <span style={{ fontWeight: 'bold' }}>{product.productGroup.description}</span>
            <span style={{ fontWeight: 'normal' }}>{product.productNumber}</span>
          </Box>
        </Box>
      </TableCell>
      {!hideCells?.productInfo && (<>
        <TableCell>{product.workHeightNumber ? `${product.workHeightNumber}` : '-'}</TableCell>
        <TableCell>
          {product.reachLengthNumber && product.reachLengthNumber > 0 ? `${product.reachLengthNumber}` : '-'}
        </TableCell>
        <TableCell>{product.liftPowerNumber ? `${product.liftPowerNumber}` : '-'}</TableCell>
        <TableCell>{product.weightNumber ? `${product.weightNumber}` : '-'}</TableCell>
      </>)}
      {!hideCells?.dailyPrice && (
        <TableCell>{currencyFormat(getDailyPrice(product))}</TableCell>
      )}
      {!hideCells?.weeklyPrice && (
        <TableCell align="right">{currencyFormat(getWeeklyPrice(product))}</TableCell>
      )}
      <TableCell align="right">
        <IconButton
          size="small"
          onClick={() => onRemove(identifier, isAccessoire)}
        >
          <Close sx={{ width: 25, height: 25 }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
