import { Card, CardContent, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loader from '../common/Loader';
import { useInvoiceList } from '../../hooks/api/useInvoices';
import DashboardInvoiceRow from './DashboardInvoiceRow';

interface DashboardInvoiceCardProps {
  maxRows: number;
}

const DashboardInvoiceCard = (props: DashboardInvoiceCardProps) => {
  const { data, isLoading, isError } = useInvoiceList();
  const { t } = useTranslation(['routes', 'common']);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Box sx={{ marginLeft: 2, marginRight: 2 }}>
        <Typography>
          {t('common:error.generic')}
        </Typography>
      </Box>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle1">
          {t('common:invoices')}
        </Typography>

        {data?.result.items.length === 0 && (
          <Typography>{t('common:no-results')}</Typography>
        )}
        {data?.result.items.slice(0, props.maxRows).map((cluster) => {
          const invoice = cluster.entries[0];

          return <DashboardInvoiceRow key={invoice.id} invoice={invoice} />;
        })}

      </CardContent>
    </Card>
  );
};

export default DashboardInvoiceCard;
