import PageTitle from '../../components/layout/PageTitle';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { LinkRouter, RouteParams } from '../../components/layout/navigation/Breadcrumb';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetOfferByClusterId } from '../../hooks/api/useOffers';
import BlueBox from '../../components/layout/BlueBox';
import { useApi } from '../../hooks/api/useApi';
import { OfferService } from '../../services/OfferService';
import JobSurveyDetailRow from '../../components/orders/JobSurveyDetailRow';
import Loader from '../../components/common/Loader';
import { currencyFormat } from '../../utils/currencyFormat';
import TextFieldShrink from '../../components/layout/TextFieldShrink';
import PageModal from '../../components/common/PageModal';
import { OfferStatus } from '../../services/models/Offer';
import { filterEntriesWithProducts } from '../../utils/offerFilterHelper';

export default function JobSurveyVerify() {
  const { t } = useTranslation(['routes', 'checkout', 'products', 'offers']);
  const { id } = useParams<RouteParams>();
  const { query } = useGetOfferByClusterId(id || '');

  const [hasItems, setHasItems] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const history = useHistory();

  const onClickReject = async () => {
    if (!entries) return;
    setIsProcessing(true);

    const offers = entries.map((entry) => {
      return { id: entry.id || '', cancelReason };
    });

    await offerService.cancelOffer(offers).then((data) => {
      if (data.result[0].status === OfferStatus.Terminated) {
        setRejectModalOpen(false);
        history.push('/werkopnames');
      }
    }).finally(() => {
      setIsProcessing(false);
      setRejectModalOpen(false);
    });
  };

  const onClickApprove = async () => {
    if (!entries) return;
    setIsProcessing(true);

    await offerService.confirmOffer(id || '').then(() => {
      history.push('/werkopnames');
    }).finally(() => {
      setIsProcessing(false);
      setApproveModalOpen(false);
    })
  };

  const offerService = useApi<OfferService>(new OfferService());

  const jobSurveyData = query.data?.result;
  const entries = jobSurveyData?.entries;
  const { isLoading } = query;

  if (isLoading || !entries || entries.length === 0) {
    return <>
      <PageTitle title={`${t('routes:check-job-survey')}`} showBreadcrumbs={false} />
      <Card sx={{ flex: 1, marginBottom: 4 }}>
        <CardContent>
          {isLoading
            ? t('common:loading')
            : t('common:x-not-exist', { item: t('orders:this-job-survey') })
          }
        </CardContent>
      </Card>
    </>;
  }

  // intakes have an 'empty' offer which holds their details. No need to show it
  const entriesWithProducts = filterEntriesWithProducts(entries);
  const firstOffer = entries[0]; // TODO: phase out

  const isTransportedByUs = t(`checkout:location.${jobSurveyData?.isTransportedByUs ? 'to-pick-up' : 'delivery-by-us'}`)
  const isApproveDisabled = jobSurveyData.contactPerson?.name === '' || isProcessing;

  return (
    <>
      <PageTitle title={`${t('routes:check-job-survey')}`} showBreadcrumbs={false} />
      <Breadcrumbs sx={{
        fontSize: 12,
        marginBottom: 2
      }} separator=">" aria-label="breadcrumb">
        <LinkRouter underline="hover" color="inherit" to="/">
          {t('routes:dashboard')}
        </LinkRouter>
        <LinkRouter underline="hover" color="inherit" to="/werkopnames">
          {t('routes:job-surveys')}
        </LinkRouter>
        <Typography sx={{ fontSize: 12 }}>
          {t('routes:check-job-survey')}
        </Typography>
      </Breadcrumbs>

      <Box sx={{
        display: 'flex',
        gap: 5,
        flexDirection: 'column',
        marginBottom: 3
      }}>

        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Typography variant="subtitle1">{t('checkout:verification.label')}</Typography>
            <BlueBox>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        {isTransportedByUs}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          {firstOffer.site?.street} {firstOffer.site?.houseNumber}
                          {firstOffer.site?.houseNumberAddition}
                        </Grid>
                        <Grid item xs={12}>
                          {firstOffer.site?.postalCode}
                        </Grid>
                        <Grid item xs={12}>
                          {firstOffer.site?.city}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2">{t('checkout:verification.other-remarks')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {firstOffer?.comments ?? '-'}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </BlueBox>

            {jobSurveyData?.contactPerson && (
              <BlueBox>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle2">{t('common:contact-person.label')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {`${jobSurveyData?.contactPerson?.name} (${jobSurveyData?.contactPerson?.phoneNumber})`}
                      </Grid>
                    </Grid>
                  </Grid>
                  {jobSurveyData?.additionalContactPerson && (
                    <Grid item xs={12} md={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2">{t('common:contact-person.label-2')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {`${jobSurveyData?.additionalContactPerson?.name} (${jobSurveyData?.additionalContactPerson?.phoneNumber})`}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {jobSurveyData?.driver && (
                    <Grid item xs={12} md={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle2">{t('common:driver.label-driver')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {`${jobSurveyData?.driver?.name} (${jobSurveyData?.driver?.phoneNumber})`}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </BlueBox>
            )}

            {entriesWithProducts && entriesWithProducts.length > 0 && (
              <>
                <Typography variant="subtitle1">{t('offers:machines')}</Typography>
                {
                  entriesWithProducts?.map((offer, index) => {
                    return (
                      <Box key={index}>
                        <JobSurveyDetailRow
                          offer={offer}
                          setHasItems={setHasItems}
                          offerService={offerService}
                          canRemove={false}
                        />
                      </Box>
                    );
                  })
                }
                <Box>
                  <Typography
                    variant="subtitle1">{t('checkout:verification.price-overview')}</Typography>
                  <BlueBox>
                    <Grid container>
                      <Grid item xs={10}>
                        {entriesWithProducts && entriesWithProducts.length === 0 &&
                          (<Loader />)
                        }
                        {hasItems && (
                          <>
                            {t('checkout:verification.estimated-price')}
                            <br />
                            {currencyFormat(
                              entriesWithProducts
                                .map((e) => e.offerAmount as number)
                                .reduce((entry, accumulator) => entry + accumulator, 0)
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </BlueBox>
                </Box>
              </>
            )}

            <Box>
              <Typography variant="subtitle1">{t('orders:other-information')}</Typography>
              <BlueBox>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">{t('orders:customer-reference')}</Typography>
                    <TextFieldShrink
                      fullWidth
                      sx={{ backgroundColor: 'white' }}
                      placeholder={t('common:unknown') ?? ''}
                      value={firstOffer.customerReference ?? ''}
                      disabled
                    />
                  </Grid>
                </Grid>
              </BlueBox>
            </Box>

            <Button
              sx={{ marginRight: 2 }}
              variant="outlined"
              color="error"
              onClick={() => setRejectModalOpen(true)}
            >
              {t('common:button.reject')}
            </Button>
            <Button
              variant="outlined"
              color="success"
              onClick={() => setApproveModalOpen(true)}
              disabled={isApproveDisabled}
            >
              {t('common:button.approve')}
            </Button>
          </CardContent>
        </Card>
      </Box>

      <PageModal
        isOpen={rejectModalOpen}
        actions={(
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => onClickReject()}
              disabled={isProcessing}
            >
              {t('common:button.reject')}
            </Button>
            <Button
              variant="text"
              color="info"
              onClick={() => setRejectModalOpen(false)}
              sx={{ marginLeft: 2 }}
            >
              {t('common:button.cancel')}
            </Button>
          </Box>
        )}
      >
        <span style={{ display: 'block', marginBottom: 24 }}>{t('orders:reject-info')}</span>
        <TextField
          multiline
          fullWidth
          rows={3}
          InputLabelProps={{
            shrink: true
          }}
          onChange={(e) => setCancelReason(e.target.value)}
          value={cancelReason}
          label={t('orders:reject-reason')}
        />
      </PageModal>

      <PageModal
        isOpen={approveModalOpen}
        actions={(
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => onClickApprove()}
              disabled={isApproveDisabled}
            >
              {t('common:button.approve')}
            </Button>
            <Button
              variant="text"
              color="info"
              onClick={() => setApproveModalOpen(false)}
              sx={{ marginLeft: 2 }}
            >
              {t('common:button.cancel')}
            </Button>
          </Box>
        )}
      >
        <span style={{ display: 'block', marginBottom: 24 }}>{t('orders:approve-info')}</span>
      </PageModal>
    </>
  );
}
