import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { UserModel } from '../../services/models/UserModel';
import { OrganizationModel, OrganizationService } from '../../services/OrganizationService';
import { useDeleteUser, useUsersList } from './useUsersList';
import { useApi } from './useApi';
import { usePopup } from '../usePopupMessage';

export default function useUserListPage() {
  const { t } = useTranslation(['settings', 'common']);

  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserModel>();
  const [organizations, setOrganizations] = useState<OrganizationModel[]>([]);

  const { fetchUsers, users, loading: loadingUsers } = useUsersList();

  const userDelete = useDeleteUser();
  const organizationService = useApi(new OrganizationService());

  const { setMessage } = usePopup();

  useEffect(() => {
    fetchUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(loadingUsers);
  }, [loadingUsers]);

  useEffect(() => {
    loadOrganizations();
  }, [users]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadOrganizations = () => {
    if (users && users.length < 1) {
      setOrganizations([]);
    } else {
      const organizationsToLoad = users
        ?.flatMap((user) => user.relationIds ?? [])
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((orgId) =>
          organizationService
            .getOrganizationByRelationId(orgId)
            .then((res) => res.result)
            .catch(() => undefined)
        );

      if (organizationsToLoad) {
        setLoading(true);
        Promise.all(organizationsToLoad)
          .then((results) => results.filter((o): o is OrganizationModel => !!o))
          .then(setOrganizations)
          .finally(() => setLoading(false));
      }
    }
  };

  const onDelete = () => {
    if (selectedUser && selectedUser.id) {
      setIsDeleting(true);
      userDelete.mutate(selectedUser.id, {
        onSuccess: () => {
          setMessage({
            message: t('settings:users.messages.success'),
            type: 'success',
            open: true
          });
          fetchUsers();
          setSelectedUser(undefined);
        },
        onError: () => {
          setMessage({
            message: t('settings:users.messages.error'),
            type: 'error',
            open: true
          });
        },
        onSettled: () => {
          setIsDeleting(false);
        }
      });
    }
  };

  const getOrganizationName = (id: string): string =>
    organizations
      .find((org) => org.relationNumber === id ?? '')
      ?.name ?? '-';

  return {
    loading,
    isDeleting,
    selectedUser,
    setSelectedUser,
    users,
    onDelete,
    getOrganizationName
  };
}
