import { Add } from '@mui/icons-material';
import {
  Box,
  TableCell,
  TableRow,
  IconButton, MenuItem, Select
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopup } from '../../hooks/usePopupMessage';
import { ProductModel } from '../../services/ProductService';
import { currencyFormat } from '../../utils/currencyFormat';
import { getWeeklyPrice } from '../../utils/priceHelper';

interface DropdownAccessoireRowProps {
  items: ProductModel[];
  onAdd: (item: ProductModel) => void;
  name: string;
  description: string;
}

export default function RoadPlatesAccessoryRow({
                                               items,
                                               onAdd,
                                               name,
                                               description
                                             }: DropdownAccessoireRowProps): JSX.Element {
  const [roadPlatesIndex, setRoadPlatesIndex] = useState(0);

  const { t } = useTranslation('checkout');
  const { setMessage } = usePopup();

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'row', minWidth: 250 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {description}
            </Box>
            <Box component="span" sx={{ fontWeight: 'normal' }}>
              {name}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right">{currencyFormat(getWeeklyPrice(items[roadPlatesIndex]))}</TableCell>
      <TableCell align="right">
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <Select defaultValue={0}
                  onChange={(a) => setRoadPlatesIndex(Number(a.target.value))}
                  sx={{ width: 90, marginRight: 2, textAlign: 'left' }}>
            <MenuItem value={0}>6</MenuItem>
            <MenuItem value={1}>8</MenuItem>
            <MenuItem value={2}>10</MenuItem>
            <MenuItem value={3}>12</MenuItem>
            <MenuItem value={4}>18</MenuItem>
          </Select>
          <Box marginY={'auto'}>
            <IconButton
              size="small"
              onClick={() => {
                onAdd(items[roadPlatesIndex])
                setMessage({
                  message: t('accessoires.success', {
                    description: items[roadPlatesIndex].productGroup.description,
                    productNumber: items[roadPlatesIndex].description
                  }),
                  type: 'success',
                  open: true
                });
              }}
            >
              <Add sx={{ color: '#D2D2CF', width: 25, height: 25 }} />
            </IconButton>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}
