import { DatePicker } from '@mui/x-date-pickers';
import {
  Card,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Button
} from '@mui/material';
import { useEffect } from 'react';
import { useCart } from '../../../hooks/useCart';
import PlanningRow from '../PlanningRow';
import { useDateTime } from '../../../hooks/useDateTime';
import { CheckoutStepProps, CheckoutStepType } from './index';
import { useTranslation } from 'react-i18next';
import CheckoutStep from './CheckoutStep';
import { Close } from '@mui/icons-material';
import theme from '../../../theme';
import { CardContent } from '@material-ui/core';

export const getSelectItems = (from: number, to: number) => {
  const range = to - from;
  const quarters = [0, 15, 30, 45];
  const hours = Array.from({ length: range + 1 }, (_, i) => i + from);

  return hours.flatMap((hour, i) => {
    if (i !== hours.length - 1) {
      return quarters.map((option) => (
        <MenuItem
          key={hour + option}
          value={`${hour.toString()}:${option.toString().padStart(2, '0')}`}
        >
          {`${hour.toString()}:${option.toString().padStart(2, '0')}`}
        </MenuItem>
      ));
    } else {
      return (
        <MenuItem key={hour + '00'} value={`${hour.toString()}:00`}>
          {`${hour.toString()}:00`}
        </MenuItem>
      );
    }
  });
};

export default function PlanningCheckout({ onNext, onPrevious }: CheckoutStepProps) {
  const { cart, setCartStart, setCartEnd, earlyDelivery, setEarlyDelivery } = useCart();
  const { start: cartStartDate, end: cartEndDate } = cart;

  const dates = useDateTime(cartStartDate, cartEndDate, setCartStart, setCartEnd);

  const { t } = useTranslation(['common', 'checkout', 'products']);

  useEffect(() => {
    if (!earlyDelivery) dates.setStartTime('8:00');
  }, [earlyDelivery]);

  useEffect(() => {
    const allValid =
      cart.items.every((item) => item.isValid) &&
      cart.accessoires.every((item) => item.isValid);

    if (!allValid) return; // show error?

    if ((dates.endDate && !dates.endTime)) {
      dates.setDateError({
        error: true,
        errorText: t('common:error.incomplete')
      })
      return;
    }

    const allCustomDates =
      cart.items.every((item) => item.alternateDate) &&
      cart.accessoires.every((item) => item.alternateDate);

    const hasCompleteDates = cartStartDate && cartEndDate && dates.startDate && dates.endDate;

    // TODO: comparable validation in hook. Keep, merge or remove?
    if (hasCompleteDates && cartStartDate > cartEndDate && !allCustomDates) {
      dates.setDateError({
        error: true,
        errorText: t('common:error.dates')
      });
      return;
    }

    dates.setDateError({
      error: false,
      errorText: ''
    });

  }, [cart, cartStartDate, cartEndDate, dates.endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CheckoutStep
      currentStep={CheckoutStepType.PLANNING}
      onNext={() => onNext && onNext()}
      nextDisabled={dates.dateError.error}
      onPrevious={onPrevious}
      previousLabel={t('common:button.previous') ?? ''}
      nextLabel={t('common:button.next') ?? ''}
    >
      <Card>
        <CardContent>
          <Typography variant="subtitle1">{t('checkout:planning.label')}</Typography>
          <Grid container spacing={2} sx={{ paddingTop: 2.5 }}>
            <Grid container spacing={2} item xs={12} sm={6}>
              <Grid item xs={6}>
                <DatePicker
                  label={t('checkout:planning.start')}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      error={dates.dateError.error}
                      InputLabelProps={{ shrink: true }}
                      required
                    />
                  )}
                  onChange={(date) => {
                    if (date) dates.setStartDate(date);
                  }}
                  inputFormat="dd/MM/yyyy"
                  value={dates.startDate}
                  minDate={new Date().getTime()}
                />
              </Grid>
              <Grid item xs={3}>
                { earlyDelivery && <>
                  <Select
                    fullWidth
                    id="startTime"
                    value={dates.startTime}
                    style={{ height: '56px', lineHeight: '1.5em' }}
                    onChange={(e) => {
                      dates.setStartTime(String(e.target.value));
                    }}
                  >
                    {getSelectItems(6, 8)}
                  </Select>
                </>}
              </Grid>

              <Grid item xs={12}>
                <Typography>{t('checkout:planning.delivery-explanation')}</Typography>
                <FormControlLabel
                  sx={{ paddingY: 1 }}
                  control={
                    <Checkbox
                      checked={earlyDelivery}
                      disabled={!dates.startDate}
                      onChange={(e) => {
                        setEarlyDelivery(e.target.checked);
                      }}
                    />
                  }
                  label={t('checkout:planning.deliver-early')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} item xs={12} sm={6}>
              <Grid item xs={6}>
                <DatePicker
                  label={t('checkout:planning.end')}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      error={dates.dateError.error}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  onChange={(date) => {
                    if (date) dates.setEndDate(date);
                  }}
                  inputFormat="dd/MM/yyyy"
                  value={dates.endDate}
                />
                <Button
                  disabled={!dates.endDate}
                  onClick={() => dates.resetEndDate()}
                  startIcon={<Close/>}
                >
                  <Typography variant='body2'>{t('button.clear-field')}</Typography>
                </Button>

              </Grid>
              <Grid item xs={12} sx={{ color: theme.palette.error.main }}>
                {dates.dateError.error && dates.dateError.errorText}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="subtitle1">{t('products:machines.label')}</Typography>
          {cart.items.map((item) => (
            <PlanningRow key={item.identifier} item={item} hideAlternateTime={cart.items.length < 2} />
          ))}
        </CardContent>
      </Card>
      {cart.accessoires.length > 0 && (
        <Card>
          <CardContent>
            <Typography variant="subtitle1">{t('products:accessories.label')}</Typography>
            {cart.accessoires.map((item) => (
              <PlanningRow key={item.identifier} item={item} />
            ))}
          </CardContent>
        </Card>
      )}
    </CheckoutStep>
  );
}
