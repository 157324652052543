import { PropsWithChildren } from 'react';
import SideBar from './SideBar';

interface Props {
  title?: string;
}

export default function FilterSideBar({ title, children }: PropsWithChildren<Props>){
  return (
    <SideBar title={title} sx={{ minWidth: 250 }}>
      {children}
    </SideBar>
  );
}
