import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  QueryClient,
  QueryClientProvider
} from 'react-query';
import {
  BrowserRouter
} from 'react-router-dom';
import { AppRoutes } from './router/AppRoutes';
import { ThemeProvider } from '@mui/system';
import theme from './theme';
import { createTheme } from '@mui/material';

import './config/i18n';
import Loader from './components/common/Loader';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { nl } from 'date-fns/locale';
import { KeycloakContextProvider } from './hooks/useKeycloak';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      suspense: false,
      refetchOnWindowFocus: false
    }
  }
});

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <BrowserRouter>
      <ThemeProvider theme={createTheme(theme)}>
        <KeycloakContextProvider>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={nl}>
              <AppRoutes />
            </LocalizationProvider>
          </QueryClientProvider>
        </KeycloakContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
