import { Api, ApiResponse } from './Api';
import {
  PasswordResetModel,
  RegisterUserModel,
  UserModel,
  UserPostModel,
  UserProfileModel
} from './models/UserModel';

export class UserService extends Api {
  putResetPassword = async (user: PasswordResetModel) => {
    return this.put<void>(
      '/api/users/password',
      user
    );
  };

  postRegisterUser = async (user: RegisterUserModel) => {
    return this.post(
      '/api/users/register',
      user
    );
  };

  getUsers = async () => {
    return this.get<ApiResponse<UserModel[]>>(
      '/api/users'
    );
  };

  getUser = async (userId: string) => {
    return this.get<ApiResponse<UserModel>>(
      `/api/users/${userId}`
    );
  };

  getUserProfile = async (userId: string) => {
    return this.get<ApiResponse<UserProfileModel>>(
      `/api/users/${userId}/profile`
    );
  };

  createUser = async (user: UserPostModel) => {
    return this.post<ApiResponse<UserModel>>(
      '/api/users',
      user
    );
  };

  updateUser = async (user: UserPostModel) => {
    return this.put<ApiResponse<UserPostModel>>(
      `/api/users/${user.id}`,
      user
    );
  };

  updateUserProfile = async (userProfile: UserProfileModel) => {
    return this.put<ApiResponse<UserProfileModel>>(
      `/api/users/${userProfile.userId}/profile`,
      userProfile
    );
  };

  deleteUserAdmin = async (userId: string) => {
    return this.http.delete(
      `/api/users/${userId}`
    );
  };
}
