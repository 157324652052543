import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Card, CardContent, Link } from '@mui/material';
import DashboardCard from '../components/dashboard/DashboardCard';
import { OfferType } from '../services/models/Offer';
import { useTranslation } from 'react-i18next';
import { contactNumbers } from '../utils/const/contact';
import { colors } from '../theme';
import DashboardInvoiceCard from '../components/dashboard/DashboardInvoiceCard';

export default function DashboardPage() {
  const { t } = useTranslation('dashboard');

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 270,
              minWidth: 275,
              backgroundImage: 'url(\'/images/mood.png\')',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          >
            <CardContent sx={{ maxWidth: '800px' }}>
              <Typography sx={{ color: colors.white, textAlign: 'center' }} variant="h1">
                {t('welcome-info-1')}<br />
                {t('welcome-info-2')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard maxRows={3} offerType={OfferType.Offer} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard maxRows={3} offerType={OfferType.JobSurvey} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardInvoiceCard maxRows={3} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%', minHeight: 270, minWidth: 275 }}>
            <CardContent>
              <Typography variant="subtitle1">{t('contact-header')}</Typography>
              <Typography variant="body1">
                {t('contact-info')}
              </Typography>
              <Grid container sx={{ marginTop: 2, marginBottom: 0 }} rowSpacing={0.5}>
                {contactNumbers.map((contact, i) => {
                  return (
                    <Grid container item xs={12} key={i}>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {contact.location}
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body2">
                          <Link href={`tel:${contact.number}`}>{contact.number}</Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
