export const generateClusterIdHash = (str: string): Promise<string> => {
  return new Promise((resolve)=>{
    let hash = 0;
    str.split('').forEach( (char, i) => {
      hash = ((hash<<5)-hash)+char.charCodeAt(0);
      hash = hash & hash;
      if (i === str.length - 1){
        resolve(String(Math.abs(hash)));
      }
    });
  });
};
