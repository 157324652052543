import { Button, CardContent, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import Loader from '../components/common/Loader';
import PageModal from '../components/common/PageModal';
import { RouteParams } from '../components/layout/navigation/Breadcrumb';
import PageTitle from '../components/layout/PageTitle';
import OfferDetailRow from '../components/offers/OfferDetailRow';
import OfferDetailTop from '../components/offers/offerDetailTop/OfferDetailTop';
import { useApi } from '../hooks/api/useApi';
import { useGetOfferByClusterId } from '../hooks/api/useOffers';
import { usePopup } from '../hooks/usePopupMessage';
import { OfferService } from '../services/OfferService';

export default function OfferDetailPage() {
  const [bookoutOrderId, setBookoutOrderId] = useState('');
  const [bookoutDate, setBookoutDate] = useState<Date | null>(null);
  const [offerId, setOfferId] = useState('');
  const [isBusyBookingOut, setIsBusyBookingOut] = useState(false);

  const service = useApi<OfferService>(new OfferService());
  const queryClient = useQueryClient();

  const { id } = useParams<RouteParams>();
  const { query } = useGetOfferByClusterId(id || '');
  const { isLoading } = query;

  const entries = query.data?.result.entries;

  const { setMessage } = usePopup();
  const { t } = useTranslation(['offers', 'routes', 'common', 'products']);

  const bookoutMachine = () => {
    setIsBusyBookingOut(true);

    if (!bookoutDate) {
      setMessage({
        message: t('messages.error-bookout'),
        type: 'error',
        open: true
      });
      return;
    }

    service
      .bookOutOrder(offerId, bookoutOrderId, bookoutDate)
      .then(() => {
        setMessage({
          message: t('messages.signed-off'),
          type: 'success',
          open: true
        });

        queryClient.invalidateQueries(['order-offernumber', offerId]);
        query.refetch();
      })
      .catch(() => {
        setMessage({
          message: t('messages.error-signed-off'),
          type: 'error',
          open: true
        });
      })
      .finally(() => {
        setBookoutDate(null);
        setBookoutOrderId('');
        setIsBusyBookingOut(false);
      });
  };

  if (isLoading || !entries || entries.length === 0) {
    return <>
      <PageTitle title={`${t('routes:job-survey-details')}`} />
      <Card sx={{ flex: 1, marginBottom: 4 }}>
        <CardContent>
          {isLoading
          ? t('common:loading')
          : t('common:x-not-exist', { item: t('orders:this-job-survey') })}
        </CardContent>
      </Card>
    </>
  }

  return (
    <>
      <PageTitle title={`${t('routes:order-details')}`} />
      <Box
        sx={{
          display: 'flex',
          gap: 5
        }}
      >
        <Card sx={{ flex: 1, marginBottom: 4 }}>
          <CardContent>
            <Typography variant="subtitle1">{t('overview-label')} {id}</Typography>
            {!query.isLoading ? <>
              {query.data && (
                <OfferDetailTop offer={query.data.result.entries[0]} />
              )}
              <Typography variant="subtitle2">{t('machines')}</Typography>
              {query.data?.result.entries.map((offer, index) => {
                return (
                  <OfferDetailRow
                    key={`${offer.id}-${index}`}
                    offer={offer}
                    onBookOut={(offerId: string, orderId: string) => {
                      setBookoutDate(new Date);
                      setOfferId(offerId);
                      setBookoutOrderId(orderId);
                    }}
                  />
                );
              })}
            </> : <Loader />}
          </CardContent>
        </Card>
      </Box>

      <PageModal
        isOpen={!!bookoutOrderId}
        onClose={(_, reason: string) => {
          if (reason && reason === 'backdropClick') return;

          setBookoutDate(null);
          setBookoutOrderId('');
          setOfferId('');
        }}
        actions={(
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
            <Button
              disabled={isBusyBookingOut}
              onClick={() => bookoutMachine()}
              variant="outlined"
            >
              {t('common:button.sign-off')}
            </Button>
            <Button
              sx={{ marginLeft: 2 }}
              onClick={() => {
                setBookoutDate(null);
                setBookoutOrderId('');
                setOfferId('');
              }}
            >
              {t('common:button.cancel')}
            </Button>
          </Box>
        )}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('sign-off-machine')}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t('confirmation-message')}
        </Typography>
      </PageModal>
    </>
  );
}
