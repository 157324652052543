import { MenuItem, Select } from '@mui/material';

export type HourSelectProps = {
  time: string;
  onChange: (start: string) => void;
}

export default function HourSelect(props: HourSelectProps) {
  const quarters = [0, 15, 30, 45];
  const hours = Array.from({ length: 14 }, (_, i) => i + 6);

  const getSelectItems = () => {
    return hours.flatMap((hour, i) => {
      if (i !== hours.length - 1) {
        return quarters.map((option) => (
          <MenuItem
            key={hour + option}
            value={`${hour.toString()}:${
              option === 0 ? '00' : option.toString()
            }`}
          >
            {`${hour.toString()}:${option.toString().padStart(2, '0')}`}
          </MenuItem>
        ));
      } else {
        return (
          <MenuItem key={hour + '00'} value={`${hour.toString()}:00`}>
            {`${hour.toString()}:00`}
          </MenuItem>
        );
      }
    });
  };

  return (
    <Select
      label='Tijd'
      value={props.time}
      style={{ height: '56px', lineHeight: '1.5em', width: '100px' }}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
    >
      {getSelectItems()}
    </Select>
  );
}
