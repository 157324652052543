import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { useClusteredOfferFilteredList } from '../../hooks/api/useOffers';
import Loader from '../common/Loader';
import EmptyState from '../layout/feedback/EmptyState';
import OfferTableRow from './OfferTableRow';

export default function OfferTable() {
  const { t } = useTranslation(['orders']);
  const { data, isLoading } = useClusteredOfferFilteredList();

  if (isLoading) {
    return (
      <Loader />
    );
  }
  if (data?.result !== undefined && data?.result.items.length === 0) {
    return (
      <EmptyState />
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('orders:work-address')}</TableCell>
            <TableCell>{t('orders:status')}</TableCell>
            <TableCell>{t('orders:requested-on')}</TableCell>
            <TableCell>{t('orders:your-reference')}</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.result.items.flatMap((cluster) => {
            return (
              <OfferTableRow
                key={
                  cluster.entries[0].id + '' + cluster.entries[0].offerNumber
                }
                cluster={cluster}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
