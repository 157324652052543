import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { AccountCircleOutlined, ApartmentOutlined, Close, EmailOutlined, PersonOutline } from '@mui/icons-material';
import { Button, Card, Chip, IconButton } from '@mui/material';
import PageTitle from '../../components/layout/PageTitle';
import { useKeycloak } from '../../hooks/useKeycloak';
import { UserSideMenu } from '../../components/users/UserSideMenu';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/common/Loader';
import { useTranslation } from 'react-i18next';
import { spacing } from '../../theme';
import PageModal from '../../components/common/PageModal';
import { UserModel } from '../../services/models/UserModel';
import useUserListPage from '../../hooks/api/useUserListPage';
import { Tooltip } from '@material-ui/core';
import { userRole } from '../../utils/const/roles';

export default function UserListPage() {
  const { t } = useTranslation(['settings', 'common']);
  const { isAdmin, isPrimaryUser } = useKeycloak();
  const { loading, isDeleting, selectedUser, setSelectedUser, users, onDelete, getOrganizationName } =
    useUserListPage();
  const history = useHistory();

  const showOrganizations = (user: UserModel) => {
    const relationIds = user.relationIds;

    if (user.role?.includes(userRole.admin)) {
      return <Chip label={t('settings:users.chip.all')} variant='outlined' />
    }

    if (relationIds && relationIds.length > 1) {
      return (
        <Tooltip title={
          <>
            {
              relationIds.map((id) => {
                return <div key={id} style={{ fontSize: 12 }}>{getOrganizationName(id)}</div>
              })
            }
          </>
        }>
          <Chip label={`${relationIds.length} ${t('settings:users.chip.organisations')}`} />
        </Tooltip>
      )
    }

    return <>{getOrganizationName(relationIds ? relationIds[0] : '')}</>
  }

  return (
    <>
      <PageTitle
        title={`${t('settings:title')}`}
        buttonTitle={`${t('settings:users.new-user')}`}
        buttonRoute="/instellingen/gebruikers/nieuw"
        showButton={isPrimaryUser()}
      />
      <Box
        sx={{
          display: 'flex',
          gap: 5
        }}
      >
        <UserSideMenu />
        <Card sx={{ flex: 1 }}>
          <Typography variant="subtitle1" sx={spacing.card.tableTitle}>
            {t('settings:users.label')}
          </Typography>
          {loading ? (
            <Loader />
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <AccountCircleOutlined /> {t('settings:users.people')}
                    </TableCell>
                    <TableCell>
                      <EmailOutlined /> {t('settings:users.mail')}
                    </TableCell>
                    <TableCell>
                      <ApartmentOutlined /> {t('settings:users.company-name')}
                    </TableCell>
                    <TableCell>
                      <PersonOutline /> {t('settings:users.user-role')}
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.map((user: UserModel) => (
                    <TableRow
                      hover
                      key={user.id}
                      sx={{ ':hover': { cursor: 'pointer' } }}
                      onClick={() => history.push('/instellingen/gebruikers/' + user.id)}
                    >
                      <TableCell component="th" scope="row">
                        {user.firstName} {user.lastName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.emailAddress}
                      </TableCell>
                      <TableCell>{showOrganizations(user)}</TableCell>
                      <TableCell>{user.role ? t(`settings:form.role.${user.role}`) : '-'}</TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {isAdmin() && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedUser(user);
                            }}
                          >
                            <Close sx={{ width: 25, height: 25 }} />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Card>
      </Box>

      <PageModal
        isOpen={!!selectedUser}
        actions={
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
            <Button variant="contained" onClick={() => onDelete()} disabled={isDeleting}>
              {t('common:button.delete')}
            </Button>
            <Button variant="text" sx={{ marginLeft: 2 }} onClick={() => setSelectedUser(undefined)}>
              {t('common:button.cancel')}
            </Button>
          </Box>
        }
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t('settings:users.delete-user-title')}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t('settings:users.delete-user-message')}
        </Typography>
      </PageModal>
    </>
  );
}
