import { useMutation, useQueryClient } from 'react-query';
import { ApiResponse } from '../../services/Api';
import {
  UserPostModel,
  PasswordResetModel,
  RegisterUserModel,
  UserModel
} from '../../services/models/UserModel';
import { UserService } from '../../services/UserService';
import { useApi } from './useApi';
import { useState } from 'react';

export function useUsersList() {
  const service = useApi<UserService>(new UserService());
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserModel[]>();

  const fetchUsers = async () => {
    setLoading(true);
    await service
      .getUsers()
      .then((res) => setUsers(res.result))
      .finally(() => setLoading(false));
  };

  return {
    loading,
    users,
    fetchUsers
  };
}

export function useGetUserById(userId: string) {
  const service = useApi<UserService>(new UserService());
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserModel>();

  const fetchUser = async () => {
    if (userId) {
      setLoading(true);
      await service
        .getUser(userId)
        .then((res) => setUser(res.result))
        .finally(() => setLoading(false));
    }
  };

  return {
    loading,
    user,
    fetchUser
  };
}

export function useCreateUser() {
  const service = useApi<UserService>(new UserService());

  const createUser = async (data: UserPostModel) => {
    await service.createUser(data);
  };

  return {
    createUser
  };
}

export function useUpdateUser() {
  const service = useApi<UserService>(new UserService());
  const queryClient = useQueryClient();
  const options = {
    onSuccess: (data: ApiResponse<UserPostModel>) => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries(['user', data.result.id]);
    }
  };

  return useMutation((data: UserPostModel) => {
    return service.updateUser(data);
  }, options);
}

export function useResetPassword() {
  const service = useApi<UserService>(new UserService());
  service.removeToken();
  const options = {};
  return useMutation((data: PasswordResetModel) => service.putResetPassword(data), options);
}

export function useRegisterUser() {
  const service = useApi<UserService>(new UserService());
  const options = {};
  return useMutation((data: RegisterUserModel) => service.postRegisterUser(data), options);
}

export function useDeleteUser() {
  const service = useApi<UserService>(new UserService());
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    }
  };
  return useMutation((userId: string) => service.deleteUserAdmin(userId), options);
}
