import { SiteModel } from '../services/SiteService';

export const formatSite = (site: SiteModel): SiteModel => {
  const words = site.street.split(' ');

  const formattedStreet = words
    .map((word) => {
      return (word[0] || '').toUpperCase() + word.substring(1);
    })
    .join(' ');

  return {
    city: site.city.toUpperCase(),
    houseNumber: site.houseNumber,
    postalCode: site.postalCode.toUpperCase(),
    street: formattedStreet,
    houseNumberAddition: site.houseNumberAddition,
    id: site.id,
    name: site.name,
    searchName: site.searchName,
    siteNumber: site.siteNumber,
    telephoneNumber: site.telephoneNumber
  };
};
