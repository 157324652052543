import { CheckoutStepType } from '.';
import { PropsWithChildren } from 'react';
import NavigationButtons from '../NavigationButtons';
import HelpAlert from '../../common/HelpAlert';

interface Props {
  currentStep: CheckoutStepType,
  onNext: () => void;
  nextLabel?: string;
  nextDisabled?: boolean;
  onPrevious?: () => void;
  previousLabel?: string;
  previousDisabled?: boolean;
  hideNavigation?: boolean;
  validate?: () => boolean;
  hideAlert?: boolean;
  submitting?: boolean;
}

export default function CheckoutStep({
    currentStep,
    onNext,
    nextLabel,
    nextDisabled,
    onPrevious,
    previousLabel,
    previousDisabled,
    hideNavigation,
    validate,
    children,
    hideAlert = false,
    submitting = false
  }: PropsWithChildren<Props>) {

  const stepTranslation = () => {
    switch (currentStep) {
      case CheckoutStepType.CART:
        return 'checkout-cart';
      case CheckoutStepType.LOCATION:
        return 'checkout-location';
      case CheckoutStepType.PLANNING:
        return 'checkout-planning';
      case CheckoutStepType.VERIFICATION:
        return 'checkout-verify';
      default:
        return undefined;
    }
  };

  return (
    <>
      {!hideAlert &&
        <HelpAlert translation={{ key: stepTranslation() }} step={stepTranslation()} />
      }
      {children}
      {!hideNavigation && (
        <NavigationButtons
          previous={onPrevious}
          previousText={previousLabel}
          previousDisabled={previousDisabled}
          next={onNext}
          nextText={nextLabel}
          nextDisabled={nextDisabled}
          validate={validate}
          submitting={submitting}
        />
      )}
    </>
  );
}
