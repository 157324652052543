import { Box } from '@mui/material';
import {  ReactNode } from 'react';
import { colors } from '../../theme';

interface BlueBoxProps{
  children?: ReactNode;
}

export default function BlueBox({ children }: BlueBoxProps){
  return (
    <Box sx={{
      marginY: 2.5,
      padding: 2.5,
      borderRadius: 3,
      bgcolor: colors.boxBlue
    }}>
      {children}
    </Box>
  );
}
