import { Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../../components/layout/navigation/Breadcrumb';
import PageTitle from '../../components/layout/PageTitle';
import { useGetOfferByClusterId } from '../../hooks/api/useOffers';
import { useTranslation } from 'react-i18next';
import JobSurveyDetailCard from './JobSurveyDetailCard';

export default function JobSurveyDetailPage() {
  const { id } = useParams<RouteParams>();
  const { query } = useGetOfferByClusterId(id || '');

  const result = query.data?.result;
  const { isLoading } = query;

  const { t } = useTranslation(['routes', 'orders', 'common']);

  if (isLoading || !result?.entries || result.entries.length === 0) {
    return <>
      <PageTitle title={`${t('routes:job-survey-details')}`} />
      <Card sx={{ flex: 1, marginBottom: 4 }}>
        <CardContent>
          {isLoading
            ? t('common:loading')
            : t('common:x-not-exist', { item: t('orders:this-job-survey') })
          }
        </CardContent>
      </Card>
    </>;
  }

  return (
    <>
      <PageTitle title={`${t('routes:job-survey-details')}`} />
      <JobSurveyDetailCard data={result} />
    </>
  );
}
