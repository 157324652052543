import { TextField, TextFieldProps } from '@material-ui/core';
import { styled } from '@mui/material';

const TextFieldShrink = styled((props: TextFieldProps) => (
    <TextField {...props} select={false} variant="outlined" InputLabelProps={{ shrink: true }} />
  ))
  (({ theme }) => ({
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }
  }));

export default TextFieldShrink;
