import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse
} from 'axios';
import { getReasonPhrase, StatusCodes } from 'http-status-codes';
import qs from 'qs';

export interface ApiResponse<T> {
  result: T;
}

export interface PagedData<T> {
  pageSize: number;
  hasNext: boolean;
  nextPage: string;
  items: Array<T>;
}

export interface QueryOptions {
  pageParam?: string;
  name?: string;
}

export interface ErrorMessage {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export class ServiceError extends Error {
  statusCode?: number;
  data?: ErrorMessage;

  constructor(message?: string, statusCode?: number, data?: ErrorMessage) {
    super(message);
    this.statusCode = statusCode;
    this.data = data;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class Api {
  http: AxiosInstance;
  refreshFunc?: () => Promise<void>;
  token = '';
  tokenRefreshing = false;

  constructor() {
    const config = window.env;
    this.http = axios.create({
      baseURL: config.baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  handleResponse = (res: AxiosResponse, refreshRequest = false) => {
    if (res.status === StatusCodes.OK || res.statusText === getReasonPhrase(StatusCodes.OK)) {
      this.tokenRefreshing = false;
      return res.data;
    }

    if (res.status !== StatusCodes.FORBIDDEN && res.status !== StatusCodes.UNAUTHORIZED) {
      throw new ServiceError(`Request rejected with status ${res.status}`, res.status, res.data);
    } else if (!refreshRequest && this.refreshFunc) {
      if (!this.tokenRefreshing) {
        this.tokenRefreshing = true;

        return this.refreshFunc().then(() => {
          return this.request(res.config, true);
        });
      }
    } else {
      throw new ServiceError('U bent niet geautoriseerd', res.status, res.data);
    }
  };

  normalizeQueryParameters = (params?: unknown) => {
    return Object.fromEntries(
      Object
        .entries(params ?? {})
        .map(([key, value]) => (
          [key, `${value}`.includes(',') ? value.split(',') : value]
        ))
    );
  };

  setRefreshFunc = (refreshFunc: () => Promise<void>) => {
    this.refreshFunc = refreshFunc;
  };

  setToken = (token: string) => {
    this.token = token;

    this.http.interceptors.request.use((config: AxiosRequestConfig) => {
      config = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${this.token}`
        }
      };
      return config;
    });
  };

  removeToken = (): void => {
    this.token = '';

    this.http.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.headers !== undefined) {
        const headers = config.headers;
        delete headers.Authorization;
        config.headers = {
          ...headers
        };
      }
      return config;
    });
  };

  request = async <T>(config: AxiosRequestConfig, refreshRequest: boolean): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.request(config);
      return res.data;
    } catch ({ response }) {
      return this.handleResponse(response as AxiosResponse, refreshRequest);
    }
  };

  get = async <T>(path: string, headers?: AxiosRequestHeaders, params?: unknown): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.get(`${path}`, {
        headers,
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        }
      });
      return res.data;
    } catch ({ response }) {
      return this.handleResponse(response as AxiosResponse);
    }
  };

  post = async <T>(path: string, data: unknown): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.post(`${path}`, data);
      return res.data;
    } catch ({ response }) {
      return this.handleResponse(response as AxiosResponse);
    }
  };

  put = async <T>(path: string, data: unknown): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.put(`${path}`, data);
      return res.data;
    } catch ({ response }) {
      return this.handleResponse(response as AxiosResponse);
    }
  };

  patch = async <T>(path: string, data: unknown): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.patch(`${path}`, data);
      return res.data;
    } catch ({ response }) {
      return this.handleResponse(response as AxiosResponse);
    }
  };

  delete = async <T>(path: string): Promise<T> => {
    try {
      const res: AxiosResponse = await this.http.delete(`${path}`);
      return res.data;
    } catch ({ response }) {
      return this.handleResponse(response as AxiosResponse);
    }
  };
}

export const api = new Api();
