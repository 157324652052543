import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import FilterSideBar from '../components/layout/FilterSideBar';
import Card from '@mui/material/Card';
import { QueryParamsProvider, useQueryParams } from '../hooks/queryParams/useOfferFilters';
import OfferTable from '../components/offers/OfferTable';
import PageTitle from '../components/layout/PageTitle';
import SidebarSearchField from '../components/layout/SidebarSearchField';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import {
  RadioGroup,
  TextField,
  Typography,
  Radio,
  FormControl,
  FormControlLabel,
  debounce
} from '@mui/material';
import { format } from 'date-fns';
import { OrderStatusFilters } from '../services/models/Offer';
import { offersDateFilterFormat } from '../utils/dateFormat';

export default function OffersListPage() {
  const { t } = useTranslation(['routes', 'orders']);

  return (
    <>
      <PageTitle title={`${t('routes:rental-orders')}`} />
      <Box sx={{
        display: 'flex',
        gap: 5
      }}>
        <QueryParamsProvider>
          <OffersListPageFilters />
          <Card sx={{ flex: 1 }}>
            <OfferTable />
          </Card>
        </QueryParamsProvider>
      </Box>
    </>
  );
}

const OffersListPageFilters = () => {
  const { t } = useTranslation(['common', 'offers']);
  const { changeParam, removeParam } = useQueryParams();

  const [dateFilter, setDateFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');

  return (
    <FilterSideBar>
      <SidebarSearchField name="search" changeParam={changeParam}
                          placeholder={`${t('common:search-order')}`} />

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="subtitle2" gutterBottom>
          {t('offers:status-filter')}
        </Typography>
        <FormControl>
          <RadioGroup
            defaultValue={statusFilter}
            value={statusFilter}
          >
            {Object.keys(OrderStatusFilters).map(key => {
              return <FormControlLabel control={<Radio onClick={(e) => {
                const inputValue = (e.target as HTMLInputElement).value;

                if (inputValue !== statusFilter) {
                  // @ts-ignore
                  changeParam('offerStatus', OrderStatusFilters[inputValue].split(','));
                } else {
                  removeParam('offerStatus');
                }
                setStatusFilter(inputValue === statusFilter ? '' : inputValue);
              }} />} label={t(`orders:order-status.${key}`)} value={key} key={key} />;
            })}
          </RadioGroup>
        </FormControl>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <DatePicker
          label={t('offers:date-filter')}
          value={dateFilter}
          onChange={debounce((date) => {
            if (!date) {
              removeParam('offerDate');
            }
            if (date && new Date(date).getTime() > 0) {
              setDateFilter(date);
              changeParam('offerDate', format(new Date(date), offersDateFilterFormat));
            }
          }, 300)}
          renderInput={(params) => <TextField {...params} />}
          componentsProps={{
            actionBar: {
              actions: ['clear']
            }
          }}
          onAccept={(date) => setDateFilter(date)}
        />
      </Box>
    </FilterSideBar>
  );
};
