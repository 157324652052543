import { Box, TableCell, TableRow } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Status from '../layout/Status';
import { formatDateOnly } from '../../utils/dateFormat';
import MenuItem from '@mui/material/MenuItem';
import TableDropdownCell from '../common/TableDropdownCell';
import { useTranslation } from 'react-i18next';
import { OfferModel } from '../../services/models/Offer';
import { useKeycloak } from '../../hooks/useKeycloak';

interface JobSurveyProps {
  offer: OfferModel;
}

export default function JobSurveyTableRow({ offer }: JobSurveyProps) {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const { isAccountManager, isAdmin } = useKeycloak();

  const ableToCheck = () => {
    return !isAccountManager() || isAdmin();
  };

  return (
    <TableRow hover key={offer.id}>
      <TableCell>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row'
        }}>
          {offer.site && (
            <Box
              key={offer.site.id}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {offer.site.street} {offer.site.houseNumber}
                {offer.site.houseNumberAddition}
              </Box>
              <Box component="span">
                {offer.site.postalCode} {offer.site.city}
              </Box>
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Status status={offer.status} />
      </TableCell>
      <TableCell>{formatDateOnly(offer.offerDate)}</TableCell>
      <TableCell>{offer.customerReference}</TableCell>
      <TableDropdownCell>
        <MenuItem onClick={() => {
          history.push('/werkopnames/' + offer.clusterIdentifier);
        }}>{t('common:details')}</MenuItem>
        <MenuItem disabled={!ableToCheck()} onClick={() => {
          history.push('/werkopnames/' + offer.clusterIdentifier + '/controleren');
        }}>{t('common:check')}</MenuItem>
      </TableDropdownCell>
    </TableRow>
  );
}
