import Box from '@mui/material/Box';
import { Badge, Chip, IconButton, SxProps, Theme } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { colors } from '../../theme';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import DescriptionIcon from '@mui/icons-material/Description';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import RefreshIcon from '@mui/icons-material/Refresh';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import useNotifications from './hooks/useNotifications';
import { useKeycloak } from "../../hooks/useKeycloak";

export default function NotificationIcon() {
  const { t } = useTranslation(['common']);
  const { isAccountManager } = useKeycloak();
  const { loading, menuRef, isMenuOpen, onToggleMenu, onLoad, notifications, showOrganisationNotifications, totalNotifications } = useNotifications();

  const sx: SxProps<Theme> = {
    color: colors.white,
    backgroundColor: colors.blue,
    '&:hover': {
      backgroundColor: colors.darkBlue
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton size="small" sx={sx} onClick={onToggleMenu}>
        <Badge badgeContent={totalNotifications} color="secondary" invisible={isMenuOpen}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={menuRef}
        open={isMenuOpen}
        onClose={() => onToggleMenu()}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isAccountManager() && (
          <MenuItem component={Link} to="/werkopnames" onClick={() => onToggleMenu()}>
            <CheckIcon style={{ color: 'green', marginRight: '6px' }} />
            {t('common:notifications.new-intakes')}
            <NotificationChip value={notifications.totalIntakesToComplete} />
          </MenuItem>
        )}
        {showOrganisationNotifications && [
          <MenuItem component={Link} to="/werkopnames" onClick={() => onToggleMenu()}>
            <CheckIcon style={{ color: 'green', marginRight: '6px' }} />
            {t('common:notifications.completed-intakes')}
            <NotificationChip value={notifications.totalCompletedIntakes} />
          </MenuItem>,
          <Divider />,
          <MenuItem component={Link} to="/facturen" onClick={() => onToggleMenu()}>
            <DescriptionIcon style={{ color: 'green', marginRight: '6px' }} />
            {t('common:notifications.past-due-invoices')}
            <NotificationChip value={notifications.totalPastDueInvoices} />
          </MenuItem>,
          <Divider />,
          <MenuItem component={Link} to="/bestelling" onClick={() => onToggleMenu()}>
            <PrecisionManufacturingIcon style={{ color: 'green', marginRight: '6px' }} />
            {t('common:notifications.rental-machines-active')}
            <NotificationChip value={notifications.totalActiveOrders} />
          </MenuItem>,
          <Divider />
        ]}
        <MenuItem sx={{ justifyContent: 'center' }} onClick={onLoad}>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<RefreshIcon />}
            style={{ pointerEvents: 'none' }}
          >
            {t('common:refresh')}
          </LoadingButton>
        </MenuItem>
      </Menu>
    </Box>
  );
}

interface NotificationChipProps {
  value: number;
}

function NotificationChip({ value }: NotificationChipProps) {
  return (
    <Chip
      label={value}
      color="secondary"
      style={{ marginLeft: '6px', backgroundColor: value ? undefined : 'transparent' }}
    />
  );
}
