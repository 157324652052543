import React, { useState } from 'react';
import { CartItem, CartModel, ContactModel } from './models/cart';
import {
  formatToWorkingHourDate, initCart,
  workingHours
} from './useCart';
import {
  OfferModel,
  OfferStatus,
  OfferType,
  TypeOfWork,
  UsageType
} from '../services/models/Offer';
import { useDebounce } from '../utils/debounce';
import { useApi } from './api/useApi';
import { SiteModel, SiteService } from '../services/SiteService';
import { JobSurveyForm } from '../pages/jobSurvey/JobSurveyPage';
import { useKeycloak } from './useKeycloak';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ProductModel } from '../services/ProductService';
import { usePopup } from './usePopupMessage';
import { DriverModel } from '../services/CheckoutService';
import { useGetOrganizationByRelationId } from './api/useOrganizations';

interface ContextState {
  jobSurvey: CartModel;
  setJobSurvey: (object: CartModel) => void;
  setJobSurveyStart: (start: number) => void;
  setJobSurveyEnd: (end: number | null) => void;
  addProductToJobSurvey: (product: ProductModel, isAccessoire: boolean) => void;
  setJobSurveyOrganisation: (organisation: string) => void;
  setJobSurveySite: (site: SiteModel, siteFromApi?: boolean, siteFromPickup?: boolean) => void;
  setJobSurveyEarlyDelivery: (value: boolean) => void;
  setJobSurveyDriver: (driver: DriverModel) => void;
  setJobSurveySiteFromPickup: (value: boolean) => void;
  setJobSurveySiteDescription: (value: string) => void;
  setJobSurveyContact: (contact: ContactModel) => void;
  setJobSurveyContact2: (contact: ContactModel) => void;
  setJobSurveyIsValidProduct: (identifier: string, isValid: boolean) => void;
  setJobSurveyCustomerReference: (reference: string) => void;
  setJobSurveyProductStart: (
    identifier: string,
    isAccessoire: boolean,
    startDate: number
  ) => void;
  setJobSurveyProductEnd: (
    identifier: string,
    isAccessoire: boolean,
    endDate: number | null
  ) => void;
  setJobSurveyAlternateDate: (
    identifier: string,
    isAccessoire: boolean,
    deviates: boolean
  ) => void;
  searchByPostalCode: (formik: any, postalCode: string, houseNumber: string) => void;
  initForm: JobSurveyForm;
  initSite: SiteModel;
  initOffer: OfferModel;
  jobSurveySchema: any;
  resetJobSurvey: () => void;
  removeFromJobSurvey: (identifier: string, isAccessoire: boolean) => void;
}

const JobSurveysContext = React.createContext<ContextState | undefined>(undefined);

export function useJobSurvey() {
  const context = React.useContext(JobSurveysContext);
  if (context === undefined) {
    throw new Error('`useJobSurvey` must be used with an `JobSurveysProvider`');
  }
  return context;
}

export const JobSurveysProviderComponent = (props: React.PropsWithChildren<unknown>) => {
  const { t } = useTranslation(['orders']);
  const { setMessage } = usePopup();

  const { getAddressByPostalCode } = useApi<SiteService>(new SiteService());
  const { relationNumber } = useKeycloak();
  const { data: loadedOrg } = useGetOrganizationByRelationId(relationNumber);

  const cartWithOrganization: CartModel = {
    ...initCart,
    organisation: loadedOrg?.result.id ?? ''
  };

  const [jobSurvey, setJobSurvey] = useState<CartModel>(
    JSON.parse(
      JSON.stringify(cartWithOrganization)
    )
  );

  const initOffer: OfferModel = {
    relationNumber: relationNumber,
    offerDate: new Date().toISOString(),
    usageType: UsageType.Unknown,
    typeOfWork: TypeOfWork.Onbekend,
    status: OfferStatus.New,
    customerReference: '',
    offerType: OfferType.JobSurvey,
    clusterIdentifier: '',
    comments: '',
    typeOfWorkDescription: '',
    quantity: 0,
    siteNumber: ''
  };

  const initSite: SiteModel = {
    id: '-1',
    siteNumber: '',
    searchName: '',
    name: '',
    street: '',
    postalCode: '',
    city: '',
    houseNumber: '',
    telephoneNumber: ''
  };

  const initForm: JobSurveyForm = {
    site: initSite,
    offer: initOffer
  };

  const jobSurveySchema = Yup.object().shape({
    site: Yup.object().shape({
      city: Yup.string().required(t('orders:validation.city-mandatory') ?? ''),
      postalCode: Yup
        .string()
        .required(t('orders:validation.postalcode-invalid') ?? '')
        .matches(
          /^[1-9][0-9]{3} (?!sa|sd|ss)[A-Z]{2}$/i,
          t('orders:validation.postalcode-invalid') ?? ''
        ),
      street: Yup.string().required(t('orders:validation.streetname-mandatory') ?? ''),
      houseNumber: Yup.number().required(t('orders:validation.housenumber-mandatory') ?? '')
    }),
    offer: Yup.object().shape({
      comments: Yup.string()
        .required(t('orders:validation.description-mandatory') ?? '')
        .min(10, t('orders:validation.description-min-10-chars') ?? ''),
      typeOfWork: Yup.string().required(t('orders:validation.typeofwork-mandatory') ?? '')
    })
  });

  const searchByPostalCode = useDebounce((formik, postalCode: string, houseNumber: string) => {
    if (postalCode.length >= 7 && houseNumber) {
      addressByPostalCode(postalCode.replaceAll(' ', ''), houseNumber).then(response => {
        formik.setValues({
          offer: {
            ...formik.values.offer
          },
          site: {
            ...formik.values.site,
            postalCode: postalCode,
            houseNumber: response.result.houseNumber,
            city: response.result.city,
            street: response.result.addressLine
          }
        });
      });
    }
  }, 600);

  const setJobSurveyStart = (start: number): void => {
    const updatedJobSurvey = { ...jobSurvey };

    let hasChanged = updatedJobSurvey.start !== start;
    updatedJobSurvey.start = start;

    updatedJobSurvey.items.forEach((item) => {
      if (!item.alternateDate) {
        hasChanged = item.start !== start;
        item.start = start;
      }
    });

    updatedJobSurvey.accessoires.forEach((item) => {
      if (!item.alternateDate) {
        hasChanged = item.start !== start;
        item.start = start;
      }
    });

    if (hasChanged) {
      updatedJobSurvey.offers = [];
    }

    setJobSurvey(updatedJobSurvey);
  };

  const setJobSurveyEnd = (end: number | null): void => {
    const updatedJobSurvey = { ...jobSurvey };

    let hasChanged = updatedJobSurvey.end !== end;
    updatedJobSurvey.end = end;

    updatedJobSurvey.items.forEach((item) => {
      if (!item.alternateDate) {
        hasChanged = item.end !== end;
        item.end = end;
      }
    });

    updatedJobSurvey.accessoires.forEach((item) => {
      if (!item.alternateDate) {
        hasChanged = item.end !== end;
        item.end = end;
      }
    });

    if (hasChanged) {
      updatedJobSurvey.offers = [];
    }

    setJobSurvey(updatedJobSurvey);
  };

  const setJobSurveyOrganisation = (organisation: string) => {
    setJobSurvey({ ...jobSurvey, organisation: organisation });
  };

  const setJobSurveySite = (site: SiteModel, siteFromApi?: boolean, siteFromPickup?: boolean) => {
    setJobSurvey({
      ...jobSurvey,
      site: site,
      siteFromApi: siteFromApi ?? jobSurvey.siteFromApi,
      siteFromPickup: siteFromPickup ?? jobSurvey.siteFromPickup
    });
  };

  const setJobSurveyEarlyDelivery = (value: boolean) => {
    setJobSurvey({ ...jobSurvey, earlyDelivery: value });
  };

  const setJobSurveyDriver = (driver: DriverModel) => {
    setJobSurvey({ ...jobSurvey, driver: driver });
  };

  const setJobSurveySiteFromPickup = (value: boolean) => {
    setJobSurvey({ ...jobSurvey, siteFromPickup: value });
  };

  const setJobSurveySiteDescription = (value: string) => {
    setJobSurvey({ ...jobSurvey, siteDescription: value });
  };

  const setJobSurveyContact = (contact: ContactModel) => {
    setJobSurvey({ ...jobSurvey, contact: contact });
  };

  const setJobSurveyContact2 = (contact: ContactModel) => {
    setJobSurvey({ ...jobSurvey, contact2: contact });
  };

  const setJobSurveyIsValidProduct = (identifier: string, isValid: boolean): void => {
    const updatedJobSurvey = { ...jobSurvey };
    const item = updatedJobSurvey.items.find((i) => i.identifier === identifier);
    if (item) {
      item.isValid = isValid;
    }

    setJobSurvey(updatedJobSurvey);
  };

  const setJobSurveyCustomerReference = (reference: string) => {
    setJobSurvey({ ...jobSurvey, customerReference: reference });
  };

  const setJobSurveyProductStart = (
    identifier: string,
    isAccessoire: boolean,
    startDate: number
  ): void => {
    const updatedJobSurvey = { ...jobSurvey };

    let hasChanged = false;
    if (!isAccessoire) {
      const item = updatedJobSurvey.items.find((i) => i.identifier === identifier);
      if (item) {
        hasChanged = item.start !== startDate;
        item.start = startDate;
      }
    } else {
      const item = updatedJobSurvey.accessoires.find(
        (i) => i.identifier === identifier
      );
      if (item) {
        hasChanged = item.start !== startDate;
        item.start = startDate;
      }
    }
    if (hasChanged) {
      updatedJobSurvey.offers = [];
    }


    setJobSurvey(updatedJobSurvey);
  };

  const setJobSurveyProductEnd = (
    identifier: string,
    isAccessoire: boolean,
    endDate: number | null
  ): void => {
    const updatedJobSurvey = { ...jobSurvey };

    let hasChanged = false;
    if (!isAccessoire) {
      const item = updatedJobSurvey.items.find((i) => i.identifier === identifier);
      if (item) {
        hasChanged = item.end !== endDate;
        item.end = endDate;
      }
    } else {
      const item = updatedJobSurvey.accessoires.find(
        (i) => i.identifier === identifier
      );
      if (item) {
        hasChanged = item.end !== endDate;
        item.end = endDate;
      }
    }

    if (hasChanged) {
      updatedJobSurvey.offers = [];
    }

    setJobSurvey(updatedJobSurvey);
  };

  const setJobSurveyAlternateDate = (
    identifier: string,
    isAccessoire: boolean,
    deviates: boolean
  ): void => {
    const updatedJobSurvey = { ...jobSurvey };

    let hasChanged = false;
    if (!isAccessoire) {
      const item = updatedJobSurvey.items.find((i) => i.identifier === identifier);
      if (item) {
        hasChanged = item.alternateDate !== deviates;
        item.alternateDate = deviates;
      }
    } else {
      const item = updatedJobSurvey.accessoires.find((i) => i.identifier === identifier);
      if (item) {
        hasChanged = item.alternateDate !== deviates;
        item.alternateDate = deviates;
      }
    }

    if (hasChanged) {
      updatedJobSurvey.offers = [];
    }

    setJobSurvey(updatedJobSurvey);
  };

  const removeFromJobSurvey = (identifier: string, isAccessoire: boolean): void => {
    const updatedJobSurvey = { ...jobSurvey };

    if (!isAccessoire) {
      const index = updatedJobSurvey.items.findIndex(
        (i) => i.identifier === identifier
      );
      if (index >= 0) {
        updatedJobSurvey.items.splice(index, 1);
      }
    } else {
      const index = updatedJobSurvey.accessoires.findIndex(
        (i) => i.identifier === identifier
      );
      if (index >= 0) {
        updatedJobSurvey.accessoires.splice(index, 1);
      }
    }

    setJobSurvey(updatedJobSurvey);
  };

  const addProductToJobSurvey = (product: ProductModel, isAccessoire: boolean) => {
    setMessage({
      message: `${t('offers:added-to-job-survey', { product: product.productGroup.description })}`,
      type: 'success',
      open: true
    });

    const updatedJobSurvey = { ...jobSurvey };

    const initCartItem: Omit<CartItem, 'product'> = {
      isAccessoire: false,
      isValid: true,
      identifier: '',
      start: formatToWorkingHourDate({ hour: workingHours.open }),
      end: null,
      alternateDate: false
    };

    if (!isAccessoire) {
      updatedJobSurvey.items.push({
        ...initCartItem,
        product: product,
        identifier: `${product.productNumber}${product.id}${jobSurvey.items.length}`
      });
    } else {
      updatedJobSurvey.accessoires.push({
        ...initCartItem,
        product: product,
        isAccessoire: true,
        identifier: `${product.productNumber}${product.id}${jobSurvey.accessoires.length}`
      });
    }

    setJobSurvey(updatedJobSurvey);
  };

  const addressByPostalCode = (postalCode: string, houseNumber: string) => {
    return getAddressByPostalCode(postalCode, houseNumber);
  };

  const resetJobSurvey = () => {
    setJobSurvey(JSON.parse(JSON.stringify(initCart)));
  };

  const context = {
    jobSurvey,
    setJobSurvey,

    setJobSurveyStart,
    setJobSurveyEnd,
    setJobSurveyOrganisation,
    setJobSurveySite,
    setJobSurveyEarlyDelivery,
    setJobSurveyDriver,
    setJobSurveySiteFromPickup,
    setJobSurveySiteDescription,
    setJobSurveyContact,
    setJobSurveyContact2,
    setJobSurveyProductStart,
    setJobSurveyProductEnd,
    setJobSurveyAlternateDate,
    setJobSurveyIsValidProduct,
    setJobSurveyCustomerReference,

    searchByPostalCode,
    initForm,
    initSite,
    initOffer,
    jobSurveySchema,
    addProductToJobSurvey,
    resetJobSurvey,
    removeFromJobSurvey
  };

  return (
    <JobSurveysContext.Provider value={context}>{props.children}</JobSurveysContext.Provider>
  );
};
