import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { NotificationModel, NotificationService } from '../../../services/NotificationService';
import { useApi } from '../../../hooks/api/useApi';
import { usePopup } from "../../../hooks/usePopupMessage";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "../../../hooks/useKeycloak";

interface NotificationsHook {
  loading: boolean;
  menuRef?: HTMLElement | null;
  isMenuOpen: boolean;
  onToggleMenu: (event?: MouseEvent<HTMLElement>) => void;
  onLoad: () => Promise<void>;
  notifications: NotificationModel
  showOrganisationNotifications: boolean
  totalNotifications: number;
}

const defaultNotifications: NotificationModel = {
  totalCompletedIntakes: 0,
  totalIntakesToComplete: 0,
  totalPastDueInvoices: 0,
  totalActiveOrders: 0
}

export default function useNotifications(): NotificationsHook {
  const { t } = useTranslation(['common']);
  const notificationService = useApi(new NotificationService());
  const { setMessage } = usePopup();
  const { token, isAccountManager, isLoggedIn } = useKeycloak();

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState(defaultNotifications);
  const [menuRef, setMenuRef] = useState<null | HTMLElement | undefined>(null);

  useEffect(() => {
    onLoad();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onToggleMenu = (event?: MouseEvent<HTMLElement>) => {
    if (event) {
      setMenuRef(event.currentTarget);
    } else {
      setMenuRef(null);
    }
  }

  const onLoad = (): Promise<void> => {
    setLoading(true);
    return notificationService
      .getNotifications()
      .then((data) => setNotifications(data.result))
      .catch(() => {
        setNotifications(defaultNotifications);
        setMessage({
          message: `${t('common:error.notifications')}`,
          type: 'error',
          open: true
        });
      })
      .finally(() => setLoading(false))
  };

  const showOrganisationNotifications = useMemo(
    () => isLoggedIn() && !isAccountManager(),
    [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const totalNotifications = useMemo(() => {
    if (showOrganisationNotifications) {
      return notifications.totalPastDueInvoices + notifications.totalCompletedIntakes + notifications.totalActiveOrders;
    } else if (isAccountManager()) {
      return notifications.totalIntakesToComplete;
    } else {
      return 0;
    }
  }, [token, showOrganisationNotifications, notifications]); // eslint-disable-line react-hooks/exhaustive-deps


  return {
    loading,
    menuRef,
    isMenuOpen: Boolean(menuRef),
    onToggleMenu,
    onLoad,
    notifications,
    showOrganisationNotifications,
    totalNotifications
  };
}
