import { DatePicker } from '@mui/x-date-pickers';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { CartItem } from '../../hooks/models/cart';
import { useCart } from '../../hooks/useCart';
import OutlinedBox from '../layout/OutlinedBox';
import { useDateTime } from '../../hooks/useDateTime';
import { useEffect } from 'react';
import { getTimeFromDate } from '../../utils/dateFormat';
import { useTranslation } from 'react-i18next';
import { useJobSurvey } from '../../hooks/useJobSurvey';

interface PlanningRowProps {
  item: CartItem;
  hideAlternateTime?: boolean;
  type?: 'cart' | 'job-survey';
}

export default function PlanningRow({ item, hideAlternateTime, type = 'cart' }: PlanningRowProps) {
  const {
    cart,
    setProductStart,
    setProductEnd,
    setAlternateDate,
    setIsValidProduct
  } = useCart();

  const {
    jobSurvey,
    setJobSurveyProductStart,
    setJobSurveyProductEnd,
    setJobSurveyAlternateDate,
    setJobSurveyIsValidProduct
  } = useJobSurvey();

  const { t } = useTranslation('checkout');

  const dates = useDateTime(
    item.start,
    item.end,
    (start) => {
      switch (type) {
        case 'cart':
          setProductStart(item.identifier, item.isAccessoire, start);
          break;
        case 'job-survey':
          setJobSurveyProductStart(item.identifier, item.isAccessoire, start);
          break;
      }
    },
    (end) => {
      switch (type) {
        case 'cart':
          setProductEnd(item.identifier, item.isAccessoire, end);
          break;
        case 'job-survey':
          setJobSurveyProductEnd(item.identifier, item.isAccessoire, end);
          break;
      }
    }
  );

  const {
    identifier,
    start: itemStartDate,
    end: itemEndDate,
    alternateDate: itemIsAlternativeDate
  } = item;

  useEffect(() => {
    if (itemIsAlternativeDate) {
      if (itemEndDate && itemStartDate >= itemEndDate) {
        dates.setDateError({
          error: true,
          errorText: 'Datum in huur ligt verder dan de einde huur datum'
        });

        handleSetIsValidProduct(identifier, false);
      } else {
        dates.setDateError({
          error: false,
          errorText: ''
        });

        handleSetIsValidProduct(identifier, true);
      }
    } else {
      dates.setDateError({
        error: false,
        errorText: ''
      });

      handleSetIsValidProduct(identifier, true);
    }
  }, [identifier, itemIsAlternativeDate, itemStartDate, itemEndDate]);

  const quarters = [0, 15, 30, 45];
  const hours = Array.from({ length: 14 }, (_, i) => i + 6);

  const getSelectItems = () => {
    return hours.flatMap((hour, i) => {
      if (i !== hours.length - 1) {
        return quarters.map((option) => (
          <MenuItem
            key={hour + option}
            value={`${hour.toString()}:${
              option === 0 ? '00' : option.toString()
            }`}
          >
            {`${hour.toString()}:${option.toString().padStart(2, '0')}`}
          </MenuItem>
        ));
      } else {
        return (
          <MenuItem key={hour + '00'} value={`${hour.toString()}:00`}>
            {`${hour.toString()}:00`}
          </MenuItem>
        );
      }
    });
  };

  const handleSetAlternateDate = (e: any) => {
    if (type === 'cart') {
      setAlternateDate(
        item.identifier,
        item.isAccessoire,
        e.target.checked
      );
    }

    if (type === 'job-survey') {
      setJobSurveyAlternateDate(
        item.identifier,
        item.isAccessoire,
        e.target.checked
      );
    }
  };

  const getContext = () => {
    switch (type) {
      case 'cart':
        return cart;
      case 'job-survey':
        return jobSurvey;
    }
  };

  const handleSetDate = () => {
    const context = getContext();

    if (context.start) {
      dates.setStartDate(context.start);
      dates.setStartTime(getTimeFromDate(context.start));
    }

    if (context.end) {
      dates.setEndDate(context.end);
      dates.setEndTime(getTimeFromDate(context.end));
    }
  };

  const handleSetIsValidProduct = (identifier: string, isValid: boolean) => {
    if (type === 'cart') {
      setIsValidProduct(identifier, isValid);
    }

    if (type === 'job-survey') {
      setJobSurveyIsValidProduct(identifier, isValid);
    }
  }

  return (
    <OutlinedBox>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                  {item.product.productGroup.description}
                </Grid>
                <Grid item xs={12}>
                  {item.product.description}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      {!hideAlternateTime && <>
        <Divider
          sx={{
            marginTop: 1,
            marginBottom: 1
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Typography variant="subtitle2">{t('planning.label')}</Typography>
              <Grid item xs={12}>
                <FormControlLabel
                  sx={{ paddingBottom: '10px' }}
                  control={
                    <Checkbox
                      checked={item.alternateDate}
                      onChange={(e) => {
                        handleSetAlternateDate(e);
                        handleSetDate();
                      }}
                    />
                  }
                  label={t('machines.deviating')}
                />
              </Grid>
              {item.alternateDate && (
                <>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        label={t('planning.start')}
                        renderInput={(props) => (
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            {...props}
                            error={dates.dateError.error}
                            helperText={dates.dateError.errorText}
                          />
                        )}
                        onChange={(date) => {
                          if (date) dates.setStartDate(date);
                        }}
                        inputFormat="dd/MM/yyyy"
                        value={dates.startDate}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Select
                        fullWidth
                        value={dates.startTime}
                        style={{ height: '56px', lineHeight: '1.5em' }}
                        onChange={(e) => {
                          dates.setStartTime(e.target.value);
                        }}
                      >
                        {getSelectItems()}
                      </Select>
                    </Grid>
                    <Grid item md={3}></Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={2} mt={1}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        label={t('planning.end')}
                        renderInput={(props) => (
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            {...props}
                          />
                        )}
                        onChange={(date) => {
                          if (date) dates.setEndDate(date);
                        }}
                        inputFormat="dd/MM/yyyy"
                        value={item.end}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Select
                        fullWidth
                        id="endTime"
                        value={dates.endTime}
                        style={{ height: '56px', lineHeight: '1.5em' }}
                        onChange={(e) => {
                          dates.setEndTime(e.target.value || '');
                        }}
                      >
                        {getSelectItems()}
                      </Select>
                    </Grid>
                    <Grid item md={3}></Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
      }
    </OutlinedBox>
  );
}
