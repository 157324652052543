import React, { useRef, useState } from 'react';
import {
  Button,
  TextField,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Box
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import MenuItem from '@mui/material/MenuItem';
import HourSelect from './HourSelect';

export type BookOutOption =
  | 'book-out-direct'
  | 'book-out-endofday'
  | 'book-out-custom';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BookOutOption = {
  Direct: 'book-out-direct' as BookOutOption,
  EndOfDay: 'book-out-endofday' as BookOutOption,
  Custom: 'book-out-custom' as BookOutOption
};

export type BookOutOfferProps = {
  date: Date;
  time: string;
  onClick: () => void;
};

export default function BookOutOffer(props: BookOutOfferProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const options = [
    BookOutOption.Direct,
    BookOutOption.EndOfDay,
    BookOutOption.Custom
  ];
  const [date, setDate] = useState<Date>(props.date);
  const [time, setTime] = useState<string>(props.time);

  const getButtonTranslation = (option: BookOutOption) => {
    if (option === BookOutOption.Custom) {
      return 'Specifieke datum/tijd afmelden';
    } else if (option === BookOutOption.EndOfDay) {
      return 'Einde dag afmelden';
    } else {
      return 'Direct afmelden';
    }
  };

  const handleClick = () => {
    props.onClick();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={{ marginBottom: '12px' }}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleClick}>Direct afmelden</Button>
      </ButtonGroup>
      {options[selectedIndex] === BookOutOption.Custom && (
        <Box sx={{ paddingTop: '15px', display: 'flex', flexDirection: 'row' }}>
            <DatePicker
              label="Datum"
              renderInput={(props) => (
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  {...props}
                />
              )}
              onChange={(date) => {
                if (date !== null) {
                  setDate(date);
                }
              }}
              inputFormat="dd/MM/yyyy"
              value={date}
            />
          <Box sx={{ paddingLeft: '10px' }}>
            <HourSelect time={time} onChange={(val) => setTime(val)} />
          </Box>
        </Box>
      )}
      <Popper
        style={{ zIndex: 10000 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {getButtonTranslation(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
