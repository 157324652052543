import { useTranslation } from 'react-i18next';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import InvoiceTableRow from './InvoiceTableRow';
import { useInvoiceFilteredList } from '../../hooks/api/useInvoices';
import Loader from '../common/Loader';
import EmptyState from '../layout/feedback/EmptyState';

export default function InvoiceTable() {
  const { t } = useTranslation(['invoices']);
  const { data, isLoading } = useInvoiceFilteredList();

  if (isLoading) {
    return (
      <Loader />
    );
  }
  if (data?.result !== undefined && data?.result.items.length === 0) {
    return (
      <EmptyState />
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('invoices:invoice-number')}</TableCell>
            <TableCell>{t('invoices:invoice-date')}</TableCell>
            <TableCell>{t('invoices:expiration-date')}</TableCell>
            <TableCell>{t('invoices:customer-reference')}</TableCell>
            <TableCell>{t('invoices:outstanding-balance')}</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.result.items.flatMap((cluster) => {
            return (
              <InvoiceTableRow
                key={cluster.entries[0].id}
                cluster={cluster}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
