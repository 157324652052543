import {
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../hooks/api/useApi';
import { useQueryParams } from '../../hooks/queryParams/useProductFilters';
import {
  ProductFilterModel,
  productFilterParams,
  ProductService
} from '../../services/ProductService';
import { measurement } from '../../utils/const/measurement';
import SideBar from '../layout/SideBar';
import SidebarSearchField from '../layout/SidebarSearchField';
import { CheckboxFilters } from '../common/CheckboxFilters';

export default function ProductFilters() {
  const productService = useApi<ProductService>(new ProductService());
  const [filterValues, setFilterValues] = useState<ProductFilterModel>();
  const { params, changeParam, setParams } = useQueryParams();

  const { t } = useTranslation(['products', 'common']);

  useEffect(() => {
    productService.getProductFilters().then((data) => setFilterValues(data.result));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SideBar sx={{ minWidth: 300 }}>
      <SidebarSearchField name="search" changeParam={changeParam}
                          placeholder={`${t('common:search-machine')}`} />

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="subtitle2" gutterBottom>
          {t('products:filter.article-groups')}
        </Typography>

        {filterValues?.productGroups ?
          <Select
            style={{ width: '100%' }}
            value={params.productGroup ?? ''}
            onChange={(event: SelectChangeEvent) => {
              setParams({ ...params, productGroup: event.target.value });
            }}
          >
            <MenuItem key={'empty'} value={''}>-</MenuItem>
            {
              filterValues.productGroups.map((value, i) => {
                return (
                  <MenuItem key={`${i}`} value={value.id}>{value.description}</MenuItem>
                );
              })
            }
          </Select> : <></>}
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        {
          filterValues?.workHeightSteps &&
          <>
            <Typography variant="subtitle2" gutterBottom>
              {t('products:work-height')} ({measurement.length})
            </Typography>
            <CheckboxFilters filterValues={filterValues?.workHeightSteps}
                             filterProperty={productFilterParams.workHeightSteps} />
          </>
        }
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        {
          filterValues?.reachLengthSteps &&
          <>
            <Typography variant="subtitle2" gutterBottom>
              {t('products:reach')} ({measurement.length})
            </Typography>
            <CheckboxFilters filterValues={filterValues?.reachLengthSteps}
                             filterProperty={productFilterParams.reachLengthSteps} />
          </>
        }
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        {
          filterValues?.liftPowerSteps &&
          <>
            <Typography variant="subtitle2" gutterBottom>
              {t('products:lift-power')} ({measurement.weight})
            </Typography>
            <CheckboxFilters filterValues={filterValues?.liftPowerSteps}
                             filterProperty={productFilterParams.liftPowerSteps} />
          </>
        }
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        {
          filterValues?.weightSteps &&
          <>
            <Typography variant="subtitle2" gutterBottom>
              {t('products:weight')} ({measurement.weight})
            </Typography>
            <CheckboxFilters filterValues={filterValues?.weightSteps}
                             filterProperty={productFilterParams.weightSteps} />
          </>
        }
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        {
          filterValues?.driveTypes &&
          <>
            <Typography variant="subtitle2" gutterBottom>{t('products:drive-type')}</Typography>
            <CheckboxFilters driveTypeValues={filterValues.driveTypes}
                             filterProperty={productFilterParams.driveTypes} />
          </>
        }
      </Box>
    </SideBar>
  );
}
