import { Add } from '@mui/icons-material';
import {
  Box,
  TableCell,
  TableRow,
  TextField,
  IconButton
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopup } from '../../hooks/usePopupMessage';
import { ProductModel } from '../../services/ProductService';
import { currencyFormat } from '../../utils/currencyFormat';
import { getWeeklyPrice } from '../../utils/priceHelper';

interface AccessoireRowProps {
  item: ProductModel;
  onAdd: (item: ProductModel, count: number) => void;
  onRequest?: boolean;
  disabled?: boolean;
}

export default function AccessoryRow({
  item,
  onAdd,
  onRequest = false,
  disabled = false
}: AccessoireRowProps): JSX.Element {
  const [count, setCount] = useState(0);

  const { t } = useTranslation('checkout');
  const { setMessage } = usePopup();

  useEffect(() => {
    if (onRequest) {
      setCount(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'row', minWidth: 250 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {item.productGroup.description}
            </Box>
            <Box component="span" sx={{ fontWeight: 'normal' }}>
              {item.productNumber}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right">
        {!onRequest && currencyFormat(getWeeklyPrice(item))}
      </TableCell>
      <TableCell align="right">
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {!onRequest && <TextField
            sx={{ width: 90, marginRight: 2 }}
            type="number"
            value={count}
            onChange={(e) => {
              if (Number(e.target.value) >= 0) {
                setCount(Number(e.target.value));
              }
            }}
            InputLabelProps={{ shrink: true }}
          />}
          {onRequest && <p style={{ marginRight: '16px' }}>Op aanvraag</p>}
          <Box marginY={'auto'}>
            <IconButton
              size="small"
              disabled={count === 0 || disabled}
              onClick={() => {
                onAdd(item, count);
                setMessage({
                  message: t('accessoires.success', {
                    description: item.productGroup.description,
                    productNumber: item.productNumber
                  }),
                  type: 'success',
                  open: true
                });
              }}
            >
              <Add sx={{ color: '#D2D2CF', width: 25, height: 25 }} />
            </IconButton>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}
