import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { addDays, roundToNearestMinutes } from 'date-fns';
import { ProductModel } from '../services/ProductService';
import { useVersionedLocalStorage } from './useLocalStorage';
import { useKeycloak } from './useKeycloak';
import { CartItem, CartModel, ContactModel } from './models/cart';
import { SiteModel } from '../services/SiteService';
import { DriverModel } from '../services/CheckoutService';
import { OfferModel } from '../services/models/Offer';

interface DateOptions {
  hour?: number;
  minute?: number;
  daysInFuture?: number;
}

interface CartContextModel {
  cart: CartModel;
  setCart: (s: CartModel) => void;
  cartItemsClone: CartItem[];
  setCartItemsClone: (items: CartItem[]) => void;
  customerReferenceChanged: boolean;
  setCustomerReferenceChanged: (bool: boolean) => void;
  setCartStart: (start: number) => void;
  setCartEnd: (end: number | null) => void;
  addToCart: (item: ProductModel, isAccessoire: boolean) => void;
  addMultipleToCart: (items: { item: ProductModel, isAccessoire: boolean }[], resetItems?: boolean) => void;
  offers: OfferModel[];
  setOffers: (offers: OfferModel[]) => void;
  removeFromCart: (identifier: string, isAccessoire: boolean) => void;
  isEmpty: () => boolean;
  isEmptyAccessories: () => boolean;
  productCount: () => number;
  setContact: (contact: ContactModel) => void;
  setContact2: (contact: ContactModel) => void;
  setIsValidProduct: (identifier: string, isValid: boolean) => void;
  setProductStart: (
    identifier: string,
    isAccessoire: boolean,
    startDate: number
  ) => void;
  setProductEnd: (
    identifier: string,
    isAccessoire: boolean,
    endDate: number | null
  ) => void;
  setAlternateDate: (
    identifier: string,
    isAccessoire: boolean,
    deviates: boolean
  ) => void;
  resetCart: () => void;
  earlyDelivery: boolean;
  setEarlyDelivery: Dispatch<SetStateAction<boolean>>;
  formatToWorkingHourDate: ({ hour, daysInFuture }: DateOptions) => number;
}

export const workingHours = {
  open: 7,
  close: 19
}; // UTC +1, so 7 becomes 8

export const formatToWorkingHourDate = ({ hour, daysInFuture }: DateOptions): number => {
  const date = addDays(roundToNearestMinutes(new Date(), {
    nearestTo: 15
  }), daysInFuture ?? 0);

  date.setUTCHours(hour ?? date.getUTCHours());

  if (date.getUTCHours() < workingHours.open) {
    date.setUTCHours(workingHours.open, 0, 0);
  } else if (date.getUTCHours() > workingHours.close) {
    date.setUTCHours(workingHours.close, 0, 0);
  }

  return date.getTime();
};

export const initSite: SiteModel = {
  id: '',
  siteNumber: '0',
  searchName: '',
  name: '',
  street: '',
  postalCode: '',
  city: '',
  houseNumber: '',
  telephoneNumber: ''
};

export const initContact: ContactModel = {
  name: '', phoneNumber: ''
};

export const initDriver: DriverModel = {
  name: '',
  phoneNumber: '',
  misc: ''
};

export const initCart: CartModel = {
  items: [],
  accessoires: [],
  start: null,
  end: null,
  offers: [],
  contact: initContact,
  contact2: initContact,
  site: initSite,
  driver: initDriver,
  customerReference: '',
  organisation: '',
  siteDescription: '',
  siteFromApi: false,
  siteFromPickup: false,
  description: '',
  earlyDelivery: false
};

const emptyCart: CartModel = {
  items: [],
  accessoires: [],
  start: null,
  end: null,
  offers: [],
  contact: initContact,
  contact2: initContact,
  site: initSite,
  driver: initDriver,
  customerReference: '',
  organisation: '',
  siteDescription: '',
  siteFromApi: false,
  siteFromPickup: false,
  description: '',
  earlyDelivery: false
};

const CartContext = createContext<CartContextModel | undefined>(undefined);

export function useCart() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('`useCart` must be used with an `CartContextProvider`');
  }
  return context;
}

export const CartContextProvider = (props: PropsWithChildren<unknown>) => {
  const { token } = useKeycloak();

  const [earlyDelivery, setEarlyDelivery] = useState(false);
  const [offers, setOffers] = useState<OfferModel[]>([]);
  const [cartItemsClone, setCartItemsClone] = useState<CartItem[]>([]);
  const [customerReferenceChanged, setCustomerReferenceChanged] = useState(false);

  // TODO: ideally don't track in Localstorage but in State
  const [cart, setCart] = useVersionedLocalStorage<CartModel>(
    1,
    token.parsed?.user_id + 'cart',
    initCart
  );

  useEffect(() => {
    setCart({ ...cart, earlyDelivery: earlyDelivery });
  }, [earlyDelivery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const updatedCart = { ...cart };
    updatedCart.offers = offers;
    setCart(updatedCart);
  }, [offers]); // eslint-disable-line react-hooks/exhaustive-deps


  const setCartStart = (start: number): void => {
    const updatedCart = { ...cart };

    let hasChanged = updatedCart.start !== start;
    updatedCart.start = start;

    updatedCart.items.forEach((item) => {
      if (!item.alternateDate) {
        hasChanged = item.start !== start;
        item.start = start;
      }
    });

    updatedCart.accessoires.forEach((item) => {
      if (!item.alternateDate) {
        hasChanged = item.start !== start;
        item.start = start;
      }
    });

    if (hasChanged) {
      updatedCart.offers = [];
    }

    setCart(updatedCart);
  };

  const setCartEnd = (end: number | null): void => {
    const updatedCart = { ...cart };

    let hasChanged = updatedCart.end !== end;
    updatedCart.end = end;

    updatedCart.items.forEach((item) => {
      if (!item.alternateDate) {
        hasChanged = item.end !== end;
        item.end = end;
      }
    });

    updatedCart.accessoires.forEach((item) => {
      if (!item.alternateDate) {
        hasChanged = item.end !== end;
        item.end = end;
      }
    });

    if (hasChanged) {
      updatedCart.offers = [];
    }

    setCart(updatedCart);
  };

  const addToCart = (item: ProductModel, isAccessoire: boolean, resetItems = false): void => {
    const updatedCart = { ...cart, items: resetItems ? [] : cart.items };
    updatedCart.offers = [];

    const initCartItem: Omit<CartItem, 'product'> = {
      isAccessoire: false,
      isValid: true,
      identifier: '',
      start: formatToWorkingHourDate({ hour: workingHours.open }),
      end: null,
      alternateDate: false
    };

    if (!isAccessoire) {
      updatedCart.items.push({
        ...initCartItem,
        product: item,
        identifier: `${item.productNumber}${item.id}${cart.items.length}`
      });
    } else {
      updatedCart.accessoires.push({
        ...initCartItem,
        product: item,
        isAccessoire: true,
        identifier: `${item.productNumber}${item.id}${cart.accessoires.length}`
      });
    }

    setCart(updatedCart);
  };

  const addMultipleToCart = (items: { item: ProductModel, isAccessoire: boolean }[], resetItems = false) => {
    items.forEach((x, index) => {
      addToCart(x.item, x.isAccessoire, index === 0 && resetItems);
    });
  };

  const removeFromCart = (identifier: string, isAccessoire: boolean): void => {
    const updatedCart = { ...cart };
    updatedCart.offers = [];

    if (!isAccessoire) {
      const index = updatedCart.items.findIndex(
        (i) => i.identifier === identifier
      );
      if (index >= 0) {
        updatedCart.items.splice(index, 1);
      }
    } else {
      const index = updatedCart.accessoires.findIndex(
        (i) => i.identifier === identifier
      );
      if (index >= 0) {
        updatedCart.accessoires.splice(index, 1);
      }
    }

    setCart(updatedCart);
  };

  const productCount = (): number => {
    return cart.items.length + cart.accessoires.length;
  };

  const setContact = (contact: ContactModel): void => {
    setCart({ ...cart, contact: contact });
  };

  const setContact2 = (contact: ContactModel): void => {
    setCart({ ...cart, contact2: contact });
  };

  const setIsValidProduct = (identifier: string, isValid: boolean): void => {
    const updatedCart = { ...cart };
    const item = updatedCart.items.find((i) => i.identifier === identifier);
    if (item) {
      item.isValid = isValid;
    }

    setCart(updatedCart);
  };

  const setAlternateDate = (
    identifier: string,
    isAccessoire: boolean,
    deviates: boolean
  ): void => {
    const updatedCart = { ...cart };

    let hasChanged = false;
    if (!isAccessoire) {
      const item = updatedCart.items.find((i) => i.identifier === identifier);
      if (item) {
        hasChanged = item.alternateDate !== deviates;
        item.alternateDate = deviates;
      }
    } else {
      const item = updatedCart.accessoires.find((i) => i.identifier === identifier);
      if (item) {
        hasChanged = item.alternateDate !== deviates;
        item.alternateDate = deviates;
      }
    }

    if (hasChanged) {
      updatedCart.offers = [];
    }

    setCart(updatedCart);
  };

  const setProductStart = (
    identifier: string,
    isAccessoire: boolean,
    startDate: number
  ): void => {
    const updatedCart = { ...cart };

    let hasChanged = false;
    if (!isAccessoire) {
      const item = updatedCart.items.find((i) => i.identifier === identifier);
      if (item) {
        hasChanged = item.start !== startDate;
        item.start = startDate;
      }
    } else {
      const item = updatedCart.accessoires.find(
        (i) => i.identifier === identifier
      );
      if (item) {
        hasChanged = item.start !== startDate;
        item.start = startDate;
      }
    }
    if (hasChanged) {
      updatedCart.offers = [];
    }

    setCart(updatedCart);
  };

  const setProductEnd = (
    identifier: string,
    isAccessoire: boolean,
    endDate: number | null
  ): void => {
    const updatedCart = { ...cart };

    let hasChanged = false;
    if (!isAccessoire) {
      const item = updatedCart.items.find((i) => i.identifier === identifier);
      if (item) {
        hasChanged = item.end !== endDate;
        item.end = endDate;
      }
    } else {
      const item = updatedCart.accessoires.find(
        (i) => i.identifier === identifier
      );
      if (item) {
        hasChanged = item.end !== endDate;
        item.end = endDate;
      }
    }

    if (hasChanged) {
      updatedCart.offers = [];
    }

    setCart(updatedCart);
  };

  const isEmpty = () => cart.items.length === 0;
  const isEmptyAccessories = () => cart.accessoires.length === 0;
  const resetCart = () => {
    setCart(JSON.parse(JSON.stringify(emptyCart)));
  };

  const context = {
    cart,
    setCart,

    setCartStart,
    setCartEnd,
    addToCart,
    addMultipleToCart,
    offers,
    setOffers,
    removeFromCart,
    productCount,
    setContact,
    setContact2,
    setProductStart,
    setProductEnd,
    setIsValidProduct,
    setAlternateDate,
    isEmpty,
    isEmptyAccessories,
    resetCart,
    earlyDelivery,
    setEarlyDelivery,
    formatToWorkingHourDate,
    cartItemsClone,
    setCartItemsClone,
    customerReferenceChanged,
    setCustomerReferenceChanged
  };

  return (
    <CartContext.Provider value={context}>
      {props.children}
    </CartContext.Provider>
  );
};
