import { TableCell, TableRow } from '@mui/material';
import { ClusterModel } from '../../services/models/Offer';
import { useHistory } from 'react-router-dom';
import Status from '../layout/Status';
import { formatDateOnly } from '../../utils/dateFormat';
import { ProductModel, ProductService } from '../../services/ProductService';
import { useApi } from '../../hooks/api/useApi';
import { useCart } from '../../hooks/useCart';
import { useTranslation } from 'react-i18next';
import { usePopup } from '../../hooks/usePopupMessage';
import MenuItem from '@mui/material/MenuItem';
import TableAddressCell from '../common/TableAddressCell';
import TableDropdownCell from '../common/TableDropdownCell';

interface OfferTableRowProps {
  cluster: ClusterModel;
}

export default function OfferTableRow({ cluster }: OfferTableRowProps) {
  const { t } = useTranslation(['orders', 'checkout', 'common']);
  const { addMultipleToCart } = useCart();
  const { setMessage } = usePopup();

  const productService = useApi<ProductService>(new ProductService());

  const history = useHistory();

  const offer = cluster.entries[0];

  const onReorderClick = () => {
    const productNumbers = cluster.entries
      .filter((offer) => offer.productNumber)
      .map((offer) => offer.productNumber as string);

    if (productNumbers.length === 0) {
      setMessage({
        message: `${t('checkout:machines.no-items')}.`,
        type: 'info',
        open: true
      });
      return;
    }

    const cartItems: { item: ProductModel, isAccessoire: boolean }[] = [];

    productNumbers.forEach((e) => {
      productService
        .getProductByProductNumber(e)
        .then((data) => {
          cartItems.push({ item: data.result, isAccessoire: false });
        })
        .finally(() => {
          if (cartItems) {
            setMessage({
              message: `${t('checkout:machines.multiple-to-cart')}`,
              type: 'success',
              open: true
            });

            addMultipleToCart(cartItems, true);
            history.push('/checkout');
          }
        });
    });
  };

  return (
    <TableRow key={offer.id}>
      <TableAddressCell site={offer.site}></TableAddressCell>
      <TableCell>
        <Status status={offer.status} />
      </TableCell>
      <TableCell>{formatDateOnly(offer.offerDate)}</TableCell>
      <TableCell>{offer.customerReference ?? '-' }</TableCell>
      <TableDropdownCell>
        <MenuItem onClick={() => {
          history.push(
            '/huuropdrachten/' + offer.clusterIdentifier
          );
        }}>{t('common:details')}</MenuItem>
        <MenuItem onClick={onReorderClick}>{t('checkout:order-again')}</MenuItem>
      </TableDropdownCell>
    </TableRow>
  );
}
