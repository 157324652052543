import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import Loader from '../common/Loader';
import EmptyState from '../layout/feedback/EmptyState';
import { useJobSurveyFilteredList } from '../../hooks/api/useOffers';
import JobSurveyTableRow from './JobSurveyTableRow';
import { useTranslation } from 'react-i18next';

export default function JobSurveyTable() {
  const { t } = useTranslation(['orders']);
  const { data, isLoading } = useJobSurveyFilteredList();

  const filteredData = data?.result.items;

  if (isLoading) {
    return (
      <Loader />
    );
  }
  if (data?.result !== undefined && filteredData?.length === 0) {
    return (
      <EmptyState />
    );
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('orders:work-address')}</TableCell>
            <TableCell>{t('orders:status')}</TableCell>
            <TableCell>{t('orders:requested-on')}</TableCell>
            <TableCell>{t('orders:your-reference')}</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData?.flatMap((cluster) => {
            return <JobSurveyTableRow key={cluster.entries[0].id} offer={cluster.entries[0]} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
