import { useProductFilteredList } from '../../hooks/api/useProducts';
import { ProductModel } from '../../services/ProductService';
import { useEffect, useState } from 'react';
import { useQueryParams } from '../../hooks/queryParams/useProductFilters';
import { Box, Card } from '@mui/material';
import NavigationButtons from '../cart/NavigationButtons';
import ProductTable from './ProductsTable';
import ProductFilters from './ProductFilters';

interface ProductPaginationProps {
  onAddProduct: (item: ProductModel, isAccessoire: boolean) => void;
  pageSize: number;
}

export default function ProductPagination({ onAddProduct, pageSize }: ProductPaginationProps) {
  const [page, setPage] = useState<number>(0);
  const { params } = useQueryParams();
  const {
    data,
    isLoading,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useProductFilteredList({ pageSize: pageSize, ...params });

  useEffect(() => {
    setPage(0);
    refetch();
  }, [params, refetch]);

  const getProducts = (): ProductModel[] => {
    if (data && page in data.pages) {
      return data?.pages[page].result.items;
    } else {
      return [];
    }
  };

  return (
    <>
      <ProductFilters />
      <Box
        sx={{
          display: 'flex',
          gap: 5,
          flexGrow: 1,
          flexDirection: 'column'
        }}
      >
        <Card sx={{ flex: 1 }}>
          <ProductTable
            products={getProducts()}
            isLoading={isLoading || isFetchingNextPage}
            onAddProduct={onAddProduct}
          />

        </Card>
        <NavigationButtons
          nextDisabled={!hasNextPage}
          previousDisabled={page === 0}
          next={() => {
            if (hasNextPage) {
              fetchNextPage();
              setPage(page + 1);
            }
          }}
          previous={() => {
            if (page > 0) {
              setPage(page - 1);
            }
          }}
        />
      </Box>
    </>
  );
}
