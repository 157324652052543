import { CircularProgress, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import { formatDateOnly } from '../../utils/dateFormat';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useEffect, useState } from 'react';
import { InvoiceClusterModel, InvoiceService } from '../../services/InvoiceService';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../hooks/api/useApi';
import { usePopup } from '../../hooks/usePopupMessage';

interface InvoiceTableRowProps {
  cluster: InvoiceClusterModel;
}

export const colorByDaysExpired = (days: number): string => {
  if (days >= 0) {
    if (days >= 90) {
      return '#FF0100';
    } else if (days >= 60) {
      return '#FF9999';
    } else if (days >= 30) {
      return '#FFCC00';
    } else if (days >= 0) {
      return '#FFEC3A';
    }
  }

  return '#0ed30e';
};

export default function InvoiceTableRow({ cluster }: InvoiceTableRowProps) {
  const { t } = useTranslation(['invoices']);
  const { setMessage } = usePopup();

  const [daysExpired, setDaysExpired] = useState<number>(0);
  const [pdfLoading, setPdfLoading] = useState(false);

  const service = useApi<InvoiceService>(new InvoiceService());

  const invoice = cluster.entries[0];

  useEffect(() => {
    const timeDiff = new Date().getTime() - new Date(cluster.entries[0].expirationDate).getTime();
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);

    setDaysExpired(Math.round(daysDiff * 10) / 10);
  }, [cluster]);

  const downloadPdf = (invoiceId?: string) => {
    if (invoiceId) {
      setPdfLoading(true);
      service.getInvoiceDocument(invoiceId)
        .then((response) => {
          window.open(`https://acceptatie-peinemann.allsolutions.nl${response.result.entries[0].filePaths[0]}`, '_blank');
          setPdfLoading(false);
        })
        .catch(() => {
          setPdfLoading(false);
          setMessage({
            message: `${t('invoices:errors.invoice-document')}`,
            type: 'error',
            open: true
          });
        });
    }
  };

  return (
    <TableRow key={invoice.id}>
      <TableCell>
        {invoice.invoiceNumber}
      </TableCell>
      {/*<TableAddressCell site={invoice.site}></TableAddressCell>*/}
      {/*<TableCell>*/}
      {/*  {invoice.product?.description ?? '-'}*/}
      {/*</TableCell>*/}
      <TableCell>
        {formatDateOnly(invoice.invoiceDate)}
      </TableCell>
      <TableCell>
        <Tooltip
          title={daysExpired > 0 ? t('invoices:expired_days', { days: daysExpired }) : ''}
          placement="top" arrow={true}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          <span style={{
            width: '10px',
            height: '10px',
            background: colorByDaysExpired(daysExpired),
            borderRadius: '100%'
          }}></span>
            {formatDateOnly(invoice.expirationDate)}
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        {invoice.customerReference}
      </TableCell>
      <TableCell>
        {`€${invoice.remainingSaldo}`}
      </TableCell>
      <TableCell>
        <IconButton
          size="small"
          disabled={pdfLoading}
          onClick={() => downloadPdf(invoice.id)}
        >
          {pdfLoading && <CircularProgress
            size={16}
            sx={{
              color: 'red',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-8px',
              marginLeft: '-8px'
            }}
          />}
          <FileDownloadIcon sx={{ width: 25, height: 25, opacity: pdfLoading ? 0 : 1 }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
