import { Box, Button, Grid, IconButton, Skeleton } from '@mui/material';
import { OfferModel, OrderStatus } from '../../services/models/Offer';
import { formatToLocalTimeFromApi } from '../../utils/dateFormat';
import { useGetOrderByOfferId } from '../../hooks/api/useOffers';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CartItemDetailRow from '../common/CartItemDetailRow';
import { Close } from '@mui/icons-material';
import BlueBox from '../layout/BlueBox';
import PageModal from '../common/PageModal';
import { OfferService } from '../../services/OfferService';
import { useQueryClient } from 'react-query';
import { usePopup } from '../../hooks/usePopupMessage';

interface JobSurveyDetailRowProps {
  offer: OfferModel;
  setHasItems: Dispatch<SetStateAction<boolean>>;
  offerService: OfferService;
  canRemove?: boolean;
}

export default function JobSurveyDetailRow({ offer, setHasItems, offerService, canRemove = true }: JobSurveyDetailRowProps) {
  const { data, isLoading } = useGetOrderByOfferId(offer.id || '');
  const { t } = useTranslation(['offers', 'checkout']);
  const { setMessage } = usePopup();

  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const isNewOffer = data?.result.status === OrderStatus.Unknown;
  const queryClient = useQueryClient();

  const onDeleteConfirm = async () => {
    if (!data?.result.id) return;

    setIsDeleting(true);

    await offerService.deleteOfferWithOrder(offer.id ?? '').then(() => {
      // TODO: proper invalidation for orders of this offer
      queryClient.invalidateQueries('offer');

      setMessage({
        message: `${t('order-deleted')}.`,
        type: 'success',
        open: true
      });
    }).catch(() => {
      setMessage({
        message: `${t('order-deleted-error')}.`,
        type: 'error',
        open: true
      });
    })
      .finally(() => {
        setIsDeleting(false);
        setModalOpen(false);
      });
  };

  useEffect(() => {
    setHasItems(data !== undefined && !isLoading);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading && (
        <Grid key={offer.id} item xs={12}>
          <Skeleton sx={{ width: '100%', maxWidth: 'unset' }}>
            <Box sx={{ height: '50px', width: '100%' }}>&nbsp;</Box>
          </Skeleton>
        </Grid>
      )}
      {data ? (
        <>
          <Fragment key={offer.id}>
            <CartItemDetailRow
              product={data.result.product}
              order={data.result}
              startDate={formatToLocalTimeFromApi(data.result.startDate)}
              endDate={data.result.endDate ? formatToLocalTimeFromApi(data.result.endDate) : undefined}
              buttons={
                isNewOffer && canRemove &&
                <Box sx={{
                  display: 'flex',
                  justifyContent: { md: 'right' },
                  marginTop: { xs: 2, md: 0 }
                }}>
                  <IconButton size="small" onClick={() => setModalOpen(true)}>
                    <Close sx={{ color: 'red' }} />
                  </IconButton>
                </Box>

              }
            />
          </Fragment>
        </>
      ) : (
        !isLoading && (
          <BlueBox>
            <Grid item xs={12}>
              {t('machine-not-found', { productNumber: offer.productNumber ?? t('unknown') })}
            </Grid>
          </BlueBox>
        )
      )}

      <PageModal
        isOpen={modalOpen}
        actions={(
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => onDeleteConfirm()}
              disabled={isDeleting}
            >
              {t('common:button.delete')}
            </Button>
            <Button
              variant="text"
              color="info"
              onClick={() => setModalOpen(false)}
              sx={{ marginLeft: 2 }}
            >
              {t('common:button.cancel')}
            </Button>
          </Box>
        )}
      >
        {t('delete-order-confirm')}
      </PageModal>
    </>
  );
}
