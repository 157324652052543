import { Info } from '@mui/icons-material';
import { Box, Button, IconButton, Popover } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductModel } from '../../services/ProductService';

interface ProductPopoverProps{
  product: ProductModel;
}

export default function ProductPopover({ product }: ProductPopoverProps){
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation('common');

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const download = () => {
    if (product?.productImage?.uri) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = product.productImage.uri;
      link.click();
    }
  };

  return (
    <Box>
      <IconButton size="small"
        onClick={handleOpen}
      >
        <Info sx={{ width: 25, height: 25 }} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <img
          src={product?.productImage?.uri}
          width='100%'
          style={{ objectFit: 'contain', maxHeight: '400px' }}
          alt={product?.description} />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          onClick={download}
        >
          {t('button.download')}
        </Button>
      </Popover>
    </Box>
  );
}
