import { format, formatISO } from "date-fns";
import { nl } from 'date-fns/locale';

const defaultDate = 'dd/MM/yyyy';
const defaultTime = 'HH:mm';

export const offersDateFilterFormat = 'yyyy-MM-dd';
export const invoicesDateFilterFormat = 'dd-MM-yyyy';

// Please read the README on how and why date conversions happen

const convertToLocal = (date: string) => formatISO(new Date(date));

export const formatToLocalTimeFromApi = (date: string) => {
  const localDate = convertToLocal(date);
  return formatToLocalTime(new Date(localDate));
};

export const formatToLocalTime = (date: Date) => {
  return format(date, `${defaultDate} ${defaultTime}`, { locale: nl });
}

export const formatDateOnlyFromApi = (date: string) => {
  const localDate = convertToLocal(date);
  return formatDateOnly(localDate);
};

export const formatDateOnly = (date: string) => {
  return format(new Date(date), defaultDate, { locale: nl });
};

/**
 * Returns a hh:mm string from the given epoch
 */
export const getTimeFromDate = (epoch: number | null) => {
  if (!epoch) return '';

  return format(new Date(epoch), defaultTime);
};

export const getMsFromTimeString = (time: string) => {
  const splitTime = time.split(':');
  return (Number(splitTime[0]) * 60 * 60 + Number(splitTime[1]) * 60) * 1000;
};
