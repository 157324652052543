import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, Grid } from '@mui/material';
import PageTitle from '../../components/layout/PageTitle';
import { UserSideMenu } from '../../components/users/UserSideMenu';
import TextFieldShrink from '../../components/layout/TextFieldShrink';
import { useTranslation } from 'react-i18next';
import { useProfile } from '../../hooks/useProfile';

export default function ProfileFormPage() {
  const { t } = useTranslation(['common', 'settings']);
  const { loading, userProfileForm } = useProfile();

  return (
    <>
      <PageTitle title={`${t('title')}`} />
      <Box sx={{
        display: 'flex',
        gap: 5
      }}>
        <UserSideMenu />
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Typography variant="subtitle1">{t('settings:profile.label')}</Typography>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }} sx={{ paddingTop: 2.5 }}
            >
              <Grid item xs={12} md={6}>
                <TextFieldShrink
                  required
                  fullWidth
                  error={userProfileForm.errors.firstName !== undefined && userProfileForm.touched.firstName}
                  helperText={userProfileForm.errors.firstName}
                  onChange={userProfileForm.handleChange}
                  label={t('settings:form.first-name')}
                  name="firstName"
                  placeholder={`${t('settings:form.first-name')}`}
                  value={userProfileForm.values.firstName}
                  disabled={userProfileForm.isSubmitting || loading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldShrink
                  required
                  fullWidth
                  error={userProfileForm.errors.lastName !== undefined && userProfileForm.touched.lastName}
                  helperText={userProfileForm.errors.lastName}
                  onChange={userProfileForm.handleChange}
                  label={t('settings:form.last-name')}
                  name="lastName"
                  placeholder={`${t('settings:form.last-name')}`}
                  value={userProfileForm.values.lastName}
                  disabled={userProfileForm.isSubmitting || loading}
                />
              </Grid>
              <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
                <Button type="submit" variant="contained" onClick={userProfileForm.submitForm}>
                  {t('common:button.save')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
