import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { DriveTypes, ProductStep } from '../../services/ProductService';
import { useQueryParams } from '../../hooks/queryParams/useProductFilters';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface CheckboxFiltersProps {
  filterValues?: ProductStep[];
  driveTypeValues?: DriveTypes[];
  filterProperty: string;
}

export const CheckboxFilters = ({
                                  filterValues,
                                  filterProperty,
                                  driveTypeValues
                                }: CheckboxFiltersProps) => {
  const { params, changeParam } = useQueryParams();
  const { t } = useTranslation('products');

  const changeHandler = (a: SyntheticEvent) => {
    const target = a.target as HTMLInputElement;
    // @ts-ignore
    const currentParams = params[filterProperty];
    const newParams: string[] = currentParams ? currentParams.split(',') : [];

    if (target.checked) {
      changeParam(filterProperty, currentParams ? [currentParams, target.value].join() : target.value);
    } else {
      newParams.splice(newParams.indexOf(target.value), 1);

      changeParam(filterProperty, newParams.join());
    }
  };

  return <FormControl>
    {filterValues && filterValues?.map((filter) => {
      return <FormControlLabel key={`${filterProperty}-${filter.type}`}
                               onChange={changeHandler}
                               control={<Checkbox />}
                               value={filter.type}
                               label={`${filter.min.toLocaleString('nl-NL')}${filter.max ? '-' : '+'}${filter.max ? filter.max.toLocaleString('nl-NL') : ''}`} />;
    })}
    {driveTypeValues && driveTypeValues?.map((driveType) => {
      return <FormControlLabel key={`${driveType}`}
                               onChange={changeHandler}
                               control={<Checkbox />}
                               value={driveType}
                               label={t('drive-type', { context: driveType })} />;
    })}
  </FormControl>;
};
