import { useTranslation } from 'react-i18next';
import PageTitle from '../components/layout/PageTitle';
import { QueryParamsProvider, useQueryParams } from '../hooks/queryParams/useOfferFilters';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { useState } from 'react';
import FilterSideBar from '../components/layout/FilterSideBar';
import SidebarSearchField from '../components/layout/SidebarSearchField';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  debounce
} from '@mui/material';
import InvoiceTable from '../components/invoices/InvoiceTable';
import { InvoiceExpirationFilters, InvoiceStatusFilters } from '../services/InvoiceService';
import { DatePicker } from '@mui/x-date-pickers';
import { invoicesDateFilterFormat } from '../utils/dateFormat';
import { format } from 'date-fns';

export default function InvoicesListPage() {
  const { t } = useTranslation(['routes', 'invoices']);

  return (
    <>
      <PageTitle title={`${t('routes:invoices')}`} />
      <Box sx={{
        display: 'flex',
        gap: 5
      }}>
        <QueryParamsProvider>
          <InvoicesListPageFilters />
          <Card sx={{ flex: 1 }}>
            <InvoiceTable />
          </Card>
        </QueryParamsProvider>
      </Box>
    </>
  );
}

const InvoicesListPageFilters = () => {
  const { t } = useTranslation(['common', 'offers']);
  const { changeParam, removeParam } = useQueryParams();

  const [statusFilter, setStatusFilter] = useState('');
  const [expirationDaysFilter, setExpirationDaysFilter] = useState('');
  const [dateFilter, setDateFilter] = useState(null);

  return (
    <FilterSideBar>
      <SidebarSearchField name="search" changeParam={changeParam}
                          placeholder={`${t('common:search-invoice')}`} />

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="subtitle2" gutterBottom>
          {t('invoices:status-filter')}
        </Typography>
        <FormControl>
          <RadioGroup
            defaultValue={statusFilter}
            value={statusFilter}
          >
            {Object.keys(InvoiceStatusFilters).map(key => {
              return <FormControlLabel control={<Radio onClick={(e) => {
                const inputValue = (e.target as HTMLInputElement).value;

                if (inputValue !== statusFilter) {
                  // @ts-ignore
                  changeParam('status', InvoiceStatusFilters[inputValue]);
                } else {
                  removeParam('status');
                }
                setStatusFilter(inputValue === statusFilter ? '' : inputValue);
              }} />} label={t(`invoices:invoice-status.${key}`)} value={key} key={key} />;
            })}
          </RadioGroup>
        </FormControl>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="subtitle2" gutterBottom>
          {t('invoices:expiration-filter')}
        </Typography>

        <FormControl>
          <RadioGroup
            defaultValue={expirationDaysFilter}
            value={expirationDaysFilter}
          >
            {Object.keys(InvoiceExpirationFilters).map(key => {
              return <FormControlLabel control={<Radio onClick={(e) => {
                const inputValue = (e.target as HTMLInputElement).value;

                if (inputValue !== expirationDaysFilter) {
                  // @ts-ignore
                  changeParam('expirationStatus', InvoiceExpirationFilters[inputValue]);
                } else {
                  removeParam('expirationStatus');
                }
                setExpirationDaysFilter(inputValue === expirationDaysFilter ? '' : inputValue);
              }} />} label={t(`invoices:invoice-expiration.${key}`)} value={key} key={key} />;
            })}
          </RadioGroup>
        </FormControl>
      </Box>

      <Box sx={{ marginTop: '30px' }}>
        <DatePicker
          label={t('invoices:invoice-date')}
          value={dateFilter}
          onChange={debounce((date) => {
            if (!date) {
              removeParam('invoiceDate');
            }
            if (date && new Date(date).getTime() > 0) {
              setDateFilter(date);
              changeParam('invoiceDate', format(new Date(date), invoicesDateFilterFormat));
            }
          }, 300)}
          renderInput={(params) => <TextField {...params} />}
          componentsProps={{
            actionBar: {
              actions: ['clear']
            }
          }}
          onAccept={(date) => setDateFilter(date)}
        />
      </Box>
    </FilterSideBar>
  );
};
