import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '../../hooks/useKeycloak';
import SideBar from '../layout/SideBar';

export const UserSideMenu = () => {
  const { isPrimaryUser } = useKeycloak();
  const history = useHistory();

  const { t } = useTranslation('settings');

  return (
    <SideBar>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={()=>{
            history.push('/instellingen');
          }}>
            <ListItemText primary={t('profile.label')} />
          </ListItemButton>
        </ListItem>
        { isPrimaryUser() &&
          <ListItem disablePadding>
            <ListItemButton onClick={()=>{
              history.push('/instellingen/gebruikers');
            }}>
              <ListItemText primary={t('users.label')} />
            </ListItemButton>
          </ListItem>
        }
        <ListItem disablePadding>
          <ListItemButton onClick={()=>{
            history.push('/instellingen/bedrijf');
          }}>
            <ListItemText primary={t('title')} />
          </ListItemButton>
        </ListItem>
      </List>
    </SideBar>
  );
};
