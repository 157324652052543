import { useHistory } from 'react-router-dom';
import { InvoiceModel } from '../../services/InvoiceService';
import {
  Box,
  Divider,
  Grid, Tooltip,
  Typography
} from '@mui/material';
import { formatDateOnly } from '../../utils/dateFormat';
import { useEffect, useState } from 'react';
import { colorByDaysExpired } from '../invoices/InvoiceTableRow';
import { useTranslation } from 'react-i18next';

type DashboardInvoiceRowProps = {
  invoice: InvoiceModel;
};

const resetPadding = { paddingTop: '0 !important' };

export default function DashboardInvoiceRow({ invoice }: DashboardInvoiceRowProps) {
  const { t } = useTranslation(['invoices']);

  const [daysExpired, setDaysExpired] = useState<number>(0);

  const history = useHistory();

  useEffect(() => {
    const timeDiff = new Date().getTime() - new Date(invoice.expirationDate).getTime();
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24);

    setDaysExpired(Math.round(daysDiff * 10) / 10);
  }, [invoice]);

  return (
    <Tooltip
      title={daysExpired > 0 ? t('invoices:expired_days', { days: daysExpired }) : ''}
      placement="top" arrow={true} disableInteractive={true}>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          history.push('/facturen');
        }}
      >
        <Divider />
        <Grid container spacing={1} sx={{ marginY: 1 }}>
          <Grid item xs={3} sx={resetPadding}>
            {invoice.invoiceNumber && (
              <>
                <Typography>{invoice.invoiceNumber}</Typography>
              </>
            )}
          </Grid>
          <Grid item xs={4} sx={resetPadding}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <span style={{
                  width: '10px',
                  height: '10px',
                  background: colorByDaysExpired(daysExpired),
                  borderRadius: '100%'
                }}></span>
                {formatDateOnly(invoice.expirationDate)}
              </div>
            </div>
          </Grid>
          <Grid item xs={3} sx={resetPadding}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              {invoice.customerReference}
            </div>
          </Grid>
          <Grid item xs={2} sx={resetPadding}>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', gap: '6px' }}>
              {`€${invoice.remainingSaldo}`}
            </div>
          </Grid>
        </Grid>
      </Box>
    </Tooltip>
  );
}
