import { ShoppingBagOutlined } from '@mui/icons-material';
import { IconButton, Badge, Box, SxProps, Theme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useCart } from '../../hooks/useCart';
import { colors } from '../../theme';

export default function ShoppingCartDropdown() {
  const { productCount } = useCart();
  const history = useHistory();

  const goToCheckout = (): void => {
    history.push('/checkout');
  };

  const sx: SxProps<Theme> = {
    color: colors.white,
    backgroundColor: colors.blue,
    '&:hover': {
      backgroundColor: colors.darkBlue
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: '10px'
    }}>
      <IconButton onClick={goToCheckout} size="small" sx={sx}>
        <Badge badgeContent={productCount()} color="secondary">
          <ShoppingBagOutlined />
        </Badge>
      </IconButton>
    </Box>
  );
}
