import * as yup from 'yup';
import { DriverModel } from '../../../../services/CheckoutService';
import { phoneRegExp } from './contactSchema';

// @ts-ignore
const driverSchema = yup.object().shape<DriverModel>({
  // TODO: i18n translations voor yup
  name: yup.string().required('Dit veld is verplicht').min(3, 'Naam is minstens 3 letters lang'),
  phoneNumber: yup.string()
    .matches(phoneRegExp, 'Geen geldig telefoonnummer')
    .required('Een telefoonnummer is verplicht'),
  misc: yup.string()
});

export default driverSchema;
