import { useApi } from './useApi';
import { useQueryParams } from '../queryParams/useOfferFilters';
import { useQuery } from 'react-query';
import { InvoiceService } from '../../services/InvoiceService';

export function useInvoiceList() {
  const service = useApi<InvoiceService>(new InvoiceService());
  const options = {
    retry: (failureCount: number, error: any) => (failureCount <= 3 && error.statusCode),
    query: { keepPreviousData: true }
  };

  const query = useQuery({
    queryKey: ['invoice-list'],
    queryFn: async () => {
      return await service.getInvoices({
        pageSize: 3,
        invoiceStatus: 0
      });
    },
    ...options
  });

  return {
    ...query
  };
}

export function useInvoiceFilteredList() {
  const service = useApi<InvoiceService>(new InvoiceService());
  const { params } = useQueryParams();

  const options = { query: { keepPreviousData: true } };
  const query = useQuery({
    queryKey: ['invoices', params],
    queryFn: async () => {
      return await service.getInvoices({
        ...params
      });
    },
    ...options
  });

  return {
    ...query
  };
}
