import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useKeycloak } from '../hooks/useKeycloak';
import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import TextFieldShrink from '../components/layout/TextFieldShrink';
import { usePopup } from '../hooks/usePopupMessage';
import LoginLayout from '../components/layout/LoginLayout';
import { useQueryParams } from '../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

export default function LoginPage() {
  const { token, login, isLoggedIn } = useKeycloak();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loggedIn, setLoginStatus] = useState<boolean>(isLoggedIn());
  const query = useQueryParams();

  const history = useHistory();
  const { setMessage } = usePopup();

  const { t } = useTranslation(['authentication', 'common']);

  useEffect(() => {
    setLoginStatus(isLoggedIn());
    // eslint-disable-next-line
  }, [token.access_token]);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    login(username, password).catch(() => {
      setMessage({
        message: t('error.login'),
        type: 'error',
        open: true
      });
    });
  }

  // TODO: PEIN-261
  // Account requesting doesn't work
  const getRedirectLink = (): string => {
    if (
      query.get('redirect') === 'verify'
      && query.get('user') !== undefined
    ) {
      return '/instellingen/verifieren?user=' + query.get('user');
    } else {
      return '/';
    }
  };

  return loggedIn ? (<Redirect to={getRedirectLink()} />) : (
    <LoginLayout>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        sx={{
          marginX: { xs: 2, sm: 10 }
        }}
      >
        <Stack
          gap={2}
          sx={{
            marginTop: 5,
            minWidth: { xs: '300px', sm: '340px' },
            width: { xs: '100%', sm: 'unset' }
          }}
        >
          <TextFieldShrink
            fullWidth
            label={t('common:email')}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextFieldShrink
            fullWidth
            label={t('form.password')}
            type="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
          >
            {t('common:button.login')}
          </Button>
          <Button
            fullWidth
            onClick={() => {
              history.push('/wachtwoord-vergeten');
            }}>
            {t('forgot-password')}?
          </Button>
          {/* <Button
            fullWidth
            onClick={()=>{
              history.push('/aanvragen');
            }}>
            {t('request-account')}
          </Button> */}
        </Stack>
      </Box>
    </LoginLayout>
  );
}
