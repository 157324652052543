import axios from 'axios';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { userRole } from '../utils/const/roles';
import { useLocalStorage } from './useLocalStorage';

interface KeycloakTokenResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  session_state: string;
  scope: string;
  parsed?: TokenData;
}

interface TokenData {
  email: string;
  name: string;
  groups: Array<string>;
  roles: Array<string>;
  relation_id: string;
  user_id: string;
}

const initialAuth = {
  access_token: '',
  expires_in: 0,
  refresh_expires_in: 0,
  refresh_token: '',
  token_type: '',
  session_state: '',
  scope: ''
} as KeycloakTokenResponse;

interface KeycloakContextModel {
  token: KeycloakTokenResponse;
  userId?: string;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  refreshToken: () => Promise<void>;
  isLoggedIn: () => boolean;
  isAdmin: () => boolean;
  isPrimaryUser: () => boolean;
  isAccountManager: () => boolean;
  getRelationNumbers: () => string[];
  relationNumber: string;
}

const KeycloakContext = createContext<KeycloakContextModel | undefined>(undefined);

export function useKeycloak() {
  const context = useContext(KeycloakContext);
  if (context === undefined) {
    throw new Error('`useKeycloak` must be used with an `KeycloakContextProvider`');
  }
  return context;
}

export const KeycloakContextProvider = (props: PropsWithChildren<unknown>) => {
  const [token, setToken] = useLocalStorage<KeycloakTokenResponse>('auth', initialAuth);
  const [expiresIn, setExpiresIn] = useLocalStorage<number>('expires_in', 0);
  const [relationNumber, setRelationNumber] = useState<string>('');

  const userId = useMemo(() => token.parsed?.user_id, [token]);

  const config = window.env;

  useEffect(() => {
    if (token && token.access_token) {
      const interval = setInterval(() => {
        refreshToken();
      }, (token.expires_in - 5) * 1_000);
      getRelationNumbers();

      return () => clearInterval(interval);
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const login = async (username: string, password: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const params = new URLSearchParams();

      params.append('client_id', config.keycloak.clientId);
      params.append('grant_type', 'password');
      params.append('username', username);
      params.append('password', password);

      axios.post<KeycloakTokenResponse>(
        `${config.keycloak.url}/realms/${config.keycloak.realm}/protocol/openid-connect/token`,
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then((res) => {
          setState(res.data);
          resolve();
        }).catch((e) => {
        reject(e);
      });
    });
  };

  const refreshToken = async (): Promise<void> => {
    return new Promise<void>((resolve) => {
      const params = new URLSearchParams();

      params.append('client_id', config.keycloak.clientId);
      params.append('grant_type', 'refresh_token');
      params.append('refresh_token', token.refresh_token);

      axios.post<KeycloakTokenResponse>(
        `${config.keycloak.url}/realms/${config.keycloak.realm}/protocol/openid-connect/token`,
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then((res) => {
          setState(res.data);
        }).finally(() => {
          window.location.reload();
        resolve();
      });
    });
  };

  const logout = async (): Promise<void> => {
    return new Promise<void>((resolve) => {
      const params = new URLSearchParams();

      params.append('client_id', config.keycloak.clientId);
      params.append('refresh_token', token.refresh_token);

      axios.post<KeycloakTokenResponse>(
        `${config.keycloak.url}/realms/${config.keycloak.realm}/protocol/openid-connect/logout`,
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then(() => {
          setState(initialAuth);
        }).finally(() => {
        resolve();
      });
    });
  };

  const isLoggedIn = (): boolean => {
    if (token && token.access_token !== '') {
      return expiresIn > Date.now() / 1000;
    } else {
      return false;
    }
  };

  const isAdmin = (): boolean => hasRole(userRole.admin);

  const isPrimaryUser = (): boolean => hasRole(userRole.primaryUser);

  const isAccountManager = (): boolean => hasRole(userRole.accountManager);

  const hasRole = (role: string) => {
    if (token.parsed != null) {
      const roles = token.parsed?.roles;
      if (roles.includes(userRole.admin)) return true;

      return token.parsed?.roles.includes(role);
    } else {
      return false;
    }
  };

  function decodeToken(access_token: string) {
    const base64Url = access_token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const setState = (token: KeycloakTokenResponse): void => {
    setExpiresIn((Date.now() / 1000) + token.refresh_expires_in);
    if (token.access_token.length > 0) {
      token.parsed = decodeToken(token.access_token);
    }
    setToken(token);
  };

  const prefix = '/debnr/';

  const getRelationNumbers = (): string[] => {
    const numbers: string[] = [];
    if (token.parsed) {
      for (const i in token.parsed.groups) {
        if (token.parsed.groups[i].includes(prefix)) {
          numbers.push(token.parsed.groups[i].replace(prefix, ''));
        }
      }
    }

    if (!relationNumber) setRelationNumber(token.parsed?.relation_id || numbers[0] || '');

    return numbers;
  };

  const context = {
    token,
    userId,
    login,
    logout,
    refreshToken,
    isLoggedIn,
    isAdmin,
    isPrimaryUser,
    isAccountManager,
    getRelationNumbers,
    relationNumber
  };

  return <KeycloakContext.Provider value={context}>{props.children}</KeycloakContext.Provider>;
};

