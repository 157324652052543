import { Box } from '@mui/material';
import { OfferStatus } from '../../services/models/Offer';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme';

interface StatusProps {
  status: OfferStatus;
}

interface StatusText {
  color: string;
  text: string;
}

export default function Status({ status }: StatusProps) {
  const { t } = useTranslation(['orders']);
  const statusText = {
    new: {
      color: colors.grey,
      text: t('order-status.requested')
    } as StatusText,
    progress: {
      color: colors.warning,
      text: t('order-status.in-progress')
    } as StatusText,
    declined: {
      color: colors.error,
      text: t('order-status.dismissed')
    } as StatusText,
    accepted: {
      color: colors.success,
      text: t('order-status.ordered')
    } as StatusText
  };

  const getStatus = (status: OfferStatus): StatusText => {
    if (status === OfferStatus.New) {
      return statusText.new;
    } else if (status === OfferStatus.Terminated) {
      return statusText.declined;
    } else if (
      status === OfferStatus.Ready ||
      status === OfferStatus.Approved ||
      status === OfferStatus.DefinitivelyPrinted ||
      status === OfferStatus.Confirmed ||
      status === OfferStatus.Assignment
    ) {
      return statusText.accepted;
    } else {
      return statusText.progress;
    }
  };

  const currStatus: StatusText = getStatus(status);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box
        sx={{
          bgcolor: currStatus.color,
          height: 10,
          width: 10,
          borderRadius: 5,
          marginRight: 0.5,
          alignSelf: 'center'
        }}
      ></Box>
      <Box>{currStatus.text}</Box>
    </Box>
  );
}
