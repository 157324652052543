import { Box, TableCell } from '@mui/material';
import { SiteModel } from '../../services/SiteService';

interface TableAddressCellProps {
  site?: SiteModel;
}

export default function TableAddressCell({ site }: TableAddressCellProps) {
  return <TableCell>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      {site && (
        <Box
          key={site.id}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {site.street}{' '}
            {site.houseNumber}
            {site.houseNumberAddition}
          </Box>
          <Box component="span">
            {site.postalCode}{' '}
            {site.city}
          </Box>
        </Box>
      )}
      {!site && '-'}
    </Box>
  </TableCell>;
}
