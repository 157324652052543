import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent, Checkbox,
  FormControlLabel, Grid, MenuItem, Radio,
  RadioGroup, Select, TextField,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../../components/layout/navigation/Breadcrumb';
import { initContact, initDriver, initSite } from '../../hooks/useCart';
import OutlinedBox from '../../components/layout/OutlinedBox';
import { useJobSurvey } from '../../hooks/useJobSurvey';
import { useSitePickupList } from '../../hooks/api/useSites';
import {
  initAddressErrors,
  initContactErrors,
  initDriverErrors
} from '../../components/cart/checkout/hooks/useLocationCheckoutForm';
import {
  useJobSurveyLocationCheckoutForm
} from '../../components/jobSurveys/useJobSurveyLocationCheckoutForm';
import { colors } from '../../theme';
import SearchAddressDropdown from '../../components/common/SearchAddressDropdown';
import TextFieldShrink from '../../components/layout/TextFieldShrink';
import Map from '../../components/layout/Map';
import { SiteModel, SiteService } from '../../services/SiteService';
import { useApi } from '../../hooks/api/useApi';
import { useDebounce } from '../../utils/debounce';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { CheckoutStepProps } from '../../components/cart/checkout';

export default function ComplementJobSurveyEditAddress({ onNext, onPrevious }: CheckoutStepProps) {
  const {
    mapData,
    updateMapDebounce,

    handleNext,

    contactErrors,
    setAddressErrors,
    addressErrors,
    setSecondContactErrors,
    secondContactErrors,
    setDriverErrors,
    driverErrors,

    setHasSecondContact,
    hasSecondContact,
    setHasDriver,
    hasDriver
  }
    = useJobSurveyLocationCheckoutForm({ onNext, onPrevious });

  const { t } = useTranslation(['orders']);
  const { id } = useParams<RouteParams>();
  const {
    jobSurvey,
    setJobSurveyDriver,
    setJobSurveySite,
    setJobSurveySiteFromPickup,
    setJobSurveySiteDescription,
    setJobSurveyContact,
    setJobSurveyContact2
  } = useJobSurvey();
  const { data: pickupData, isLoading: pickupsLoading } = useSitePickupList();
  const { getAddressByPostalCode } = useApi<SiteService>(new SiteService());

  const [addWorkAddress, setAddWorkAddress] = useState(false);

  const addressByPostalCode = (postalCode: string, houseNumber: string) => {
    return getAddressByPostalCode(postalCode, houseNumber);
  };

  const setSite = (site: SiteModel, siteFromApi = false, siteFromPickup = false): void => {
    setJobSurveySite(site, siteFromApi, siteFromPickup);
  };

  const onChangeDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const siteFromPickup = event.target.value === 'true';
    setJobSurveySiteFromPickup(siteFromPickup);

    if (!siteFromPickup) setSite(initSite);
  };

  const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    searchByPostalCode(e.target.value, jobSurvey.site.houseNumber);
  };

  const handleHouseNumberChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    searchByPostalCode(jobSurvey.site.postalCode, e.target.value);
  };

  const searchByPostalCode = useDebounce((postalCode: string, houseNumber: string) => {
    if (postalCode.length >= 7 && houseNumber) {
      addressByPostalCode(postalCode.replaceAll(' ', ''), houseNumber).then(response => {
        updateMapDebounce({
          mapAddress: `${response.result.addressLine} ${response.result.houseNumber}`,
          mapCity: response.result.city,
          mapPostalCode: response.result.postalCode
        });

        setSite(
          {
            ...jobSurvey.site,
            postalCode: postalCode,
            city: response.result.city,
            street: response.result.addressLine,
            houseNumber: response.result.houseNumber
          },
          false
        );
      });
    }
  }, 600);

  return (
    <>
      <Card sx={{ flex: 1 }}>
        <CardContent>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">{t('orders:job-survey')} {id}</Typography>
          </Box>

          {/* Werkadres */}
          <OutlinedBox>
            <Typography variant="subtitle2">{t('common:work-address')}</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <RadioGroup
                row
                value={jobSurvey.siteFromPickup}
                onChange={onChangeDelivery}
              >
                <FormControlLabel
                  value={'false'}
                  control={<Radio />}
                  label={t('checkout:location.delivery')}
                />
                <FormControlLabel
                  value={'true'}
                  control={<Radio />}
                  label={t(`checkout:location.pick-up${jobSurvey.siteFromPickup ? '-at' : ''}`)}
                  disabled={pickupsLoading}
                />
                {jobSurvey.siteFromPickup && (
                  <>
                    <Select
                      value={jobSurvey.site.city}
                      style={{ height: '56px', lineHeight: '1.5em', width: '250px' }}
                      onChange={(e) => {
                        const site = pickupData?.result.find((x) => x.city === e.target.value);
                        setSite({
                            ...jobSurvey.site,
                            ...site ?? initSite
                          },
                          jobSurvey.siteFromApi,
                          true
                        );
                      }}
                    >
                      {!pickupsLoading && pickupData?.result.map((site) => (
                        <MenuItem key={site.id} value={site.city}>
                          {site.city}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </RadioGroup>
            </Box>
          </OutlinedBox>

          {/* Zoek naar bestaand werkadres */}
          <OutlinedBox>
            <Typography variant="subtitle2">{t('checkout:location.fill-label')}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  {jobSurvey.siteFromPickup && addressErrors.street.error && (
                    <Grid item xs={12} color={colors.error}>
                      {t('validation:choose-a-pickup-site', { option: `${t('checkout:location.delivery')}` })}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SearchAddressDropdown value={jobSurvey.site || initSite}
                                           disabled={jobSurvey.siteFromPickup}
                                           onAddressSelect={(e, site, reason) => {
                                             updateMapDebounce({
                                               mapAddress: site ? `${site?.street} ${site?.houseNumber}` : '',
                                               mapCity: site ? site?.city ?? '' : '',
                                               mapPostalCode: site ? site?.postalCode ?? '' : ''
                                             });

                                             if (reason === 'clear') {
                                               setSite(site || initSite, false);
                                             } else {
                                               setAddressErrors(initAddressErrors);
                                               setSite(site || initSite, true);
                                             }
                                           }} />
                    <div style={{ marginTop: '12px' }} />
                    <FormControlLabel control={<Checkbox
                      onChange={(event) => setAddWorkAddress(event.target.checked)}
                      checked={addWorkAddress} />} label={t('orders:add-work-address')} />
                  </Grid>
                  {addWorkAddress && <>
                    <Grid item xs={12} md={6}>
                      <TextFieldShrink
                        fullWidth
                        required
                        label={t('common:postal-code')}
                        value={jobSurvey.site?.postalCode}
                        error={!jobSurvey.siteFromPickup && addressErrors.postalCode.error}
                        helperText={addressErrors.postalCode.message}
                        onChange={(e) => {
                          setSite(
                            {
                              ...jobSurvey.site,
                              postalCode: e.target.value
                            },
                            false
                          );
                          handlePostalCodeChange(e);
                        }}
                        disabled={jobSurvey.siteFromPickup || jobSurvey.siteFromApi}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextFieldShrink
                        fullWidth
                        required
                        label={t('common:labels.housenumber')}
                        value={jobSurvey.site?.houseNumber}
                        error={!jobSurvey.siteFromPickup && addressErrors.houseNumber.error}
                        helperText={addressErrors.houseNumber.message}
                        onChange={(e) => {
                          setSite(
                            {
                              ...jobSurvey.site,
                              name: jobSurvey.site.street + ' ' + e.target.value + ' ' + jobSurvey.site.city,
                              houseNumber: e.target.value
                            },
                            false
                          );
                          handleHouseNumberChange(e);
                        }}
                        disabled={jobSurvey.siteFromPickup || jobSurvey.siteFromApi}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextFieldShrink
                        fullWidth
                        label={t('common:labels.addition') ?? ''}
                        name="site.houseNumberAddition"
                        value={jobSurvey.site?.houseNumberAddition}
                        onChange={(e) => {
                          setSite(
                            {
                              ...jobSurvey.site,
                              name: jobSurvey.site.street + ' ' + jobSurvey.site.houseNumber + '' + e.target.value + ' ' + jobSurvey.site.city,
                              houseNumberAddition: e.target.value
                            },
                            false
                          );
                        }}
                        disabled={jobSurvey.siteFromPickup || jobSurvey.siteFromApi}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldShrink
                        fullWidth
                        required
                        label={t('common:street')}
                        value={jobSurvey.site?.street}
                        error={!jobSurvey.siteFromPickup && addressErrors.street.error}
                        helperText={addressErrors.street.message}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldShrink
                        fullWidth
                        required
                        label={t('common:labels.city')}
                        helperText={addressErrors.city.message}
                        error={!jobSurvey.siteFromPickup && addressErrors.city.error}
                        value={jobSurvey.site.city}
                        disabled={true}
                      />
                    </Grid>
                  </>
                  }
                  <Grid item xs={false} md={6}></Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t('common:particularities')}
                      multiline
                      fullWidth
                      value={jobSurvey.siteDescription || ''}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => setJobSurveySiteDescription(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Map
                  address={mapData.mapAddress || ''}
                  postalCode={mapData.mapPostalCode || ''}
                  city={mapData.mapCity || ''}
                />
              </Grid>
            </Grid>
          </OutlinedBox>

          {/* Contactpersoon op locatie */}
          <OutlinedBox>
            <Typography variant="subtitle2">{t('common:contact-person.on-site')}</Typography>
            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
              <Grid item xs={6}>
                <TextFieldShrink
                  value={jobSurvey.contact.name}
                  helperText={contactErrors.name.message}
                  error={contactErrors.name.error}
                  fullWidth
                  onChange={(e) => {
                    setJobSurveyContact({
                      ...jobSurvey.contact,
                      name: e.target.value
                    });
                  }}
                  required
                  label={t('common:name')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldShrink
                  fullWidth
                  onChange={(e) => {
                    setJobSurveyContact({
                      ...jobSurvey.contact,
                      phoneNumber: e.target.value
                    });
                  }}
                  required
                  helperText={contactErrors.phoneNumber.message}
                  error={contactErrors.phoneNumber.error}
                  value={jobSurvey.contact.phoneNumber}
                  label={t('common:phone')}
                />
              </Grid>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasSecondContact}
                      onChange={(e) => {
                        setHasSecondContact(e.target.checked);
                        if (!e.target.checked) {
                          setJobSurveyContact2({ ...initContact });
                          setSecondContactErrors(initContactErrors);
                        }
                      }}
                    />
                  }
                  label={t('common:contact-person.add-alternative')}
                />
              </Grid>
              {hasSecondContact && (
                <Grid item md={12}>
                  <Typography variant="subtitle2">
                    {t('common:contact-person.alternative')}
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginBottom: 3
                    }}
                  >
                    <Grid item xs={6}>
                      <TextFieldShrink
                        required
                        value={jobSurvey.contact2?.name || ''}
                        helperText={secondContactErrors.name.message}
                        error={secondContactErrors.name.error}
                        fullWidth
                        onChange={(e) => {
                          setJobSurveyContact2({
                            ...jobSurvey.contact2,
                            name: e.target.value
                          });
                        }}
                        label={t('common:name')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldShrink
                        fullWidth
                        required
                        onChange={(e) => {
                          setJobSurveyContact2({
                            ...jobSurvey.contact2,
                            phoneNumber: e.target.value
                          });
                        }}
                        helperText={secondContactErrors.phoneNumber.message}
                        error={secondContactErrors.phoneNumber.error}
                        value={jobSurvey.contact2?.phoneNumber || ''}
                        label={t('common:phone')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </OutlinedBox>

          {/* Chauffeur aanmelden */}
          <FormControlLabel
            control={
              <Checkbox
                checked={hasDriver}
                onChange={(e) => {
                  setHasDriver(e.target.checked);
                  if (!e.target.checked) {
                    setJobSurveyDriver({ ...initDriver });
                    setDriverErrors(initDriverErrors);
                  }
                }}
              />
            }
            label={`${t('common:driver.label')}`}
          />
          {hasDriver && (
            <OutlinedBox>
              <Typography variant="subtitle2">{t('common:driver.label')}</Typography>
              <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={6}>
                  <TextFieldShrink
                    fullWidth
                    required
                    onChange={(e) => {
                      setJobSurveyDriver({
                        ...jobSurvey.driver,
                        name: e.target.value
                      });
                    }}
                    helperText={driverErrors.name.message}
                    error={driverErrors.name.error}
                    value={jobSurvey.driver.name}
                    label={t('common:name')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldShrink
                    fullWidth
                    required
                    onChange={(e) => {
                      setJobSurveyDriver({
                        ...jobSurvey.driver,
                        phoneNumber: e.target.value
                      });
                    }}
                    helperText={driverErrors.phoneNumber.message}
                    error={driverErrors.phoneNumber.error}
                    value={jobSurvey.driver.phoneNumber}
                    label={t('common:phone')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => {
                      setJobSurveyDriver({
                        ...jobSurvey.driver,
                        misc: e.target.value
                      });
                    }}
                    value={jobSurvey.driver.misc}
                    label={t('checkout:verification.other-remarks')}
                  />
                </Grid>
              </Grid>
            </OutlinedBox>
          )}
        </CardContent>
      </Card>

      <Box display={'flex'} justifyContent={'space-between'}>
        <Button
          type="submit"
          variant="contained"
          onClick={() => onPrevious ? onPrevious() : null}
        >
          <ArrowBack sx={{ marginRight: 1 }} /> {t('common:button.previous')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={() => handleNext()}
        >
          {t('common:button.next')} <ArrowForward sx={{ marginLeft: 1 }} />
        </Button>
      </Box>
    </>
  );
}
