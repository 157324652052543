import { Grid } from '@mui/material';
import Map from '../../layout/Map';
import { SiteModel } from '../../../services/SiteService';

interface OfferDetailTopMapProps {
  site?: SiteModel;
}

export default function OfferDetailTopMap({ site }: OfferDetailTopMapProps) {
  return (
    <>
      {site && (
        <Grid item xs={12} md={6}>
          <Map
            address={`${site.street} ${site.houseNumber}` || ''}
            postalCode={site.postalCode || ''}
            city={site.city || ''}
          />
        </Grid>
      )}
    </>
  );
}
