import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import HelpAlert from '../components/common/HelpAlert';
import Breadcrumb from '../components/layout/navigation/Breadcrumb';
import ProductPagination from '../components/products/ProductPagination';
import { QueryParamsProvider } from '../hooks/queryParams/useProductFilters';
import { useCart } from '../hooks/useCart';
import { planningContactNumber } from '../utils/const/contact';
import { usePopup } from '../hooks/usePopupMessage';

export default function ProductsListPage() {
  const { t } = useTranslation(['products', 'routes', 'common', 'checkout']);
  const { productCount, addToCart } = useCart();
  const { setMessage } = usePopup();
  const history = useHistory();

  return (
    <>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginY: 2
      }}>
        <Box>
          <Typography variant="h1">{t('routes:new-order')}</Typography>
          <Breadcrumb />
        </Box>
        <Button
          sx={{ marginY: 1 }}
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={() => history.push('/checkout')}
          disabled={productCount() <= 0}
        >
          {t('common:button.to-cart')} ({productCount()})
        </Button>
      </Box>
      <HelpAlert
        sx={{ marginBottom: 3, marginTop: 1 }}
        translation={{ key: 'order', values: { number: planningContactNumber || t('common:alerts.number-dashboard') } }} />
      <Box sx={{
        display: 'flex',
        gap: 5
      }}>
        <QueryParamsProvider>
          <ProductPagination pageSize={25} onAddProduct={(product, isAccessoire) => {
            setMessage({
              message: `${t('checkout:machines.added-to-cart', { product: product.productGroup.description })}`,
              type: 'success',
              open: true
            });
            addToCart(product, isAccessoire)
          }} />
        </QueryParamsProvider>
      </Box>
    </>
  );
}

