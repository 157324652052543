import { Api, ApiResponse, PagedData } from './Api';

export interface SiteModel {
  id?: string;
  siteNumber?: string;
  searchName?: string;
  name?: string;
  street: string;
  postalCode: string;
  city: string;
  houseNumber: string;
  houseNumberAddition?: string;
  telephoneNumber?: string;
}

export interface AddressModel {
  addressLine: string;
  street: string;
  houseNumber: string;
  houseNumberAddition?: string;
  postalCode?: string;
  city: string;
}

export interface SiteFilters {
  site?: string;
  isActive?: boolean;
  search?: string;
  siteNumber?: string;
}

export class SiteService extends Api {
  getSites = async (params?: SiteFilters) => {
    return this.get<ApiResponse<PagedData<SiteModel>>>(
      '/api/sites', {},
      params
    );
  };

  getSitePickupLocations = async () => {
    return this.get<ApiResponse<SiteModel[]>>('/api/sites/pickup');
  };

  getSiteBySiteNumber = async (id: string) => {
    return this.get<ApiResponse<SiteModel>>(
      `/api/sites/${id}`
    );
  };

  getAddressByPostalCode = async (postalCode: string, houseNumber: string) => {
    return this.get<ApiResponse<AddressModel>>('/api/address', {}, {
      postalCode,
      houseNumber
    });
  };

  createSite = async (site: SiteModel) => {
    return this.post<ApiResponse<SiteModel>>(
      '/api/sites',
      site
    );
  };
}
