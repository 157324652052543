import { PropsWithChildren, ReactNode } from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from '../../utils/currencyFormat';
import { getDailyPrice, getTotalPrice, getWeeklyPrice } from '../../utils/priceHelper';
import { ProductModel } from '../../services/ProductService';
import BlueBox from '../layout/BlueBox';
import { measurement } from '../../utils/const/measurement';
import { OrderModel } from '../../services/models/Offer';

interface RowProps {
  product: ProductModel,
  order?: OrderModel,
  startDate: string,
  endDate?: string,
  buttons?: ReactNode,
  hideProperties?: boolean
}

export default function CartItemDetailRow(
  {
    product,
    order,
    startDate,
    endDate,
    hideProperties = true,
    buttons,
    children
  }: PropsWithChildren<RowProps>) {
  const { t } = useTranslation(['common', 'products', 'checkout']);

  return (
    <BlueBox>
      {product && (
        <Grid container>
          <Grid item container xs={4} md={4}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10} sx={{ fontWeight: 'bold' }}>
                  <Tooltip title={product?.productGroup.description || ''}>
                    <Typography sx={{ fontWeight: 'bold' }}>{product?.description}</Typography>
                  </Tooltip>
                </Grid>
                {!hideProperties && (
                  <Grid container item xs={12} sx={{ paddingRight: '15px' }}>
                    {!!product.liftPowerNumber && (
                      <>
                        <Grid item xs={6}>{t('products:lift-power')}</Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: 'flex',
                            paddingRight: '10px'
                          }}
                        >
                          {product.liftPowerNumber}{measurement.weight}
                        </Grid>
                      </>
                    )}
                    {!!product.weightNumber && (
                      <>
                        <Grid item xs={6}>{t('products:weight')}</Grid>
                        <Grid item xs={6} sx={{
                          display: 'flex',
                          paddingRight: '10px'
                        }}
                        >
                          {product.weightNumber}{measurement.weight}
                        </Grid>
                      </>
                    )}
                    {!!product.workHeightNumber && (
                      <>
                        <Grid item xs={6}>{t('products:work-height')}</Grid>
                        <Grid item xs={6} sx={{
                          display: 'flex',
                          paddingRight: '10px'
                        }}
                        >
                          {product.workHeightNumber}{measurement.length}
                        </Grid>
                      </>
                    )}
                    {!!product.reachLengthNumber && (
                      <>
                        <Grid item xs={6}>{t('products:reach')}</Grid>
                        <Grid item xs={6} sx={{
                          display: 'flex',
                          paddingRight: '10px'
                        }}
                        >
                          {product.reachLengthNumber}{measurement.length}
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} md={3}>
            <Typography sx={{ fontWeight: 'bold' }}>{t('labels.start-date')}</Typography>
            {startDate}
            <Typography sx={{ fontWeight: 'bold' }}>{t('labels.end-date')}</Typography>
            {endDate || t('until-cancellation')}
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography
              sx={{ fontWeight: 'bold' }}>{t('checkout:verification.day-rate')}</Typography>
            {currencyFormat(getDailyPrice(product))}
            <Typography
              sx={{ fontWeight: 'bold' }}>{t('checkout:verification.week-rate')}</Typography>
            {currencyFormat(getWeeklyPrice(product))}
            {order && order.amount && <>
              <Typography
                sx={{ fontWeight: 'bold' }}>{t('checkout:verification.project-price')}</Typography>
              {currencyFormat(getTotalPrice(order))}
            </>
            }
          </Grid>
          <Grid item xs={12} md={3}>
            {buttons}
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      )}
    </BlueBox>
  );
}
