import { useInfiniteQuery, useQuery, UseInfiniteQueryOptions } from 'react-query';
import { ProductFilterParams, ProductModel, ProductService } from '../../services/ProductService';
import { useApi } from './useApi';

const accessoriesItems = [
  'Rijplaten',
  'Rijplaten (8)',
  'Rijplaten (10)',
  'Rijplaten (12)',
  'Rijplaten (18)',
  'RST',
  'HMXLL20P',
  'BST995'
];

interface QueryFetchOptions {
  query: UseInfiniteQueryOptions;
}

export function useProductFilteredList(params: ProductFilterParams) {
  const service = useApi<ProductService>(new ProductService());
  const queryKey = ['products', params];

  const options: QueryFetchOptions = {
    query: {
      keepPreviousData: true,
      refetchOnMount: 'always'
    }
  };

  const query = useInfiniteQuery(
    queryKey,
    ({ pageParam = undefined }) => service.getProducts({ nextToken: pageParam, ...params }), {
      getNextPageParam: (lastPage) => {
        if (lastPage.result.nextPage !== '') {
          return lastPage.result.nextPage;
        } else {
          return undefined;
        }
      },
      ...options
    });

  return {
    queryKey,
    ...query
  };
}

export function useAccessories() {
  const service = useApi<ProductService>(new ProductService());
  const queryKey = 'accessories';

  const options: QueryFetchOptions = {
    query: {
      keepPreviousData: true
    }
  };

  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {

      const items: ProductModel[] = [];
      for (const accessoireId of accessoriesItems) {
        const product = await getProduct(service, accessoireId);
        if (product) {
          items.push(product);
        }
      }

      const unique = items.filter(
        (item, i, arr) => arr.findIndex((t) => t.id === item.id) === i
      );
      return { data: unique };
    },
    ...options
  });

  return {
    queryKey,
    ...query
  };
}

async function getProduct(service: ProductService, productNumber: string) : Promise<ProductModel | null> {
  try {
    const product = await service.getProductByProductNumber(productNumber);
    return product.result;
  }
  catch (e) {
    return null;
  }
}
