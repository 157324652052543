import { useEffect } from 'react';
import { Api } from '../../services/Api';
import { useKeycloak } from '../useKeycloak';

export function useApi<T extends Api>(service: T): T{
  const { token, refreshToken } = useKeycloak();
  service.setToken(token.access_token);
  service.refreshFunc = refreshToken;

  useEffect(() => {
    if (token.access_token !== undefined && service.token !== token.access_token){
      service.setToken(token.access_token);
    }
    // eslint-disable-next-line
  }, [token.access_token]);

  return service;
}
