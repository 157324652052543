import { Box, Grid, Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkRouter, RouteParams } from '../../components/layout/navigation/Breadcrumb';

export default function ComplementJobSurveyTitle() {
  const { t } = useTranslation(['routes']);
  const { id } = useParams<RouteParams>();

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 0 100%',
        flexDirection: 'row',
        marginTop: 2,
        marginBottom: 2
      }}
    >
      <Grid container>
        <Grid item>
          <Typography variant="h1">{t('routes:complement-job-survey')}</Typography>
          <Breadcrumbs sx={{
            fontSize: 12
          }} separator=">" aria-label="breadcrumb">
            <LinkRouter underline="hover" color="inherit" to="/">
              {t('routes:dashboard')}
            </LinkRouter>
            <LinkRouter underline="hover" color="inherit" to="/werkopnames">
              {t('routes:job-surveys')}
            </LinkRouter>
            <LinkRouter underline="hover" color="inherit" to={`/werkopnames/${id}`}>
              {t('routes:job-survey-details')}
            </LinkRouter>
            <Typography sx={{ fontSize: 12 }}>
              {t('routes:complement-job-survey')}
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
    </Box>
  );
}
