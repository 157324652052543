import { useMutation, useQueryClient } from 'react-query';
import { CheckoutService } from '../../services/CheckoutService';
import { SaveCartModel, ConfirmCartModel } from '../models/cart';
import { useApi } from './useApi';

export function useSaveCart() {
  const service = useApi<CheckoutService>(new CheckoutService());
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries('checkout');
    }
  };

  return useMutation((data: SaveCartModel) => service.saveCheckout(data), options);
}

export function useConfirmCart() {
  const service = useApi<CheckoutService>(new CheckoutService());
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries('checkout');
    }
  };

  return useMutation((data: ConfirmCartModel) => service.confirmCheckout(data), options);
}

export function useSaveJobSurvey(id: string) {
  const service = useApi<CheckoutService>(new CheckoutService());
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries('checkout-survey');
    }
  };

  return useMutation((data: SaveCartModel) => service.saveJobSurvey(data, id), options);
}
