import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopup } from './usePopupMessage';
import { useApi } from './api/useApi';
import { UserService } from '../services/UserService';
import { UserProfileModel } from '../services/models/UserModel';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useKeycloak } from './useKeycloak';

interface ProfileContextModel {
  userProfileForm: any;
  profile?: UserProfileModel;
  fetchUserProfile: (userId: string) => void;
  loading: boolean;
}

const ProfileContext = createContext<ProfileContextModel | undefined>(undefined);

export function useProfile() {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('`useProfile` must be used with an `ProfileContextProvider`');
  }
  return context;
}

export const ProfileContextProvider = (props: PropsWithChildren<unknown>) => {
  const { t } = useTranslation(['common', 'settings', 'validation']);
  const { setMessage } = usePopup();
  const { userId, isLoggedIn } = useKeycloak();
  const service = useApi<UserService>(new UserService());
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<UserProfileModel>();

  useEffect(() => {
    if (isLoggedIn() && userId) {
      fetchUserProfile(userId);
    }
  }, [userId]);

  const defaultUserProfile = {
    userId: '',
    username: '',
    isEmailVerified: false,
    firstName: '',
    lastName: '',
    emailAddress: ''
  };

  const fetchUserProfile = async (userId: string) => {
    setLoading(true);
    await service
      .getUserProfile(userId)
      .then((res) => setProfile(res.result))
      .finally(() => setLoading(false));
  };

  const updateProfile = async (profile: UserProfileModel): Promise<void> => {
    setLoading(true);
    await service
      .updateUserProfile(profile)
      .then((res) => setProfile(res.result))
      .finally(() => setLoading(false));
  };

  const profileSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, `${t('validation:minimum', { name: t('common:username') })}`)
      .max(50, `${t('validation:maximum', { name: t('common:username') })}`)
      .required(`${t('validation:required', { name: t('common:username') })}`),
    firstName: Yup.string()
      .min(2, `${t('validation:minimum', { name: t('common:first-name') })}`)
      .max(50, `${t('validation:maximum', { name: t('common:first-name') })}`)
      .required(`${t('validation:required', { name: t('common:first-name') })}`),
    lastName: Yup.string()
      .min(2, `${t('validation:minimum', { name: t('common:last-name') })}`)
      .max(50, `${t('validation:maximum', { name: t('common:last-name') })}`)
      .required(`${t('validation:required', { name: t('common:last-name') })}`),
    emailAddress: Yup.string()
      .email(`${t('validation:invalid-email')}`)
      .required(`${t('validation:required', { name: t('common:email') })}`)
  });

  const userProfileForm = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultUserProfile, ...profile, username: profile?.emailAddress },
    validationSchema: profileSchema,
    onSubmit: (values, { setSubmitting }) => {
      updateProfile(values).then((resolve) => {
        setMessage({
          message: t('settings:profile.messages.success'),
          type: 'success',
          open: true
        });
      }, (reject) => {
        setMessage({
          message: t('settings:profile.messages.error'),
          type: 'error',
          open: true
        });
      }).finally(() => setSubmitting(false));
    }
  });

  const context = {
    userProfileForm,
    profile,
    fetchUserProfile,
    loading
  };

  return <ProfileContext.Provider value={context}>{props.children}</ProfileContext.Provider>;
};
