import { Box, CssBaseline } from '@mui/material';
import { Switch, Route } from 'react-router-dom';
//page imports
import LoginPage from '../pages/LoginPage';
import DashboardPage from '../pages/DashboardPage';
import routeConfig from './RouteConfig';
import PrivateRoute from '../components/layout/PrivateRoute';
import NavBar from '../components/layout/navigation/NavBar';
import { CartContextProvider } from '../hooks/useCart';
import PopupMessages from '../components/layout/feedback/PopupMessages';
import RegisterPage from '../pages/RegisterPage';
import PasswordForgottenPage from '../pages/PasswordForgottenPage';
import { colors, spacing } from '../theme';
import { JobSurveysProviderComponent } from '../hooks/useJobSurvey';
import { ProfileContextProvider } from '../hooks/useProfile';
import { PopupContextProvider } from '../hooks/usePopupMessage';

export const AppRoutes = () => {
  return (
    <>
      <PopupContextProvider>
        <ProfileContextProvider>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/aanvragen" component={RegisterPage} />
            <Route path="/wachtwoord-vergeten" component={PasswordForgottenPage} />
            <Route
              path={'/(.*)'}
              render={() => (
                <Box sx={{ display: 'flex' }}>
                  <CssBaseline />
                  <CartContextProvider>
                    <JobSurveysProviderComponent>
                      <NavBar />
                      <Box sx={{
                        bgcolor: colors.background,
                        flexGrow: 1,
                        paddingX: 3,
                        paddingTop: 2,
                        minHeight: `calc(100vh - ${spacing.navbar.height})`,
                        marginTop: spacing.navbar.height
                      }}>
                        <Box component="main" sx={{
                          maxWidth: spacing.page.maxWidth,
                          margin: '0 auto'
                        }}>
                          <Switch>
                            <PrivateRoute exact path="/" component={DashboardPage} />
                            {Object.entries(routeConfig).map(
                              ([to, route]) => {
                                return (
                                  <PrivateRoute path={to} key={to} component={route.component} />
                                );
                              }
                            )}
                          </Switch>
                        </Box>
                      </Box>
                    </JobSurveysProviderComponent>
                  </CartContextProvider>
                </Box>
              )}
            />
          </Switch>
          <PopupMessages />
        </ProfileContextProvider>
      </PopupContextProvider>
    </>
  );
};
