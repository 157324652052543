import { ContactModel } from "../../hooks/models/cart";
import { DriverModel } from "../CheckoutService";
import { ProductModel } from "../ProductService";
import { SiteModel } from "../SiteService";

export enum OrderStatus {
  Unknown = "Unknown",
  New = "New",
  Entered = "Entered",
  Denied = "Denied",
  Completed = "Completed",
  CheckList = "CheckList",
  Approved = "Approved",
  ApprovedAndConfirmed = "ApprovedAndConfirmed",
  Modified = "Modified",
  ReservedAndIncomplete = "ReservedAndIncomplete",
  ReservedAndComplete = "ReservedAndComplete",
  DeskOrder = "DeskOrder",
  Terminated = "Terminated"
}

export interface OrderModel {
  id?: string;
  orderNumber?: number;
  orderDate: string;
  status: OrderStatus;
  productNumber?: string;
  product: ProductModel;
  configurationNumber?: string;
  bookOutDate?: string;
  startDate: string;
  endDate: string;
  debtorNumber?: string;
  amount: number;
  customerReference?: string;
  contactPerson?: ContactModel;
  additionalContactPerson?: ContactModel;
  transportCostTo?: number;
  transportCostFrom?: number;
}

export interface ClusterModel {
  entries: OfferModel[];

  // Exclusive to JobSurvey detail
  expectedStartDate?: string;
  expectedEndDate?: string;
  contactPerson?: ContactModel;
  additionalContactPerson?: ContactModel;
  driver?: DriverModel;
  isTransportedByUs?: boolean;
  isEarlyDelivery?: boolean;
}

export interface OfferModel {
  id?: string;
  siteNumber?: string;
  site?: SiteModel;
  offerNumber?: string;
  description?: string;
  relationNumber?: string;
  productNumber?: string;
  quantity: number;
  offerDate: string;
  startDate?: string;
  endDate?: string;
  status: OfferStatus;
  usageType: UsageType;
  offerType?: OfferType;
  typeOfWork?: TypeOfWork;
  offerAmount?: number;
  discountAmount?: number;
  clusterIdentifier?: string;
  customerReference?: string;
  siteDescription?: string;
  typeOfWorkDescription?: string;
  comments?: string;
  contactPerson?: ContactModel;
  additionalContactPerson?: ContactModel;
  driver?: DriverModel;
  transportCostTo?: number;
  transportCostFrom?: number;
  cancelReason?: string;
}

export enum UsageType {
  Unknown, Outside, Inside, RoughTerrain
}

export enum TypeOfWork {
  Onbekend = 'Onbekend',
  Montage = 'Montage',
  Inspectie = 'Inspectie',
  Snoeiwerkzaamheden = 'Snoeiwerkzaamheden',
  Schilderwerkzaamheden = 'Schilderwerkzaamheden',
  Asbestsanering = 'Asbestsanering',
  Overig = 'Overig',
}

export enum OfferType {
  Offer = '01',
  JobSurvey = '02',
  Regular = 'Regular',
  Intake = 'Intake'
}

export enum OrderStatusFilters {
  ordered = 'Ready,Approved,DefinitivelyPrinted,Confirmed,Assignment',
  requested = 'New',
  dismissed = 'Terminated'
}

export enum OfferStatus {
  Unknown = 'Unknown',
  New = 'New',
  Modified = 'Modified',
  Ready = 'Ready',
  Approved = 'Approved',
  DefinitivelyPrinted = 'DefinitivelyPrinted',
  Confirmed = 'Confirmed',
  Assignment = 'Assignment',
  Terminated = 'Terminated',
}
