import { SetStateAction, useEffect, useState } from 'react';
import { useGetOrganizationByRelationId } from '../../../../hooks/api/useOrganizations';
import { useSiteList } from '../../../../hooks/api/useSites';
import { useCart } from '../../../../hooks/useCart';
import { useKeycloak } from '../../../../hooks/useKeycloak';
import { useDebounce } from '../../../../utils/debounce';
import { formatSite } from '../../../../utils/siteFormatter';
import contactSchema from '../schema/contactSchema';
import siteSchema from '../schema/siteSchema';
import driverSchema from '../schema/driverSchema';
import { DriverModel } from '../../../../services/CheckoutService';
import { ContactModel } from '../../../../hooks/models/cart';

interface ErrorType {
  error: boolean;
  message: string;
}

export const emptyError: ErrorType = { error: false, message: '' };

export interface MapData {
  mapAddress?: string,
  mapPostalCode?: string,
  mapCity?: string,
}

export const initAddressErrors = {
  city: emptyError,
  street: emptyError,
  houseNumber: emptyError,
  postalCode: emptyError
};

export const initContactErrors : Record<keyof ContactModel, ErrorType> = {
  name: emptyError,
  phoneNumber: emptyError
};

export const initDriverErrors : Record<keyof DriverModel, ErrorType> = {
  name: emptyError,
  phoneNumber: emptyError,
  misc: emptyError
};

interface FormProps {
  onNext: (() => void) | undefined,
  onPrevious: (() => void) | undefined
}

type SchemaName = 'contact' | 'site' | 'contact2' | 'driver';

export function useLocationCheckoutForm({ onNext }: FormProps) {
  const { cart, setCart, setContact, setContact2 } = useCart();
  const { relationNumber } = useKeycloak();
  const { data: loadedOrg } = useGetOrganizationByRelationId(relationNumber);

  const [addressErrors, setAddressErrors] = useState(initAddressErrors);
  const [contactErrors, setContactErrors] = useState(initContactErrors);
  const [secondContactErrors, setSecondContactErrors] = useState(initContactErrors);
  const [driverErrors, setDriverErrors] = useState(initDriverErrors);

  const [hasDriver, setHasDriver] = useState(!!cart.driver.name);
  const [hasSecondContact, setHasSecondContact] = useState(!!cart.contact2.name);

  const [mapData, setMapData] = useState<MapData>({
    mapAddress: cart.site ? `${cart.site.street} ${cart.site.houseNumber}` : '',
    mapPostalCode: cart.site ? cart.site.postalCode : '',
    mapCity: cart.site ? cart.site.city : ''
  });

  const updateMapDebounce = useDebounce(
    (d: MapData) => setMapData({ ...mapData, ...d }), 400
  );

  const [search, setSearch] = useState(() => '');
  const { data, isLoading: sitesLoading } = useSiteList(search);

  const onChangeSiteInputValue = useDebounce(
    (newValue: string) => setSearch(newValue),
    500
  );

  useEffect(() => {
    setCart({ ...cart, organisation: loadedOrg?.result.id ?? '' });
  }, [loadedOrg]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNext = () => {
    const items: SchemaName[] = ['contact'];
    const list = [contactSchema.validate(cart.contact, { abortEarly: false })];

    if (!cart.siteFromApi && !cart.siteFromPickup) {
      setCart({ ...cart, site: formatSite(cart.site) });
      items.push('site');
      list.push(siteSchema.validate(cart.site, { abortEarly: false }));
    }
    if (hasSecondContact) {
      items.push('contact2');
      list.push(contactSchema.validate(cart.contact2, { abortEarly: false }));
    }
    if (hasDriver) {
      items.push('driver');
      list.push(driverSchema.validate(cart.driver, { abortEarly: false }));
    }

    Promise.allSettled([...list]).then((result) => {
      let status = 'fulfilled';

      result.forEach((x, index) => {
        if (x.status === 'fulfilled') return;

        switch (items[index]) {
          case 'site': {
            handleErrorsHelper(initAddressErrors, x.reason, setAddressErrors);
            break;
          }
          case 'contact': {
            handleErrorsHelper(initContactErrors, x.reason, setContactErrors);
            break;
          }
          case 'contact2': {
            handleErrorsHelper(initContactErrors, x.reason, setSecondContactErrors);
            break;
          }
          case 'driver': {
            handleErrorsHelper(initDriverErrors, x.reason, setDriverErrors);
            break;
          }
        }
        status = 'rejected';
      });

      if (status === 'fulfilled') onNext && onNext();
    });
  };

  const handleErrorsHelper = (errorSchema: any, result: any, errorAction: SetStateAction<any>) => {
    for (const [i, value] of result['inner'].entries()) {
      errorSchema = {
        ...errorSchema,
        [String(value.path)]: { error: true, message: value.errors[0] }
      };
      if (i === result['inner'].length - 1) {
        errorAction(errorSchema);
      }
    }
  };

  return {
    cart,
    setCart,
    setContact,
    setContact2,

    mapData,
    updateMapDebounce,

    handleNext,
    data,
    sitesLoading,
    onChangeSiteInputValue,

    contactErrors,
    setAddressErrors,
    addressErrors,
    setSecondContactErrors,
    secondContactErrors,
    setDriverErrors,
    driverErrors,

    setHasSecondContact,
    hasSecondContact,
    setHasDriver,
    hasDriver
  };
}
