import { SearchOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme';

export default function EmptyState(){
  const { t } = useTranslation('common');

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 150,
      flexDirection: 'column'
    }}>
      <SearchOff sx={{
        fontSize: 200,
        color: colors.blue
      }}/>
      <Typography variant="subtitle1">{t('empty-results')}</Typography>
    </Box>
  );
}
