import { Grid } from '@mui/material';
import { OfferModel } from '../../../services/models/Offer';
import BlueBox from '../../layout/BlueBox';
import OfferDetailTopType from './OfferDetailTopType';
import OfferDetailTopMap from './OfferDetailTopMap';
import OfferDetailTopIntro from './OfferDetailTopIntro';
import OfferDetailTopAddress from './OfferDetailTopAddress';

interface OfferDetailTopProps {
  offer: OfferModel;
}

export default function OfferDetailTop({ offer }: OfferDetailTopProps) {
  return (
    <BlueBox>
      <Grid container spacing={2}>
        <Grid item container xs={12} md={6}>
          <OfferDetailTopIntro offer={offer} />
          <OfferDetailTopAddress offer={offer} />
          <OfferDetailTopType offer={offer} />
        </Grid>
        <OfferDetailTopMap site={offer.site} />
      </Grid>
    </BlueBox>
  );
}
