import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OfferModel } from '../../../services/models/Offer';

interface OfferDetailTopAddress {
  offer: OfferModel;
}

export default function OfferDetailTopAddress({ offer }: OfferDetailTopAddress) {
  const { t } = useTranslation(['common']);

  return (
    <>
      <Typography
        variant="subtitle2"
        gutterBottom={false}
        sx={{ marginLeft: 0, marginTop: 2 }}
      >
        {t('common:address')}
      </Typography>

      <Grid item xs={12}>
        {offer.site && (
          <>
            {offer.site.street} {offer.site.houseNumber}
            {offer.site.houseNumberAddition}
            <br />
            {offer.site.postalCode || ''} {offer.site.city}
          </>
        )}
      </Grid>
    </>
  );
}
