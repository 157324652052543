import { createTheme } from '@mui/material';

export const spacing = {
  page: {
    maxWidth: '1400px'
  },
  navbar: {
    height: '70px',
    maxWidth: '1440px'
  },
  card: {
    padding: '24px',
    tableTitle: {
      padding: '24px 16px 0'
    }
  }
}

export const colors = {
  blue: '#007CB0',
  orange: '#FF5001',
  grey: '#CAC9D4',

  text: '#4A4A49',

  darkGrey: '#7E7E7E',
  lightBlue: '#F2F6FD',
  darkBlue: '#00638D',

  white: '#FFFFFF',
  black: '#000000',

  success: '#54DB5A',
  warning: '#FF9800',
  error: '#EF5350',

  boxBlue: '#F5F7FC',
  background: '#F6F6F9',
  stepperBackground: '#E4E4EA',

  disabledBackground: '#F6F6F6'
}

export default createTheme({
  palette: {
    primary: {
      main: colors.blue
    },
    secondary: {
      main: colors.orange
    },
    text: {
      primary: colors.text
    },
    success: { main: colors.success },
    warning: { main: colors.warning },
    error: { main: colors.error }
  },
  typography: {
    fontFamily: '\'Work Sans\', \'Avenir LT Std\', sans-serif',
    h1: {
      color: colors.black,
      fontWeight: 'bold',
      fontSize: 30,
      textTransform: 'uppercase'
    },
    h2: {
      color: colors.blue,
      fontWeight: 'bold',
      fontSize: 18,
      marginBottom: 12
    },
    subtitle1: {
      color: colors.blue,
      fontWeight: 'bold',
      fontSize: 18,
      marginBottom: 5
    },
    subtitle2: {
      color: colors.blue,
      fontWeight: 'bold',
      fontSize: 16,
      marginBottom: 10
    }
  },
  components: {
    // Card
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: spacing.card.padding // Could become just card in the future
        }
      }
    },
    // Table
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          th: {
            svg: {
              verticalAlign: 'middle',
              display: 'inline-block',
              marginRight: 5,
              marginBottom: 4,
              fontSize: 20
            },
            fontSize: 12,
            color: colors.darkGrey,
            verticalAlign: 'middle'
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            border: 0
          },
          ":hover": {
            backgroundColor: 'inherit !important'
          },
          td: {
            // Icon buttons
            button: {
              svg: {
                color: colors.blue
              },
              backgroundColor: colors.lightBlue
            },
            color: colors.text
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&.Mui-expanded': {
            border: 'none'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
          minHeight: '25px !important',
          '&.Mui-expanded': {
            margin: 0,
            padding: 0
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: 5
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
          opacity: 1,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        contained: {
          backgroundColor: colors.orange,
          '&:hover': {
            backgroundColor: colors.white,
            color: colors.orange
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:disabled, &.Mui-disabled': {
            svg: {
              color: colors.grey
            }
          }
        },
        sizeSmall: {
          backgroundColor: colors.lightBlue,
          '&:hover': {
            backgroundColor: colors.blue,
            svg: {
              color: colors.white
            }
          }
        }
      }
    },
    MuiAlert :{
      styleOverrides: {
        root: {
          button: {
            '&:hover': {
              backgroundColor: colors.grey
            }
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: colors.disabledBackground
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: colors.blue,
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 4
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          paddingLeft: 4
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: 'unset'
        }
      }
    }
  }
});
