import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useKeycloak } from '../../hooks/useKeycloak';


interface PrivateRouteProps extends RouteProps {
  component?:  any;
  location?: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const { isLoggedIn } = useKeycloak();

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isLoggedIn() ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
