import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { colors } from "../../theme";

interface HelpAlertProps {
  sx?: SxProps<Theme>;
  translation?: {
    key?: string;
    values?: { [key: string]: string };
  }
  step?: string;
  stepValues?: { [key: string]: string };
}

const HelpAlert = ({ sx, translation = { key: 'help' } }: HelpAlertProps) => {
  const { t } = useTranslation('common');

  return (
    <Alert icon={false} variant="filled" severity="info"
      sx={{ ...sx, borderRadius: 0, background: colors.blue }}>
      <Typography style={{ fontSize: 16, color: colors.white }}>
        {t(`alerts.${translation.key}`, { ...translation.values })}
      </Typography>
    </Alert>
  )
}

export default HelpAlert;
