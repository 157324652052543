import { FC } from 'react';
import CheckoutPage from '../pages/CheckoutPage';
import JobSurveyPage from '../pages/jobSurvey/JobSurveyPage';
import OfferDetailPage from '../pages/OfferDetailPage';
import OffersListPage from '../pages/OffersListPage';
import JobSurveyListPage from '../pages/jobSurvey/JobSurveyListPage';
import ProductsListPage from '../pages/ProductListPage';
import OrganizationPage from '../pages/settingPages/OrganizationPage';
import UserFormPage from '../pages/settingPages/UserFormPage';
import UserListPage from '../pages/settingPages/UserListPage';
import JobSurveyDetailPage from '../pages/jobSurvey/JobSurveyDetailPage';
import ComplementJobSurveyPage from '../pages/complementJobSurvey/ComplementJobSurveyPage';
import InvoicesListPage from '../pages/InvoicesListPage';
import JobSurveyVerify from '../pages/jobSurvey/JobSurveyVerify';
import ProfileFormPage from '../pages/settingPages/ProfileFormPage';

interface RouteConfig {
  name: string;
  component: FC
}

const routeConfig: {[to: string] : RouteConfig} = {
  '/huuropdrachten/:id': { name: 'routes:order-details', component: OfferDetailPage },
  '/huuropdrachten': { name: 'routes:rental-orders', component: OffersListPage },
  '/bestelling': { name: 'routes:new-order', component: ProductsListPage },
  '/werkopnames/:id/aanvullen': { name: 'routes:job-survey-details', component: ComplementJobSurveyPage },
  '/werkopnames/:id/controleren': { name: 'routes:job-survey-details', component: JobSurveyVerify },
  '/werkopnames/:id': { name: 'routes:job-survey-details', component: JobSurveyDetailPage },
  '/werkopnames': { name: 'routes:job-surveys', component: JobSurveyListPage },
  '/werkopname-maken': { name: 'routes:new-job-survey', component: JobSurveyPage },
  '/facturen': { name: 'routes:invoices', component: InvoicesListPage },
  '/instellingen/gebruikers/nieuw':  { name: 'routes:new-user', component: UserFormPage },
  '/instellingen/gebruikers/:id':  { name: 'routes:edit-user', component: UserFormPage },
  '/instellingen/gebruikers':  { name: 'routes:users', component: UserListPage },
  '/instellingen/bedrijf':  { name: 'routes:company-info', component: OrganizationPage },
  '/instellingen':  { name: 'routes:settings', component: ProfileFormPage },
  '/checkout':  { name: 'routes:checkout', component: CheckoutPage }
};
export default routeConfig;
