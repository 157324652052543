import { Logout, PersonOutline, Settings, ApartmentOutlined } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { deepOrange } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useKeycloak } from '../../hooks/useKeycloak';
import { colors } from '../../theme';
import { useProfile } from '../../hooks/useProfile';

export default function AccountButton() {
  const { token, logout, isLoggedIn, isPrimaryUser } = useKeycloak();
  const { profile } = useProfile();
  const [redirect, setRedirect] = useState<boolean>(!isLoggedIn());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setRedirect(!isLoggedIn());
  }, [token.access_token]); // eslint-disable-line react-hooks/exhaustive-deps

  return redirect ? (
    <Redirect to="/login" />
  ) : (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: '10px'
    }}>
      <IconButton onClick={handleClick} size="small"
                  sx={{ width: 35, height: 35, backgroundColor: colors.blue }}>
        <Avatar sx={{ width: 30, height: 30, bgcolor: deepOrange, fontSize: 14 }}>
          {profile?.firstName?.charAt(0)}{profile?.lastName?.charAt(0)}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isPrimaryUser() ? (
          <MenuItem component={Link} to="/instellingen/gebruikers">
            <PersonOutline style={{ marginRight: '6px' }} /> Gebruikers
          </MenuItem>
        ) : ''}
        <MenuItem component={Link} to="/instellingen/bedrijf">
          <ApartmentOutlined style={{ marginRight: '6px' }} /> Bedrijf
        </MenuItem>
        <MenuItem component={Link} to="/instellingen">
          <Settings style={{ marginRight: '6px' }} /> Instellingen
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Uitloggen
        </MenuItem>
      </Menu>
    </Box>
  );
}
