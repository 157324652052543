import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

interface NavigationsButtonsProps{
  next: () => void;
  previous?: () => void;
  validate?: () => boolean;
  nextDisabled?: boolean;
  previousDisabled?: boolean;
  previousText?: string;
  nextText?: string;
  submitting?: boolean;
}

const buttonStyle = {
  paddingX: 2,
  paddingY: 1
};

export default function NavigationButtons({
  next,
  previous,
  validate = () => { return true; },
  previousDisabled = false,
  nextDisabled = false,
  submitting = false,
  nextText = '',
  previousText = ''
} : NavigationsButtonsProps) {

  const handleNextClick = () => {
    if (validate()) {
      next();
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <Button variant="contained" sx={ buttonStyle } onClick={ previous } disabled={ previousDisabled }>
        <ArrowBack sx={{ marginRight: 1 }} /> {previousText}
      </Button>
      <LoadingButton sx={ buttonStyle } variant="contained" onClick={ handleNextClick } disabled={ nextDisabled } loading={ submitting }>
        {nextText} <ArrowForward sx={{ marginLeft: 1 }} />
      </LoadingButton>
    </Box>
  );
}
