import { Box, Button, Grid, Skeleton } from '@mui/material';
import {
  formatDateOnlyFromApi,
  formatToLocalTimeFromApi,
  getTimeFromDate
} from '../../utils/dateFormat';
import { useGetOrderByOfferId } from '../../hooks/api/useOffers';
import { Fragment, useState } from 'react';
import BookOutOffer from './BookOutOffer';
import { useTranslation } from 'react-i18next';
import CartItemDetailRow from '../common/CartItemDetailRow';
import DownloadIcon from '@mui/icons-material/Download';
import { useApi } from '../../hooks/api/useApi';
import { usePopup } from '../../hooks/usePopupMessage';
import { LoadingButton } from '@mui/lab';
import { OfferModel } from '../../services/models/Offer';
import { DocumentFile, OrderService } from '../../services/OrderService';
import { CircularProgress } from '@material-ui/core';

interface OfferDetailRowProps {
  offer: OfferModel;
  onBookOut: (offerId: string, orderNumber: string) => void;
}

export default function OfferDetailRow({ offer, onBookOut }: OfferDetailRowProps) {
  const { t } = useTranslation(['common', 'offers']);
  const { data, isLoading } = useGetOrderByOfferId(offer.id || '');
  const { setMessage } = usePopup();

  const [files, setFiles] = useState<DocumentFile[]>([]);
  const [configurations, setConfigurations] = useState<DocumentFile[]>([]);
  const [loadingDocuments, setLoadingDocuments] = useState(false);

  const orderService = useApi<OrderService>(new OrderService());

  const bookOutPossible =
    !!data && !!data.result
    && data.result.orderNumber !== undefined && data.result.orderNumber > 0
    && !data.result.bookOutDate && !data.result.endDate;

  const openFile = (file: DocumentFile) => {
    window.open(`https://acceptatie-peinemann.allsolutions.nl${file.filePaths[0]}`, '_blank');
  };

  const getDocuments = async () => {
    setLoadingDocuments(true);

    orderService.getDocuments(data?.result.id ?? '')
      .then(response => {
        setFiles(response.result.entries);
        getConfigurations();
      })
      .catch(() => {
        setMessage({
          message: `${t('common:error.documents-not-found')}`,
          type: 'error',
          open: true
        });

        setLoadingDocuments(false);
      });
  };

  const getConfigurations = () => {
    orderService.getConfigurations(data?.result.configurationNumber ?? '')
    .then(response => {
      setConfigurations(response.result.entries);
      setLoadingDocuments(false);
    })
    .catch(() => setLoadingDocuments(false));
  }

  return (
    <>
      {isLoading && (
        <Grid key={offer.id} item xs={12}>
          <Skeleton sx={{ width: '100%', maxWidth: 'unset' }}>
            <Box sx={{ height: '50px', width: '100%' }}>&nbsp;</Box>
          </Skeleton>
        </Grid>
      )}

      {data && (
        <Fragment key={offer.id}>
        <CartItemDetailRow
          product={data.result.product}
          order={data.result}
          startDate={formatToLocalTimeFromApi(data.result.startDate)}
          endDate={data.result.endDate ? formatToLocalTimeFromApi(data.result.endDate) : undefined}
          hideProperties={false}
          buttons={<>
            <Box sx={{
              display: 'flex',
              justifyContent: { md: 'right' },
              marginTop: { xs: 2, md: 0 }
            }}>
              {bookOutPossible && (
                <BookOutOffer
                  date={data.result.endDate ? new Date(data.result.endDate) : new Date()}
                  time={getTimeFromDate(new Date(data.result.endDate).valueOf())}
                  onClick={() => {
                    if (offer.id && data.result.orderNumber) {
                      onBookOut(offer.id, data.result.orderNumber.toString());
                    }
                  }}
                />
              )}
              {data.result.bookOutDate && (
                <>
                  <span style={{ fontWeight: 'bold' }}>{t('offers:signed-off-at')}</span>:
                  {formatDateOnlyFromApi(data.result.bookOutDate)}
                </>
              )}
            </Box>
            {files.length === 0 && (
              <div style={{ position: 'relative' }}>
                <div style={{ float: 'right' }}>
                  <LoadingButton
                    disabled={data.result.orderNumber !== undefined && data.result.orderNumber <= 0 }
                    loading={loadingDocuments}
                    loadingPosition="start"
                    startIcon={<DownloadIcon />}
                    variant="outlined"
                    onClick={getDocuments}
                  >
                    {t('common:retrieve-documents')}
                  </LoadingButton>
                </div>
              </div>
            )}
          </>}
        >
          <Box>
            {files.length > 0 && (
              <>
               <hr style={{ marginTop: 20 }} />
                {[...files, ...configurations].map((file: DocumentFile, i) => {
                  return <Button
                            style={{ display: 'block', width: '100%', textAlign: 'left', paddingLeft: 0 }}
                            key={i}
                            onClick={() => openFile(file)}
                    >
                      {file.fileName}
                    </Button>;
                })}
                {loadingDocuments && <CircularProgress style={{ marginTop: 10 }} />}
              </>
            )}
          </Box>
        </CartItemDetailRow>
      </Fragment>
      )}
    </>
  );
}
