import { Add } from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/system/Box';
import { Link } from 'react-router-dom';
import ShoppingCartButton from '../../cart/ShoppingCartButton';
import AccountButton from '../AccountButton';
import { useTranslation } from 'react-i18next';
import { colors, spacing } from '../../../theme';
import { SxProps, Theme } from '@mui/material';
import NotificationIcon from '../NotificationIcon';

export default function NavBar(){
  const { t } = useTranslation(['routes', 'orders']);

  const sxTextButtons : SxProps<Theme> = {
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.darkBlue
    }
  }

  return (
    <AppBar position="fixed" sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      boxShadow: 'none',
      margin: 0,
      padding: 0,
      background: colors.blue
    }}>
      <Box sx={{
        margin: '0 auto',
        width: '100%',
        maxWidth: spacing.navbar.maxWidth
      }}>
        <Toolbar sx={{
          justifyContent: 'space-between',
          maxWidth: spacing.page.maxWidth,
          height: spacing.navbar.height,
          maxHeight: spacing.navbar.height
        }}>
          <Link to="/" style={{ maxHeight: spacing.navbar.height }}>
            <img src="/images/Logo.svg" className="App-logo" alt="logo" height="70" />
          </Link>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 3
          }}>
            <Button
              aria-controls="basic-menu"
              aria-haspopup="true"
              component={Link} to="/"
              sx={sxTextButtons}
            >
              {t('routes:dashboard')}
            </Button>
            <Button
              aria-controls="basic-menu"
              aria-haspopup="true"
              component={Link} to="/huuropdrachten"
              sx={sxTextButtons}
            >
              {t('routes:rental-orders')}
            </Button>
            <Button
              aria-controls="basic-menu"
              aria-haspopup="true"
              component={Link} to="/werkopnames"
              sx={sxTextButtons}
            >
              {t('routes:job-surveys')}
            </Button>
            <Button component={Link} to="/facturen" sx={sxTextButtons}>
              {t('routes:invoices')}
            </Button>
            <Button component={Link} to="/bestelling" variant="contained">
              {t('orders:order-it')} <Add />
            </Button>
            <Button component={Link} to="/werkopname-maken" variant="contained">
              {t('orders:job-survey')} <Add />
            </Button>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 3
          }}>
            <ShoppingCartButton />
            <NotificationIcon />
            <AccountButton />
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}
