import { Box } from '@mui/material';
import {  ReactNode } from 'react';
import { colors } from '../../theme';

interface OutlinedBoxProps{
  children?: ReactNode;
}

export default function OutlinedBox({ children }: OutlinedBoxProps){
  return (
    <Box sx={{
      display: 'flex',
      border: `1px solid ${colors.grey}`,
      borderRadius: 3,
      marginY: 2.5,
      padding: 2.5,
      flexDirection: 'column'
    }}>
      {children}
    </Box>
  );
}
