import { Api, ApiResponse, PagedData } from './Api';
import { SiteModel } from './SiteService';
import { ProductModel } from './ProductService';

export enum InvoiceStatusFilters {
  open = 'Open',
  paid = 'Paid'
}

export enum InvoiceExpirationFilters {
  zeroToThirty = 'ZeroToThirty',
  thirtyToSixty = 'ThirtyToSixty',
  sixtyToNinety = 'SixtyToNinety',
  ninetyPlus = 'NinetyPlus'
}

export interface InvoiceFilterParams {
  pageSize?: number;
  invoiceStatus?: 0 | 1;
}

export interface InvoiceClusterModel {
  entries: InvoiceModel[];
}

export interface InvoiceDocumentClusterModel {
  entries: InvoiceDocumentModel[];
}

export interface InvoiceDocumentModel {
  fileName: string;
  filePaths: string[];
  id: string;
  referenceNumber: number;
  relation: string;
  topic: string;
  versionNumber: number;
}

export interface InvoiceModel {
  id?: string;
  invoiceNumber: number;
  invoiceDate: string;
  expirationDate: string;
  remainingSaldo: number;
  customerReference: string;
  site?: SiteModel;
  product?: ProductModel;

  machine?: string;
  workAddress?: string;
  invoiceFilePath?: string;
}

export class InvoiceService extends Api {
  getInvoices = async (params?: InvoiceFilterParams) => {
    return this.get<ApiResponse<PagedData<InvoiceClusterModel>>>(
      '/api/invoices', {},
      params
    );
  };

  getInvoiceDocument = async (invoiceId: string) => {
    return this.get<ApiResponse<InvoiceDocumentClusterModel>>(
      `/api/invoices/${invoiceId}/document`
    );
  };
}
