import { Box, Typography, Card, Grid, CardContent } from '@mui/material';
import PageTitle from '../../components/layout/PageTitle';
import { UserSideMenu } from '../../components/users/UserSideMenu';
import { useKeycloak } from '../../hooks/useKeycloak';
import { useGetMultipleByRelationIds } from '../../hooks/api/useOrganizations';
import Loader from '../../components/common/Loader';
import { useTranslation } from 'react-i18next';

export default function OrganizationPage() {
  const { t } = useTranslation('settings');
  const { getRelationNumbers } = useKeycloak();
  const { data, isLoading } = useGetMultipleByRelationIds(
    getRelationNumbers()
  )

  return (
    <>
      <PageTitle title={`${t('title')}`} />
      <Box
        sx={{
          display: 'flex',
          gap: 5
        }}
      >
        <UserSideMenu />
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <Typography variant="subtitle1">{t('company-info.organisation')}</Typography>
            {isLoading ? (
              <Loader />
            ) : (
              data?.result.map((org) => {
                return (
                  <Grid container spacing={1} sx={{ paddingY: 2.5 }} >
                    <Grid item xs={12}>
                      {org.name}
                    </Grid>
                    <Grid item xs={12}>
                      {org.billingAddress.addressLine}
                    </Grid>
                    <Grid item xs={12}>
                      {org.billingAddress.postalCode} {org.billingAddress.city}
                    </Grid>
                    <Grid item xs={12}>
                      {org.phoneNumber || '-'}
                    </Grid>
                  </Grid>
                )
              })
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
