import { Add } from '@mui/icons-material';
import { Box, IconButton, TableCell } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { ProductModel } from '../../services/ProductService';
import { currencyFormat } from '../../utils/currencyFormat';
import ProductPopover from './ProductPopover';
import { getDailyPrice, getWeeklyPrice } from '../../utils/priceHelper';

interface ProductTableRowProps {
  product: ProductModel;
  onAddProduct: (item: ProductModel, isAccessoire: boolean) => void;
}

export default function ProductTableRow({ product, onAddProduct }: ProductTableRowProps) {
  return (
    <TableRow hover>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: 1
          }}>
            <span style={{ fontWeight: 'bold' }}>{product.productGroup.description}</span>
            <span style={{ fontWeight: 'normal' }}>{product.description}</span>
          </Box>
          {product.productImage !== null &&
            product.productImage !== undefined && (
            <ProductPopover product={product} />
          )}
        </Box>
      </TableCell>
      <TableCell>{product.workHeightNumber ? `${product.workHeightNumber}` : '-'}</TableCell>
      <TableCell>
        {product.reachLengthNumber ? `${product.reachLengthNumber}`: '-'}
      </TableCell>
      <TableCell>{product.liftPowerNumber ? `${product.liftPowerNumber}` : '-'}</TableCell>
      <TableCell>{product.weightNumber ? `${product.weightNumber}` : '-'}</TableCell>
      <TableCell>{currencyFormat(getDailyPrice(product))}</TableCell>
      <TableCell align="right">
        {currencyFormat(getWeeklyPrice(product))}
      </TableCell>
      <TableCell scope="row">
        <IconButton
          size="small"
          onClick={() => {
            onAddProduct(product, false);
          }}
        >
          <Add sx={{ width: 25, height: 25 }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
