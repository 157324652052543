import React from 'react';
import {
  Box, Button, Card, CardContent,
  Typography
} from '@mui/material';
import { QueryParamsProvider } from '../../hooks/queryParams/useProductFilters';
import ProductPagination from '../../components/products/ProductPagination';
import BlueBox from '../../components/layout/BlueBox';
import { useJobSurvey } from '../../hooks/useJobSurvey';
import { useTranslation } from 'react-i18next';
import JobSurveyCartTable from '../../components/jobSurveys/jobSurveyCartTable';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../../components/layout/navigation/Breadcrumb';
import { ArrowForward } from '@mui/icons-material';

interface ComplementJobSurveyEditProductsProps {
  onNext: () => void;
}

export default function ComplementJobSurveyEditProducts({ onNext }: ComplementJobSurveyEditProductsProps) {
  const { t } = useTranslation(['products', 'offers', 'orders']);
  const { addProductToJobSurvey } = useJobSurvey();
  const { id } = useParams<RouteParams>();
  const { jobSurvey } = useJobSurvey();

  return (
    <>
      <Card sx={{ flex: 1 }}>
        <CardContent>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">{t('orders:job-survey')} {id}</Typography>
          </Box>
          <BlueBox>
            <Box sx={{
              display: 'flex',
              gap: 5
            }}>
              <QueryParamsProvider>
                <ProductPagination
                  pageSize={14}
                  onAddProduct={(product, isAccessoire) => addProductToJobSurvey(product, isAccessoire)}
                />
              </QueryParamsProvider>
            </Box>
          </BlueBox>
          <BlueBox>
            <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
              {t('offers:machines')}
            </Typography>
            <JobSurveyCartTable />
          </BlueBox>
        </CardContent>
      </Card>

      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button
          type="submit"
          variant="contained"
          onClick={() => onNext()}
          disabled={jobSurvey.items.length <= 0}
        >
          {t('common:button.next')} <ArrowForward sx={{ marginLeft: 1 }} />
        </Button>
      </Box>
    </>
  );
}
