import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../../components/layout/navigation/Breadcrumb';
import { useGetOfferByClusterId } from '../../hooks/api/useOffers';
import { useJobSurvey } from '../../hooks/useJobSurvey';
import PageTitle from '../../components/layout/PageTitle';
import { Box, Card, CardContent } from '@mui/material';
import ComplementJobSurveyTitle from './ComplementJobSurveyTitle';
import { useEffect, useState } from 'react';
import ComplementJobSurveyEditAddress from './ComplementJobSurveyEditAddress';
import ComplementJobSurveyEditProducts from './ComplementJobSurveyEditProducts';
import { initContact, initDriver, initSite } from '../../hooks/useCart';
import ComplementJobSurveyEditPlanning from './ComplementJobSurveyEditPlanning';
import VerifyCheckout from '../../components/cart/checkout/VerifyCheckout';

export default function ComplementJobSurveyPage() {
  const { t } = useTranslation(['common', 'routes', 'offers']);

  const { id } = useParams<RouteParams>();
  const { query } = useGetOfferByClusterId(id || '');
  const { isLoading } = query;
  const { jobSurvey, setJobSurvey, setJobSurveySite } = useJobSurvey();

  const [activeStep, setActiveStep] = useState(0);

  const entries = query.data?.result.entries;

  useEffect(() => {
    if (entries && entries[0]) {
      setJobSurveySite(entries[0].site ?? initSite);
      setJobSurvey({
        ...jobSurvey,
        site: entries[0].site ?? initSite,
        contact: entries[0].contactPerson ?? initContact,
        contact2: entries[0].additionalContactPerson ?? initContact,
        driver: entries[0].driver ?? initDriver,
        customerReference: entries[0].customerReference ?? '',
        siteDescription: entries[0].comments ?? ''
      })
    }
  }, [entries]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isLoading || !entries || entries.length === 0 && (
        <>
          <PageTitle title={`${t('routes:complement-job-survey')}`} /><Card
          sx={{ flex: 1, marginBottom: 4 }}>
          <CardContent>
            {isLoading
              ? t('common:loading')
              : t('common:x-not-exist', { item: t('orders:this-job-survey') })}
          </CardContent>
        </Card>
        </>
      )}

      {!isLoading && entries && entries.length > 0 && (
        <>
          <ComplementJobSurveyTitle />
          <Box sx={{
            display: 'flex',
            gap: 5,
            flexDirection: 'column'
          }}>
            {activeStep === 0 && (
              <ComplementJobSurveyEditProducts onNext={() => setActiveStep(activeStep + 1)} />
            )}
            {activeStep === 1 && (
              <>
                <ComplementJobSurveyEditAddress
                  onPrevious={() => setActiveStep(activeStep - 1)}
                  onNext={() => setActiveStep(activeStep + 1)}
                />
              </>
            )}
            {activeStep === 2 && (
              <ComplementJobSurveyEditPlanning
                onPrevious={() => setActiveStep(activeStep - 1)}
                onNext={() => setActiveStep(activeStep + 1)}
              />
            )}
            {activeStep === 3 && (
              <VerifyCheckout
                type={'job-survey'}
                onPrevious={() => setActiveStep(activeStep - 1)}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
}
