import { ChangeEvent, useState } from 'react';
import {
  Card,
  Box,
  Grid,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem
} from '@mui/material';
import OutlinedBox from '../../layout/OutlinedBox';
import TextFieldShrink from '../../layout/TextFieldShrink';
import { SiteModel, SiteService } from '../../../services/SiteService';
import { useTranslation } from 'react-i18next';
import { CheckoutStepProps, CheckoutStepType } from './index';
import CheckoutStep from './CheckoutStep';
import { CardContent } from '@material-ui/core';
import {
  initAddressErrors,
  initContactErrors,
  initDriverErrors,
  useLocationCheckoutForm
} from './hooks/useLocationCheckoutForm';
import { useSitePickupList } from '../../../hooks/api/useSites';
import { colors } from '../../../theme';
import { initContact, initDriver, initSite } from '../../../hooks/useCart';
import { useDebounce } from '../../../utils/debounce';
import { useApi } from '../../../hooks/api/useApi';
import SearchAddressDropdown from '../../common/SearchAddressDropdown';
import Map from '../../layout/Map';

export default function LocationCheckout({ onNext, onPrevious }: CheckoutStepProps) {
  const {
    cart,
    setCart,
    setContact,
    setContact2,
    mapData,
    updateMapDebounce,

    handleNext,

    contactErrors,
    setAddressErrors,
    addressErrors,
    setSecondContactErrors,
    secondContactErrors,
    setDriverErrors,
    driverErrors,

    setHasSecondContact,
    hasSecondContact,
    setHasDriver,
    hasDriver
  }
    = useLocationCheckoutForm({ onNext, onPrevious });

  const { data: pickupData, isLoading: pickupsLoading } = useSitePickupList();
  const { getAddressByPostalCode } = useApi<SiteService>(new SiteService());
  const { t } = useTranslation(['common', 'checkout', 'orders', 'validation']);

  const [addWorkAddress, setAddWorkAddress] = useState(false);

  const addressByPostalCode = (postalCode: string, houseNumber: string) => {
    return getAddressByPostalCode(postalCode, houseNumber);
  };

  const setSite = (site: SiteModel, siteFromApi = false, siteFromPickup = false): void => {
    setCart({ ...cart, site, siteFromApi, siteFromPickup });
  };

  const onChangeDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const siteFromPickup = event.target.value === 'true';
    setCart({ ...cart, siteFromPickup });

    if (!siteFromPickup) setSite(initSite);
  };

  const cleanPostalCodeField = (value?: string) => {
    if (!value) return;

    if (value.length === 6) {
      const formattedString = `${value.substring(0, 4)} ${value.substring(4)}`;
      setSite(
        {
          ...cart.site,
          postalCode: formattedString
        },
        false
      );
      searchByPostalCode(formattedString, cart.site.houseNumber);
    }
  }

  const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    searchByPostalCode(e.target.value, cart.site.houseNumber);
  };

  const handleHouseNumberChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    searchByPostalCode(cart.site.postalCode, e.target.value);
  };

  const searchByPostalCode = useDebounce((postalCode: string, houseNumber: string) => {
    if (postalCode.length >= 7 && houseNumber) {
      addressByPostalCode(postalCode.replaceAll(' ', ''), houseNumber).then(response => {
        updateMapDebounce({
          mapAddress: `${response.result.addressLine} ${response.result.houseNumber}`,
          mapCity: response.result.city,
          mapPostalCode: response.result.postalCode
        });

        setSite(
          {
            ...cart.site,
            postalCode: postalCode,
            city: response.result.city,
            street: response.result.addressLine,
            houseNumber: response.result.houseNumber
          },
          false
        );
      });
    }
  }, 600);

  return (
    <CheckoutStep
      currentStep={CheckoutStepType.LOCATION}
      onNext={handleNext}
      onPrevious={onPrevious}
      previousLabel={t('common:button.previous') ?? ''}
      nextLabel={t('common:button.next') ?? ''}
    >
      <Card sx={{ flex: 1 }}>
        <CardContent>
          <Typography variant="subtitle1">{t('checkout:location.label')}</Typography>
          <OutlinedBox>
            <Typography variant="subtitle2">{t('common:work-address')}</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <RadioGroup
                row
                value={cart.siteFromPickup}
                onChange={onChangeDelivery}
              >
                <FormControlLabel
                  value={'false'}
                  control={<Radio />}
                  label={t('checkout:location.delivery')}
                />
                <FormControlLabel
                  value={'true'}
                  control={<Radio />}
                  label={t(`checkout:location.pick-up${cart.siteFromPickup ? '-at' : ''}`)}
                  disabled={pickupsLoading}
                />
                {cart.siteFromPickup && (
                  <>
                    <Select
                      value={cart.site.city}
                      style={{ height: '56px', lineHeight: '1.5em', width: '250px' }}
                      onChange={(e) => {
                        const site = pickupData?.result.find((x) => x.city === e.target.value);
                        setSite(site || initSite, false, true);
                      }}
                    >
                      {!pickupsLoading && pickupData?.result.map((site) => (
                        <MenuItem key={site.id} value={site.city}>
                          {site.city}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              </RadioGroup>
            </Box>
          </OutlinedBox>
          <OutlinedBox>
            <Typography variant="subtitle2">{t('checkout:location.fill-label')}</Typography>
            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  {cart.siteFromPickup && addressErrors.street.error && (
                    <Grid item xs={12} color={colors.error}>
                      {t('validation:choose-a-pickup-site', { option: `${t('checkout:location.delivery')}` })}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SearchAddressDropdown value={cart.site || initSite}
                                           disabled={cart.siteFromPickup}
                                           onAddressSelect={(e, site, reason) => {
                                             updateMapDebounce({
                                               mapAddress: site ? `${site?.street} ${site?.houseNumber}` : '',
                                               mapCity: site ? site?.city ?? '' : '',
                                               mapPostalCode: site ? site?.postalCode ?? '' : ''
                                             });

                                             if (reason === 'clear') {
                                               setSite(site || initSite, false);
                                             } else {
                                               setAddressErrors(initAddressErrors);
                                               setSite(site || initSite, true);
                                             }
                                           }} />
                    <div style={{ marginTop: '12px' }} />
                    <FormControlLabel control={<Checkbox
                      onChange={(event) => setAddWorkAddress(event.target.checked)}
                      checked={addWorkAddress} />} label={t('orders:add-work-address')} />
                  </Grid>
                  {addWorkAddress && <>
                    <Grid item xs={12} md={6}>
                      <TextFieldShrink
                        fullWidth
                        required
                        label={t('common:postal-code')}
                        value={cart.site?.postalCode}
                        error={!cart.siteFromPickup && addressErrors.postalCode.error}
                        helperText={addressErrors.postalCode.message}
                        inputProps={{ maxLength: 7 }}
                        onChange={(e) => {
                          setSite(
                            {
                              ...cart.site,
                              postalCode: e.target.value
                            },
                            false
                          );
                          handlePostalCodeChange(e);
                        }}
                        onBlur={() => cleanPostalCodeField(cart.site?.postalCode)}
                        disabled={cart.siteFromPickup || cart.siteFromApi}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextFieldShrink
                        fullWidth
                        required
                        label={t('common:labels.housenumber')}
                        value={cart.site?.houseNumber}
                        error={!cart.siteFromPickup && addressErrors.houseNumber.error}
                        helperText={addressErrors.houseNumber.message}
                        onChange={(e) => {
                          setSite(
                            {
                              ...cart.site,
                              name: cart.site.street + ' ' + e.target.value + ' ' + cart.site.city,
                              houseNumber: e.target.value
                            },
                            false
                          );
                          handleHouseNumberChange(e);
                        }}
                        disabled={cart.siteFromPickup || cart.siteFromApi}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextFieldShrink
                        fullWidth
                        label={t('common:labels.addition') ?? ''}
                        name="site.houseNumberAddition"
                        value={cart.site?.houseNumberAddition}
                        onChange={(e) => {
                          setSite(
                            {
                              ...cart.site,
                              name: cart.site.street + ' ' + cart.site.houseNumber + '' + e.target.value + ' ' + cart.site.city,
                              houseNumberAddition: e.target.value
                            },
                            false
                          );
                        }}
                        disabled={cart.siteFromPickup || cart.siteFromApi}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldShrink
                        fullWidth
                        required
                        label={t('common:street')}
                        value={cart.site?.street}
                        error={!cart.siteFromPickup && addressErrors.street.error}
                        helperText={addressErrors.street.message}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldShrink
                        fullWidth
                        required
                        label={t('common:labels.city')}
                        helperText={addressErrors.city.message}
                        error={!cart.siteFromPickup && addressErrors.city.error}
                        value={cart.site.city}
                        disabled={true}
                      />
                    </Grid>
                  </>
                  }
                  <Grid item xs={false} md={6}></Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t('common:particularities')}
                      multiline
                      fullWidth
                      value={cart.siteDescription || ''}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(e) => setCart({ ...cart, siteDescription: e.target.value })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Map
                  address={mapData.mapAddress || ''}
                  postalCode={mapData.mapPostalCode || ''}
                  city={mapData.mapCity || ''}
                />
              </Grid>
            </Grid>
          </OutlinedBox>
          <OutlinedBox>
            <Typography variant="subtitle2">{t('common:contact-person.on-site')}</Typography>
            <Grid container spacing={2} sx={{ marginBottom: 3 }}>
              <Grid item xs={6}>
                <TextFieldShrink
                  value={cart.contact.name}
                  helperText={contactErrors.name.message}
                  error={contactErrors.name.error}
                  fullWidth
                  onChange={(e) => {
                    setContact({
                      ...cart.contact,
                      name: e.target.value
                    });
                  }}
                  required
                  label={t('common:name')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldShrink
                  fullWidth
                  onChange={(e) => {
                    setContact({
                      ...cart.contact,
                      phoneNumber: e.target.value
                    });
                  }}
                  required
                  helperText={contactErrors.phoneNumber.message}
                  error={contactErrors.phoneNumber.error}
                  value={cart.contact.phoneNumber}
                  label={t('common:phone')}
                />
              </Grid>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasSecondContact}
                      onChange={(e) => {
                        setHasSecondContact(e.target.checked);
                        if (!e.target.checked) {
                          setContact2(initContact);
                          setSecondContactErrors(initContactErrors);
                        }
                      }}
                    />
                  }
                  label={t('common:contact-person.add-alternative')}
                />
              </Grid>
              {hasSecondContact && (
                <Grid item md={12}>
                  <Typography variant="subtitle2">
                    {t('common:contact-person.alternative')}
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginBottom: 3
                    }}
                  >
                    <Grid item xs={6}>
                      <TextFieldShrink
                        required
                        value={cart.contact2?.name || ''}
                        helperText={secondContactErrors.name.message}
                        error={secondContactErrors.name.error}
                        fullWidth
                        onChange={(e) => {
                          setContact2({
                            ...cart.contact2,
                            name: e.target.value
                          });
                        }}
                        label={t('common:name')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextFieldShrink
                        fullWidth
                        required
                        onChange={(e) => {
                          setContact2({
                            ...cart.contact2,
                            phoneNumber: e.target.value
                          });
                        }}
                        helperText={secondContactErrors.phoneNumber.message}
                        error={secondContactErrors.phoneNumber.error}
                        value={cart.contact2?.phoneNumber || ''}
                        label={t('common:phone')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </OutlinedBox>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasDriver}
                onChange={(e) => {
                  setHasDriver(e.target.checked);
                  if (!e.target.checked) {
                    setCart({ ...cart, driver: initDriver });
                    setDriverErrors(initDriverErrors);
                  }
                }}
              />
            }
            label={`${t('driver.label')}`}
          />
          {hasDriver && (
            <OutlinedBox>
              <Typography variant="subtitle2">{t('driver.label')}</Typography>
              <Grid container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={6}>
                  <TextFieldShrink
                    fullWidth
                    required
                    onChange={(e) => {
                      setCart({
                        ...cart,
                        driver: { ...cart.driver, name: e.target.value }
                      });
                    }}
                    helperText={driverErrors.name.message}
                    error={driverErrors.name.error}
                    value={cart.driver.name}
                    label={t('common:name')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldShrink
                    fullWidth
                    required
                    onChange={(e) => {
                      setCart({
                        ...cart,
                        driver: { ...cart.driver, phoneNumber: e.target.value }
                      });
                    }}
                    helperText={driverErrors.phoneNumber.message}
                    error={driverErrors.phoneNumber.error}
                    value={cart.driver.phoneNumber}
                    label={t('common:phone')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(e) => {
                      setCart({
                        ...cart,
                        driver: { ...cart.driver, misc: e.target.value }
                      });
                    }}
                    value={cart.driver.misc}
                    label={t('checkout:verification.other-remarks')}
                  />
                </Grid>
              </Grid>
            </OutlinedBox>
          )}
        </CardContent>
      </Card>
    </CheckoutStep>
  );
}
