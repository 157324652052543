import { Dispatch, SetStateAction } from 'react';

export enum CheckoutStepType {
  CART,
  LOCATION,
  PLANNING,
  VERIFICATION
}

export interface CheckoutStepProps {
  setSteps?: Dispatch<SetStateAction<number>>;

  onNext?: () => void;
  onPrevious?: () => void;
  type?: 'cart' | 'job-survey';
}
