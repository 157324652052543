import { SaveCartModel, SavedCartModel, ConfirmCartModel } from '../hooks/models/cart';
import { Api, ApiResponse } from './Api';

export interface DriverModel {
  name: string;
  phoneNumber: string;
  misc: string;
}

export class CheckoutService extends Api {
  saveCheckout = async(cart: SaveCartModel) => {
    return this.post<ApiResponse<SavedCartModel>>(
      '/api/checkout',
      cart
    );
  };

  confirmCheckout = async(model: ConfirmCartModel) => {
    return this.put<ApiResponse<void>>(
      '/api/checkout/confirm',
      model
    );
  };

  saveJobSurvey = async(cart: SaveCartModel, id: string) => {
    return this.put<ApiResponse<SavedCartModel>>(
      `/api/checkout/${id}`,
      cart
    );
  }
}
