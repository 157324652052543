import { Alert, Snackbar } from '@mui/material';
import { usePopup } from '../../../hooks/usePopupMessage';

export default function PopupMessages(){
  const { popup, setMessage } = usePopup();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage({ ...popup, open: false });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={popup.open}
      onClose={handleClose}
      autoHideDuration={3000}
    >
      <Alert onClose={handleClose} severity={popup.type} sx={{ width: '100%' }}>
        {popup.message}
      </Alert>
    </Snackbar>
  );
}
