import { Card, CardContent, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useOfferTypeList } from '../../hooks/api/useOffers';
import { OfferType } from '../../services/models/Offer';
import Loader from '../common/Loader';
import DashboardRow from './DashboardRow';

interface DashboardCardProps {
  offerType: OfferType;
  maxRows: number;
}

const DashboardCard = (props: DashboardCardProps) => {
  const { data, isLoading, isError } = useOfferTypeList(props.offerType);
  const { t } = useTranslation(['routes', 'common']);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Box sx={{ marginLeft: 2, marginRight: 2 }}>
        <Typography>
          {t('common:error.generic')}
        </Typography>
      </Box>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle1">
          {t(`${props.offerType === OfferType.Offer ? 'rental-orders' : 'job-surveys'}`)}
        </Typography>

        {data?.result.items.length === 0 && (
          <Typography>{t('common:no-results')}</Typography>
        )}
        {data?.result.items.slice(0, props.maxRows).map((cluster) => {
          return (
            <DashboardRow
              key={cluster.entries[0].clusterIdentifier}
              offer={cluster.entries[0]}
            />
          );
        })}

      </CardContent>
    </Card>
  );
};

export default DashboardCard;
