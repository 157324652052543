import Box from '@mui/material/Box';
import Link, { LinkProps } from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import routeConfig from '../../../router/RouteConfig';
import { useTranslation } from 'react-i18next';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

export const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const crumbFontSize = 12;

export interface RouteParams {
  id?: string;
}

export default function Breadcrumb() {
  const { t } = useTranslation(['dashboard']);

  const location = useLocation();
  const { id } = useParams<RouteParams>();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 2 }}>
      {
        <Breadcrumbs sx={{
          fontSize: crumbFontSize
        }} separator=">" aria-label="breadcrumb">

          <LinkRouter underline="hover" color="inherit" to="/">
            Dashboard
          </LinkRouter>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            if (id !== undefined && last) {
              const to = `/${pathnames.slice(0, index).join('/')}/:id`;
              return (
                <Typography sx={{
                  fontSize: crumbFontSize
                }} color="text.primary" key={'link-' + to}>
                  {t(routeConfig[to].name)}
                </Typography>
              );
            } else if (last) {
              return (
                <Typography sx={{
                  fontSize: crumbFontSize
                }} color="text.primary" key={'link-' + to}>
                  {t(routeConfig[to].name)}
                </Typography>
              );
            } else {
              return (
                <LinkRouter underline="hover" color="inherit" to={to} key={'link-' + to}>
                  {t(routeConfig[to].name)}
                </LinkRouter>
              );
            }
          })}
        </Breadcrumbs>
      }
    </Box>
  );
}
