import { Box, Grid, Typography } from '@mui/material';
import { formatDateOnly } from '../../../utils/dateFormat';
import { OfferModel } from '../../../services/models/Offer';
import { useTranslation } from 'react-i18next';

interface OfferDetailTopIntroProps {
  offer: OfferModel;
}

export default function OfferDetailTopIntro({ offer }: OfferDetailTopIntroProps) {
  const { t } = useTranslation(['common']);

  return (
    <>
      <Typography variant="subtitle2" sx={{ marginLeft: 0 }}>
        {t('common:planning')}
      </Typography>
      <Grid item xs={12}>
        <Box sx={{ fontWeight: 'bold' }}>{t('orders:your-reference')}</Box>
        <Box sx={{ marginBottom: '5px' }}>{offer.customerReference ?? t('common:unknown')}</Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ fontWeight: 'bold' }}>{t('orders:peinemann-reference')}</Box>
        <Box sx={{ marginBottom: '5px' }}>{offer.clusterIdentifier}</Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ fontWeight: 'bold' }}>{t('common:order-date')}</Box>
        {formatDateOnly(offer.offerDate)}
      </Grid>
    </>
  );
}
