import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import PageTitle from '../../components/layout/PageTitle';
import TextFieldShrink from '../../components/layout/TextFieldShrink';
import { useKeycloak } from '../../hooks/useKeycloak';
import * as Yup from 'yup';
import { useCreateUser, useGetUserById, useUpdateUser } from '../../hooks/api/useUsersList';
import { usePopup } from '../../hooks/usePopupMessage';
import { UserSideMenu } from '../../components/users/UserSideMenu';
import { useEffect, useState } from 'react';
import { RouteParams } from '../../components/layout/navigation/Breadcrumb';
import { useOrganizationSearch } from '../../hooks/api/useOrganizations';
import { OrganizationModel, OrganizationService } from '../../services/OrganizationService';
import Loader from '../../components/common/Loader';
import { useDebounce } from '../../utils/debounce';
import { useTranslation } from 'react-i18next';
import { userRole } from '../../utils/const/roles';
import { UserPostModel } from '../../services/models/UserModel';
import { useApi } from '../../hooks/api/useApi';
import { CircularProgress } from '@material-ui/core';

export default function UserFormPage() {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation(['settings', 'validation', 'common']);

  const { isAdmin, isPrimaryUser, token, relationNumber } = useKeycloak();
  const { setMessage } = usePopup();

  const userId: string = token.parsed?.user_id || '';

  const initUser = {
    userName: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    relationIds: [],
    role: userRole.user,
    enabled: true
  };

  const [search, setSearch] = useState<string>('');
  const [user, setUser] = useState<UserPostModel>(initUser);
  const [organization, setOrganization] = useState<OrganizationModel[]>([]);
  const [organisationsLoaded, setOrganisationsLoaded] = useState<boolean>(false);

  const { data: organizatons, isFetching } = useOrganizationSearch(search);
  const autoCompleteOptions = organizatons?.result.items || [];

  const service = useApi<OrganizationService>(new OrganizationService());

  const userUpdateService = useUpdateUser();

  const { createUser } = useCreateUser();
  const { fetchUser, loading: userLoading, user: userData } = useGetUserById(id ?? userId);

  useEffect(() => {
    fetchUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: move to schema + merge with ProfileFormPage schema
  const userSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, `${t('validation:minimum', { name: 'Voornaam' })}`)
      .max(50, `${t('validation:maximum', { name: 'Voornaam' })}`)
      .required(`${t('validation:required', { name: 'Voornaam' })}`),
    lastName: Yup.string()
      .min(2, `${t('validation:minimum', { name: 'Achternaam' })}`)
      .max(50, `${t('validation:maximum', { name: 'Achternaam' })}`)
      .required(`${t('validation:required', { name: 'Achternaam' })}`),
    emailAddress: Yup.string()
      .email(`${t('validation:invalid-email')}`)
      .required(`${t('validation:required', { name: 'Email' })}`),
    role: Yup.string().required(),
    relationIds: Yup.array().min(1, `${t('validation:no-organisation')}`),
    enabled: Yup.boolean()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema: userSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (id !== undefined) {
        if (targetIsAdmin && !isAdmin()) {
          setMessage({
            message: 'Je hebt geen toestemming om deze gebruiker te mogen wijzigen.',
            type: 'error',
            open: true
          });
          setSubmitting(false);
          return;
        }

        updateUser({ ...(userData), ...values }, setSubmitting);
      } else {
        doCreateUser(values, setSubmitting);
      }
    }
  });

  const history = useHistory();
  const navigateBack = () => {
    history.push('/instellingen/gebruikers');
  };

  const targetIsAdmin = user.role === userRole.admin;
  const targetIsAM = user.role === userRole.accountManager;

  const isDisabled = (): boolean => {
    return (formik.isSubmitting || userLoading);
  };

  const isRoleDisabled = (): boolean => {
    return (isAdmin() && id === token.parsed?.user_id)
      || isDisabled()
      || (targetIsAdmin && !isAdmin())
      || (targetIsAM && isPrimaryUser());
  };

  useEffect(() => {
    if (!isAdmin()) {
      formik.setFieldValue('relationIds', [
        relationNumber
      ]);
    }
  }, [relationNumber]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!id) return;

    setUser({
      ...user,
      ...userData,
      role: userData?.role || userRole.user,
      relationIds: [
        userData?.relationIds?.find(
          (string) => string !== undefined
        ) || userRole.user
      ]
    });
  }, [userData, userLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const ids = userData?.relationIds;

    if (ids && ids?.length > 0 && !organisationsLoaded) {
      service.getMultipleOrganizationsByRelationIds({ relationIds: ids })
        .then((res) => {
          setOrganization(res.result);
          setOrganisationsLoaded(true);
        })
        .catch(() => {
          setMessage({
            message: `${t('settings:edit-user.messages.relations-fail')}`,
            type: 'error',
            open: true
          });
        });
    }
  }, [userData?.relationIds]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCompletedSave = (mail: string) => {
    navigateBack();
    setMessage({
      message: `Gebruiker ${mail} is aangepast`,
      type: 'success',
      open: true
    });
  };

  const doCreateUser = (values: UserPostModel, setSubmitting: (submitting: boolean) => void) => {
    createUser(values).then(() => {
      handleCompletedSave(values.emailAddress);
    }, () =>  {
      setSubmitting(false);
      setMessage({
        message: 'Er ging iets mis tijdens het opslaan van de gebruiker',
        type: 'error',
        open: true
      });
    });
  };

  const updateUser = (values: UserPostModel, setSubmitting: (submitting: boolean) => void) => {
    userUpdateService.mutate(values, {
      onSuccess: (newUser) => {
        handleCompletedSave(newUser.result.emailAddress);
      },
      onError: () => {
        setSubmitting(false);
        setMessage({
          message: 'Er ging iets mis tijdens het opslaan van de gebruiker',
          type: 'error',
          open: true
        });
      }
    });
  };

  const onChangeOrganisationInputValue = useDebounce(
    (newValue: string) => setSearch(newValue),
    300
  );

  return (
    <>
      <PageTitle title={`${t('title')}`} />
      <Box
        sx={{
          display: 'flex',
          gap: 5
        }}
      >
        <UserSideMenu />
        <Card sx={{ flex: 1 }}>
          <CardContent>
            {id !== undefined && (
              <Typography variant="subtitle1">{t('edit-user.label-edit')}</Typography>
            )}
            {id === undefined && (
              <Typography variant="subtitle1">{t('edit-user.label-new')}</Typography>
            )}
            {userLoading ? (
              <Loader />
            ) : (
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                sx={{ paddingTop: 2.5 }}
              >
                <Grid item xs={12} md={6}>
                  <TextFieldShrink
                    required
                    fullWidth
                    error={
                      formik.errors.firstName !== undefined &&
                      formik.touched.firstName
                    }
                    helperText={formik.errors.firstName}
                    onChange={formik.handleChange}
                    label={t('form.first-name')}
                    id="firstName"
                    name="firstName"
                    placeholder={`${t('form.first-name')}`}
                    value={formik.values.firstName}
                    disabled={isDisabled()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldShrink
                    required
                    fullWidth
                    error={
                      formik.errors.lastName !== undefined &&
                      formik.touched.lastName
                    }
                    helperText={formik.errors.lastName}
                    onChange={formik.handleChange}
                    label={t('form.last-name')}
                    id="lastName"
                    name="lastName"
                    placeholder={`${t('form.last-name')}`}
                    value={formik.values.lastName}
                    disabled={isDisabled()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldShrink
                    required
                    fullWidth
                    error={
                      formik.errors.emailAddress !== undefined &&
                      formik.touched.emailAddress
                    }
                    helperText={formik.errors.emailAddress}
                    onChange={formik.handleChange}
                    label={t('form.email')}
                    id="emailAddress"
                    name="emailAddress"
                    type="email"
                    placeholder={`${t('form.email')}`}
                    value={formik.values.emailAddress}
                    disabled={isDisabled() || id !== undefined}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('form.role.label')}
                    id="role"
                    name="role"
                    select
                    defaultValue={userRole.user}
                    value={formik.values.role}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('role', e.target.value);
                    }}
                    fullWidth
                    disabled={isRoleDisabled()}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  >
                    <MenuItem value={userRole.user}>{t('form.role.user')}</MenuItem>
                    {isPrimaryUser() && <MenuItem
                      value={userRole.primaryUser}>{t('form.role.primary_user')}</MenuItem>}
                    {isAdmin() && <MenuItem
                      value={userRole.accountManager}>{t('form.role.account_manager')}</MenuItem>}
                    {isAdmin() &&
                      <MenuItem value={userRole.admin}>{t('form.role.admin')}</MenuItem>}
                  </TextField>
                </Grid>
                {isAdmin() && !targetIsAdmin && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        fullWidth
                        multiple
                        id="organisation-multi"
                        value={organization}
                        options={autoCompleteOptions}
                        disabled={!organisationsLoaded || isDisabled()}
                        onChange={(_, org) => {
                          if (org.length > 0) {
                            formik.setFieldValue('relationIds', org.map(x => x?.relationNumber));
                          } else {
                            formik.setFieldValue('relationIds', []);
                          }
                          setOrganization(org);
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option?.id}>
                              {`${option?.name}`}
                            </li>
                          );
                        }}
                        onInputChange={(_, newInputValue) => {
                          onChangeOrganisationInputValue(newInputValue);
                        }}
                        loading={isFetching}
                        loadingText={`${t('common:loading')}`}
                        getOptionLabel={(option) => option?.name || ''}
                        placeholder={`${t('form.organisation')}`}
                        renderInput={(params) => (
                          <TextFieldShrink
                            {...params}
                            error={
                              formik.errors.relationIds !== undefined &&
                              formik.touched.relationIds
                            }
                            placeholder={`${t('form.organisation')}`}
                            helperText={formik.errors.relationIds}
                            disabled={formik.isSubmitting}
                            label={`${t('form.organisation')}`}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {!organisationsLoaded &&
                        <CircularProgress style={{ display: 'inline-block', marginTop: 8 }} />}
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!organisationsLoaded && isDisabled()}
                    onClick={formik.submitForm}
                  >
                    {t('common:button.save')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
