import * as yup from 'yup';
import { SiteModel } from '../../../../services/SiteService';

// @ts-ignore
const siteSchema = yup.object().shape<SiteModel>({
  city: yup
    .string()
    .required('Een stad is verplicht'),
  postalCode: yup
    .string()
    .required('Een geldige postcode is verplicht (0000 AA)')
    .matches(
      /^[1-9][0-9]{3} (?!sa|sd|ss)[A-Z]{2}$/i,
      'Een geldige postcode is verplicht (0000 AA)'
    ),
  street: yup.string().required('Een straatnaam is verplicht'),
  houseNumber: yup.string().required('Een huisnummer is verplicht')
});

export default siteSchema;
