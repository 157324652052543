import { ArrowForward } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { OfferModel, OfferType } from '../../services/models/Offer';
import { colors } from '../../theme';
import { formatDateOnly } from '../../utils/dateFormat';
import Status from '../layout/Status';

type DashboardRowProps = {
  offer: OfferModel;
};

const resetPadding = { paddingTop: '0 !important' };

export default function DashboardRow({ offer }: DashboardRowProps) {
  const history = useHistory();

  const createUrl = (clusterId?: string): string => {
    if (!clusterId) return '/';

    if (offer.offerType === OfferType.Regular){
      return `/huuropdrachten/${clusterId}`;
    } else {
      return `/werkopnames/${clusterId}`;
    }
  };

  return (
    <Box
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        history.push(createUrl(offer.clusterIdentifier));
      }}
    >
      <Divider />
      <Grid container spacing={1} sx={{ marginY: 1 }}>
        <Grid item xs={7} sx={resetPadding}>
          {offer.site && (
            <>
              <Typography sx={{ fontWeight: 'bold' }}>
                {offer.site.street} {offer.site.houseNumber} {offer.site.houseNumberAddition}
              </Typography>
              <Typography>
                {offer.site.postalCode} {offer.site.city}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={4} sx={resetPadding}>
          <Status status={offer.status} />
          <Typography sx={{ marginLeft: 1.75 }}>{formatDateOnly(offer.offerDate)}</Typography>
        </Grid>
        <Grid item xs={1} sx={resetPadding}>
          <IconButton
            size="small"
            onClick={() => {
              history.push(createUrl(offer.clusterIdentifier));
            }}
          >
            <ArrowForward sx={{ color: colors.blue }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
