import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useCallback, useMemo, useState } from 'react';
import Geocode from 'react-geocode';
import debounce from 'lodash/debounce';
import { MapsApiKey } from '../../utils/const/googleApi';

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: 250
};

interface LatLang {
  lat: number;
  lng: number;
}

interface MapProps {
  address: string;
  postalCode: string;
  city: string;
}

export default function Map({ address, postalCode, city }: MapProps) {
  const [marker, setMarker] = useState<LatLang>();

  const debounced = useCallback(
    debounce(callback => {
      callback();
    }, 400),
    []
  );

  useMemo(() => {
    if (address !== '' && postalCode !== '' && city !== '') {
      debounced(() => {
        Geocode.setApiKey(MapsApiKey);
        Geocode.fromAddress(address + ' ' + postalCode + ' ' + city).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            setMarker({
              lat: lat,
              lng: lng
            });
          }, () => {
            setMarker(undefined);
          }
        );
      });
    } else {
      setMarker(undefined);
    }
  }, [address, postalCode, city]);

  const getCenter = (): LatLang => {
    return marker ? marker : {
      lat: 52.33227569495236,
      lng: 5.5257854610824
    };
  };

  const getZoom = (): number => {
    return marker ? 18 : 8;
  };

  return (
    <LoadScript googleMapsApiKey={MapsApiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={getCenter()}
        zoom={getZoom()}
        options={{
          disableDefaultUI: true,
          draggable: false,
          zoomControl: false,
          scrollwheel: false,
          disableDoubleClickZoom: true
        }}
      >
        {marker ? <Marker position={marker} /> : ''}
      </GoogleMap>
    </LoadScript>
  );
}
