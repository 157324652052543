import { Api, ApiResponse, PagedData } from './Api';
import { ClusterModel, OrderModel, OfferModel } from './models/Offer';

export interface OfferFilterParams {
  pageSize?: number;
  offerType?: string;
  search?: string;
  offerStatus?: string[];
  isActive?: boolean;
}

export class OfferService extends Api {
  getOffers = async (params?: OfferFilterParams) => {
    return this.get<ApiResponse<PagedData<ClusterModel>>>(
      '/api/offers', {},
      params
    );
  };

  getOfferByClusterId = async (id: string) => {
    return this.get<ApiResponse<ClusterModel>>(
      `/api/offers/${id}`
    );
  };

  getOrderByOfferId = async (offerId: string) => {
    return this.get<ApiResponse<OrderModel>>(
      `/api/offers/${offerId}/order`
    );
  };

  createOffer = async (offer: OfferModel) => {
    // Exists in backend but currently unused in frontend
    // Creates an offer without a dummy order
    return this.post<ApiResponse<OfferModel>>(
      '/api/offers',
      offer
    );
  };

  createJobOffer = async (offer: OfferModel) => {
    return this.post<ApiResponse<OfferModel>>(
      '/api/offers/job',
      offer
    );
  };

  updateOffer = async (offer: OfferModel) => {
    return this.put<ApiResponse<OfferModel>>(
      `api/offers/${offer.id}`,
      offer
    );
  };

  bookOutOrder = async (offerId: string, orderId: string, date: Date) => {
    return this.put<ApiResponse<OrderModel>>(
      `/api/offers/${offerId}/bookout/${orderId}`,
      { date: date }
    );
  };

  confirmOffer = async(clusterId: string) => {
    return this.put<ApiResponse<void>>(
      `/api/offers/${clusterId}/confirm`, clusterId
    )
  }

  cancelOffer = async (data: { id: string, cancelReason: string }[]) => {
    return this.put<ApiResponse<OfferModel[]>>(
      `/api/offers/cancel`, data
    );
  };

  deleteOfferWithOrder = async (offerId: string) => {
    return this.delete<ApiResponse<void>>(
      `/api/offers/${offerId}/order`
    )
  }
}
