import { useQuery } from 'react-query';
import { OrganizationService } from '../../services/OrganizationService';
import { useKeycloak } from '../useKeycloak';
import { useApi } from './useApi';

export function useOrganizationSearch(searchKey: string) {
  const { isAdmin } = useKeycloak();

  const service = useApi<OrganizationService>(new OrganizationService());
  const options = { query: { keepPreviousData: true } };
  const queryKey = 'organizations';

  const query = useQuery({ queryKey: queryKey, queryFn: async () => {
    if (!isAdmin()) return;
    return await service.getOrganizationsByParams({ name: searchKey });
  }, ...options });

  return {
    queryKey,
    ...query
  };
}

export function useGetOrganizationById(id: string) {
  const service = useApi<OrganizationService>(new OrganizationService());
  const options = { query: { keepPreviousData: true } };
  const queryKey = ['organization', id];
  const query = useQuery({ queryKey: queryKey, queryFn: async () => {
    return await service.getOrganizationById(id);
  }, ...options });

  return {
    queryKey,
    ...query
  };
}

export function useGetOrganizationByRelationId(id: string) {
  const service = useApi<OrganizationService>(new OrganizationService());
  const queryKey = ['organizationRel', id];
  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      return await service.getOrganizationByRelationId(id);
    },
    enabled: !!id,
    keepPreviousData: true
  });

  return {
    queryKey,
    ...query
  };
}

export function useGetMultipleByRelationIds(ids: string[]) {
  const service = useApi<OrganizationService>(new OrganizationService());
  const queryKey = 'multiple-organisations'

  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      return await service.getMultipleOrganizationsByRelationIds({
        relationIds: ids
      });
    },
    enabled: !!ids
  });

  return {
    queryKey,
    ...query
  };
}
