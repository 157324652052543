import { ChangeEvent, useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import {
  CardContent,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import PageTitle from '../../components/layout/PageTitle';
import TextFieldShrink from '../../components/layout/TextFieldShrink';
import { useKeycloak } from '../../hooks/useKeycloak';
import { OfferModel, TypeOfWork } from '../../services/models/Offer';
import { useFormik } from 'formik';
import { SiteModel } from '../../services/SiteService';
import { useCreateSite } from '../../hooks/api/useSites';
import { useCreateJobOffer } from '../../hooks/api/useOffers';
import { usePopup } from '../../hooks/usePopupMessage';
import { useHistory } from 'react-router-dom';
import { generateClusterIdHash } from '../../utils/clusterIdHash';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ApiResponse } from '../../services/Api';
import { formatSite } from '../../utils/siteFormatter';
import HelpAlert from '../../components/common/HelpAlert';
import { useJobSurvey } from '../../hooks/useJobSurvey';
import SearchAddressDropdown from '../../components/common/SearchAddressDropdown';
import { initSite } from '../../hooks/useCart';
import Map from '../../components/layout/Map';
import { LoadingButton } from '@mui/lab';

export interface JobSurveyForm {
  site: SiteModel;
  offer: OfferModel;
}

export default function JobSurveyPage() {
  const { t } = useTranslation(['common', 'checkout', 'orders', 'routes']);
  const { setMessage } = usePopup();
  const history = useHistory();

  const theme = useTheme();
  const screenIsMd = useMediaQuery(theme.breakpoints.up('md'));

  const { relationNumber } = useKeycloak();
  const { initForm, jobSurveySchema, searchByPostalCode } = useJobSurvey();

  const offerService = useCreateJobOffer();
  const siteService = useCreateSite();

  const [typeOfWork, setTypeOfWork] = useState<TypeOfWork | string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [hash, setHash] = useState<string>('');

  const [addWorkAddress, setAddWorkAddress] = useState(false);
  const [addDatePeriod, setAddDatePeriod] = useState(false);

  const cleanPostalCodeField = (value?: string) => {
    if (!value) return;

    if (value.length === 6) {
      const formattedString = `${value.substring(0, 4)} ${value.substring(4)}`;

      formik.setFieldValue('site.postalCode', formattedString);
      searchByPostalCode(formik, formattedString, formik.values.site.houseNumber);
    }
  }

  const handlePostalCodeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    searchByPostalCode(formik, e.target.value, formik.values.site.houseNumber);
  };

  const handleHouseNumberChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    searchByPostalCode(formik, formik.values.site.postalCode, e.target.value);
  };

  useEffect(() => {
    if (hash === '') {
      generateClusterIdHash(relationNumber + String(Date.now())).then(
        (clusterId) => {
          setHash(clusterId);
        }
      );
    }
  });

  const saveJobSurvey = (
    values: JobSurveyForm,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    values.site = formatSite(values.site);
    offerService.mutate(
      { ...values.offer, clusterIdentifier: hash },
      {
        onSuccess: () => {
          setMessage({
            message: t('orders:job-survey-request-successful', { reference: hash }),
            type: 'success',
            open: true
          });
          history.push('/werkopnames');
        },
        onError: () => {
          setSubmitting(false);
          setMessage({
            message: t('orders:job-survey-request-failed'),
            type: 'error',
            open: true
          });
        }
      }
    );
  };

  const formik = useFormik({
    initialValues: initForm,
    validationSchema: jobSurveySchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      values.offer.relationNumber = relationNumber;

      if (values.site.id === undefined || values.site.id === '' || values.site.id === '-1') {
        values.site.name = `${values.site.street} ${values.site.city}`;
        siteService.mutate(values.site,
          {
            onSuccess: (response: ApiResponse<SiteModel>) => {
              values.offer.siteNumber = response.result.siteNumber;
              saveJobSurvey(values, setSubmitting);
            },
            onError: () => {
              setSubmitting(false);
              setMessage({
                message: t('orders:job-survey-request-failed'),
                type: 'error',
                open: true
              });
            }
          }
        );
      } else {
        saveJobSurvey(values, setSubmitting);
      }
    }
  });

  const handleTypeOfWorkChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.values.offer.typeOfWork = event.target.value as TypeOfWork;
    setTypeOfWork(event.target.value as TypeOfWork);

    if (event.target.value !== TypeOfWork.Onbekend) {
      formik.setFieldValue('offer.typeOfWorkDescription', '');
    }
  };

  return (
    <>
      <PageTitle title={t('routes:new-job-survey') ?? ''} />
      <Box
        sx={{
          display: 'flex',
          gap: 5
        }}
      >
        <Card sx={{ flex: 1 }}>
          <CardContent>
            <HelpAlert
              sx={{ marginTop: 1 }}
              translation={{ key: 'job-survey-info' }}
            />
            <Box component='form' onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item container xs={12} md={6} spacing={2} mt={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant='subtitle2'>{t('orders:job-survey-work-address')}</Typography>
                    <SearchAddressDropdown value={formik.values.site}
                                           disabled={formik.isSubmitting}
                                           onAddressSelect={(e, site) => {
                                             formik.setValues({
                                               offer: {
                                                 ...formik.values.offer,
                                                 siteNumber: site?.siteNumber
                                               },
                                               site: { ...initSite, ...site }
                                             });
                                           }} />
                    {(formik.errors.site?.street || formik.errors.site?.houseNumber || formik.errors.site?.postalCode || formik.errors.site?.city) &&
                      <FormHelperText
                        error={true}>{t('orders:validation.work-address-mandatory')}</FormHelperText>}
                    <div style={{ marginTop: '12px' }} />
                    <FormControlLabel control={<Checkbox
                      onChange={(event) => setAddWorkAddress(event.target.checked)}
                      checked={addWorkAddress} />} label={t('orders:add-work-address')} />
                  </Grid>
                  {addWorkAddress && <>
                    <Grid item xs={12} md={6}>
                      <TextFieldShrink
                        fullWidth
                        label={t('common:labels.postalcode') ?? ''}
                        name='site.postalCode'
                        value={formik.values.site.postalCode}
                        error={
                          formik.errors.site?.postalCode !== undefined &&
                          formik.touched.site?.postalCode
                        }
                        helperText={formik.errors.site?.postalCode}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handlePostalCodeChange(e);
                        }}
                        onBlur={() => cleanPostalCodeField(formik.values.site.postalCode)}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextFieldShrink
                        fullWidth
                        label={t('common:labels.housenumber') ?? ''}
                        name='site.houseNumber'
                        value={formik.values.site.houseNumber}
                        error={
                          formik.errors.site?.houseNumber !== undefined &&
                          formik.touched.site?.houseNumber
                        }
                        helperText={formik.errors.site?.houseNumber}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleHouseNumberChange(e);
                        }}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextFieldShrink
                        fullWidth
                        label={t('common:labels.addition') ?? ''}
                        name='site.houseNumberAddition'
                        value={formik.values.site.houseNumberAddition}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldShrink
                        fullWidth
                        label={t('common:labels.streetname') ?? ''}
                        name='site.street'
                        value={formik.values.site.street}
                        error={
                          formik.errors.site?.street !== undefined &&
                          formik.touched.site?.street
                        }
                        helperText={formik.errors.site?.street}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextFieldShrink
                        fullWidth
                        label={t('common:labels.city') ?? ''}
                        name='site.city'
                        error={
                          formik.errors.site?.city !== undefined &&
                          formik.touched.site?.city
                        }
                        helperText={formik.errors.site?.city}
                        value={formik.values.site.city}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        disabled={true}
                      />
                    </Grid>
                  </>}
                </Grid>
                <Grid item xs={12} md={6} mt={4} sx={{ marginTop: screenIsMd ? '67px' : '0px' }}>
                  <Map
                    address={`${formik.values.site?.street} ${formik.values.site?.houseNumber}`}
                    postalCode={formik.values.site?.postalCode}
                    city={formik.values.site?.city}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldShrink
                    fullWidth
                    label={t('orders:your-reference') ?? ''}
                    name='offer.customerReference'
                    value={formik.values.offer.customerReference}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    select
                    label={t('orders:offer-typeof-work') ?? ''}
                    id='select-typeofwork'
                    value={typeOfWork}
                    defaultValue={TypeOfWork.Onbekend}
                    onChange={handleTypeOfWorkChange}
                    InputLabelProps={{ shrink: true }}
                    helperText={formik.errors.offer?.typeOfWork}
                  >
                    <MenuItem
                      value={TypeOfWork.Onbekend}>{t('orders:type-of-work-enum.unknown')}</MenuItem>
                    <MenuItem
                      value={TypeOfWork.Inspectie}>{t('orders:type-of-work-enum.inspection')}</MenuItem>
                    <MenuItem value={TypeOfWork.Snoeiwerkzaamheden}>
                      {t('orders:type-of-work-enum.trimming')}
                    </MenuItem>
                    <MenuItem value={TypeOfWork.Schilderwerkzaamheden}>
                      {t('orders:type-of-work-enum.painting')}
                    </MenuItem>
                    <MenuItem value={TypeOfWork.Asbestsanering}>
                      {t('orders:type-of-work-enum.asbestos')}
                    </MenuItem>
                    <MenuItem
                      value={TypeOfWork.Overig}>{t('orders:type-of-work-enum.other')}</MenuItem>
                  </TextField>
                </Grid>
                {typeOfWork === TypeOfWork.Onbekend && <Grid item xs={12} md={3}>
                  <TextFieldShrink
                    fullWidth
                    label={t('orders:offer-typeof-work-description') ?? ''}
                    name='offer.typeOfWorkDescription'
                    value={formik.values.offer.typeOfWorkDescription}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </Grid>}
                <Grid item xs={12} md={12}>
                  <TextFieldShrink
                    fullWidth
                    multiline
                    placeholder={t('orders:job-survey-description-placeholder') ?? ''}
                    minRows={4}
                    error={
                      formik.errors.offer?.comments !== undefined &&
                      formik.touched.offer?.comments
                    }
                    helperText={formik.errors.offer?.comments}
                    label={t('orders:job-survey-description')}
                    name='offer.comments'
                    value={formik.values.offer.comments}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                        <Checkbox
                          onChange={(event) => setAddDatePeriod(event.target.checked)}
                          checked={addDatePeriod}
                        />
                      }
                    label={t('orders:date-period-known')}
                  />
                </Grid>
                { addDatePeriod &&
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label={t('common:labels.expected-start-date')}
                      renderInput={(props) => (
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          {...props}
                        />
                      )}
                      onChange={(date) => {
                        setStartDate(date);
                        formik.values.offer.startDate = date?.toISOString();
                      }}
                      inputFormat='dd/MM/yyyy'
                      value={startDate}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      label={t('common:labels.expected-end-date')}
                      renderInput={(props) => (
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          {...props}
                        />
                      )}

                      onChange={(date) => {
                        setEndDate(date);
                        formik.values.offer.endDate = date?.toISOString();
                      }}
                      inputFormat='dd/MM/yyyy'
                      value={endDate}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                </Grid>
                }
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={formik.isSubmitting}
                  >
                    {t('orders:request-job-survey')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
