import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../theme';
import { CheckoutStepType } from './index';

interface StepperProps {
  currentStep: CheckoutStepType;
}

export default function CheckoutStepper({ currentStep }: StepperProps) {
  const { t } = useTranslation('checkout');

  const steps = Object.keys(CheckoutStepType).filter((step) => isNaN(Number(step)));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        margin: 0.5,
        flexShrink: 0,
        bgcolor: colors.stepperBackground,
        fontSize: 14,
        alignItems: 'center',
        borderRadius: 3,
        whiteSpace: 'nowrap'
      }}
    >
      {steps.map((item) =>
        (
          <Box key={`step-${item}`} sx={{ ...stepStyle(currentStep, item) }}>
            {t('step', { context: item })}
          </Box>
        )
      )}
    </Box>
  );
}

const stepStyle = (currentStep: CheckoutStepType, item: string) => {
  const step = CheckoutStepType[item as keyof typeof CheckoutStepType];
  const style = {
    display: 'flex',
    flex: 1,
    padding: 2,
    height: 50,
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  if (step < currentStep) {
    return {
      ...style,
      borderRadius: step === 0 ? '10px 0 0 10px' : '',
      bgcolor: colors.white,
      color: colors.blue
    };
  } else if (step === currentStep) {
    return {
      ...style,
      borderRadius: step === 0 ? '10px' : '0 10px 10px 0',
      bgcolor: colors.white,
      color: colors.orange
    };
  } else {
    return style;
  }
};
