import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OfferStatus, OfferType, OfferModel } from '../../services/models/Offer';
import { OfferService } from '../../services/OfferService';
import { useQueryParams } from '../queryParams/useOfferFilters';
import { useApi } from './useApi';

const offerStatuses = [OfferStatus.Approved, OfferStatus.Assignment, OfferStatus.Confirmed, OfferStatus.DefinitivelyPrinted];
const jobSurveyStatuses = [OfferStatus.New, OfferStatus.Modified, OfferStatus.Ready, OfferStatus.Approved, OfferStatus.Assignment, OfferStatus.Confirmed, OfferStatus.DefinitivelyPrinted, OfferStatus.Terminated];

export function useOfferTypeList(type: OfferType) {
  const service = useApi<OfferService>(new OfferService());
  const options = {
    retry: (failureCount: number, error: any) => (failureCount <= 3 && error.statusCode),
    query: { keepPreviousData: true }
  };

  const query = useQuery({
    queryKey: ['offers', { offerType: type }],
    queryFn: async () => {
      return await service.getOffers({
        offerType: type,
        offerStatus: type === OfferType.JobSurvey ? jobSurveyStatuses : offerStatuses,
        pageSize: 3
      });
    },
    ...options
  });

  return {
    ...query
  };
}

export function useClusteredOfferFilteredList() {
  const service = useApi<OfferService>(new OfferService());
  const { params } = useQueryParams();

  const options = { query: { keepPreviousData: true } };
  const query = useQuery({
    queryKey: ['offers', params],
    queryFn: async () => {
      return await service.getOffers({
        offerType: '01',
        offerStatus: offerStatuses,
        ...params
      });
    },
    ...options
  });

  return {
    ...query
  };
}

export function useJobSurveyFilteredList() {
  const service = useApi<OfferService>(new OfferService());
  const { params } = useQueryParams();

  const options = { query: { keepPreviousData: true } };
  const query = useQuery({
    queryKey: ['job-surveys', params],
    queryFn: async () => {
      return await service.getOffers({
        offerType: '02',
        ...params,
        offerStatus: jobSurveyStatuses
      });
    },
    ...options
  });

  return {
    ...query
  };
}

export function useGetOfferByClusterId(id: string) {
  const service = useApi<OfferService>(new OfferService());
  const options = { query: { keepPreviousData: false } };
  const queryKey = ['offer', id];

  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      return await service.getOfferByClusterId(id);
    },
    ...options
  });

  return {
    queryKey,
    query
  };
}

export function useGetOfferByClusterIdLazy(id: string) {
  const service = useApi<OfferService>(new OfferService());
  const options = { query: { keepPreviousData: false } };
  const queryKey = ['offer-lazy', id];

  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      return await service.getOfferByClusterId(id);
    },
    enabled: !!id,
    ...options
  });

  return {
    queryKey,
    query
  };
}

export function useGetOrderByOfferId(offerId: string) {
  const service = useApi<OfferService>(new OfferService());
  const options = { query: { keepPreviousData: false } };
  const queryKey = ['order-offernumber', offerId];
  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      return await service.getOrderByOfferId(offerId);
    },
    ...options
  });

  return {
    queryKey,
    ...query
  };
}

export function useCreateJobOffer() {
  const service = useApi<OfferService>(new OfferService());
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries('offers');
      queryClient.invalidateQueries(['offers', { offerType: '01' }]);
      queryClient.invalidateQueries(['offers', { offerType: '02' }]);
    }
  };
  return useMutation((data: OfferModel) => service.createJobOffer(data), options);
}

// Unused
export function useUpdateOffer() {
  const service = useApi<OfferService>(new OfferService());
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries('offers');
      queryClient.invalidateQueries(['offers', { offerType: '01' }]);
      queryClient.invalidateQueries(['offers', { offerType: '02' }]);
    }
  };

  return useMutation((data: OfferModel) => service.updateOffer(data), options);
}
