import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import CheckoutTable from '../components/cart/checkout/CheckoutTable';
import LocationCheckout from '../components/cart/checkout/LocationCheckout';
import PlanningCheckout from '../components/cart/checkout/PlanningCheckout';
import VerifyCheckout from '../components/cart/checkout/VerifyCheckout';
import PageTitle from '../components/layout/PageTitle';
import CheckoutStepper from '../components/cart/checkout/CheckoutStepper';
import { CheckoutStepType } from '../components/cart/checkout';

export default function CheckoutPage() {
  const [step, setStep] = useState<CheckoutStepType>(CheckoutStepType.CART);
  return (
    <>
      <PageTitle>
        <CheckoutStepper currentStep={step}/>
      </PageTitle>
      <Box sx={{
        display: 'flex',
        gap: 5,
        flexDirection: 'column',
        marginBottom: 3,
      }}>
        <CheckoutStep step={step} setStep={setStep} />
      </Box>
    </>
  );
}

interface Props {
  step: CheckoutStepType;
  setStep: Dispatch<SetStateAction<CheckoutStepType>>;
}

const CheckoutStep = ({ step, setStep }: Props) => {
  switch (step) {
    case CheckoutStepType.LOCATION:
      return <LocationCheckout
        onNext={() => setStep(CheckoutStepType.PLANNING)}
        onPrevious={() => setStep(CheckoutStepType.CART)}
      />;
    case CheckoutStepType.PLANNING:
      return <PlanningCheckout
        onNext={() => setStep(CheckoutStepType.VERIFICATION)}
        onPrevious={() => setStep(CheckoutStepType.LOCATION)}
      />;
    case CheckoutStepType.VERIFICATION:
      return <VerifyCheckout
        onPrevious={() => setStep(CheckoutStepType.PLANNING)}
      />;
    case CheckoutStepType.CART:
    default:
      return <CheckoutTable onNext={() => setStep(CheckoutStepType.LOCATION)} />;
  }
}
