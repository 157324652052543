import { Button, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoginLayout from '../components/layout/LoginLayout';
import { useResetPassword } from '../hooks/api/useUsersList';
import * as yup from 'yup';
import { usePopup } from '../hooks/usePopupMessage';
import TextFieldShrink from '../components/layout/TextFieldShrink';
import { useTranslation } from 'react-i18next';

export default function PasswordForgottenPage() {
  const forgotPasswordMutation = useResetPassword();
  const [mail, setMail] = useState<string>('');
  const [validation, setValidation] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { setMessage } = usePopup();

  const history = useHistory();

  const { t } = useTranslation(['authentication', 'validation', 'common']);

  // TODO: move to schema
  const emailSchema = yup.object().shape({
    emailAddress: yup.string()
      .required(`${t('validation:required', { name: 'Email' })}`)
      .email(`${t('validation:invalid-email')}`)
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = { emailAddress: mail };

    emailSchema.validate(data).then(() => {
      forgotPasswordMutation.mutate(
        data, {
          onSuccess: () => {
            setMessage({
              message: `${t('password-success')}`,
              type: 'success',
              open: true
            });
            history.push('/login');
          },
          onError: () => {
            setMessage({
              message: `${t('password-error')}`,
              type: 'error',
              open: true
            });
          }
        }
      );
    }).catch((err)=>{
      setError(err.errors[0]);
      setValidation(true);
    });
  };

  return (
    <LoginLayout>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onBlur={()=> {
          setValidation(false);
          setError('');
        }}
        onSubmit={onSubmit}
        sx={{
          marginX: { xs: 2, sm: 10 }
        }}
      >
        <Stack
          gap={2}
          sx={{
            marginTop: 5,
            minWidth: { xs: '300px', sm: '340px' },
            width: { xs: '100%', sm: 'unset' }
          }}
        >
          <Typography variant="h2" sx={{ textAlign: 'center', marginBottom: 2 }}>
            {t('forgot-password')}
          </Typography>
          <TextFieldShrink
            fullWidth
            label="Email"
            onChange={(e) => setMail(e.target.value)}
            helperText={error}
            error={validation}
          />
          <Button fullWidth type="submit" variant="contained">
            {t('reset-password')}
          </Button>
          <Button fullWidth onClick={() => { history.push('/login'); }}>
            {t('common:button.return')}
          </Button>
        </Stack>
      </Box>
    </LoginLayout>
  );
}
