import { Grid, Typography } from '@mui/material';
import { currencyFormat } from '../../../utils/currencyFormat';
import { useTranslation } from 'react-i18next';
import { OfferModel, OfferType } from '../../../services/models/Offer';

interface OfferDetailTopTypeProps {
  offer: OfferModel;
}

export default function OfferDetailTopType({ offer }: OfferDetailTopTypeProps) {
  const { t } = useTranslation(['common']);

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          gutterBottom={false}
          sx={{ marginLeft: 0, marginTop: 2 }}
        >
          {t('common:contact-person:label')}
        </Typography>
        {`${offer.contactPerson?.name || t('common:unknown')}`}
        {offer.contactPerson?.phoneNumber ? ` (${offer.contactPerson?.phoneNumber})` : ''}
      </Grid>
      {(offer.additionalContactPerson?.name) && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            gutterBottom={false}
            sx={{ marginLeft: 0, marginTop: 2 }}
          >
            {t('common:contact-person:label-2')}
          </Typography>
          {`${offer.additionalContactPerson?.name ?? t('common:unknown')}`}
          {offer.additionalContactPerson?.phoneNumber ? ` (${offer.additionalContactPerson?.phoneNumber})` : ''}
        </Grid>
      )}

      {(offer.driver?.name) && (
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          gutterBottom={false}
          sx={{ marginLeft: 0, marginTop: 2 }}
        >
          {t('common:driver:label-driver')}
        </Typography>
        {`${offer.driver?.name || t('common:unknown')}`}
        {offer.driver?.phoneNumber ? ` (${offer.driver?.phoneNumber})` : ''}
        {offer.driver?.misc && <Typography>{offer.driver?.misc}</Typography>}
      </Grid>
      )}

      {offer.offerType !== OfferType.Regular && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            gutterBottom={false}
            sx={{ marginLeft: 0, marginTop: 2 }}
          >
            {t('common:typeof-work')}
          </Typography>
          {offer.typeOfWork}
        </Grid>
      )}

      {offer.offerType !== OfferType.Intake && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            gutterBottom={false}
            sx={{ marginLeft: 0, marginTop: 2 }}
          >
            {t('common:transport-costs')}
          </Typography>
          {!offer.transportCostFrom && !offer.transportCostTo && <>{t('common:unknown')}</>}
          {offer.transportCostFrom ?
            <Typography>
                  <span
                    style={{ fontWeight: 'bold' }}>{t('common:supply')}</span>: {currencyFormat(offer.transportCostFrom)}
            </Typography>
            : <></>}
          {offer.transportCostTo ?
            <Typography>
                  <span
                    style={{ fontWeight: 'bold' }}>{t('common:disposal')}</span>: {currencyFormat(offer.transportCostTo)}
            </Typography>
            : <></>}
        </Grid>
      )}
    </>
  );
}
