import { Box, Card } from '@mui/material';
import FilterSideBar from '../../components/layout/FilterSideBar';
import PageTitle from '../../components/layout/PageTitle';
import JobSurveyTable from '../../components/orders/JobSurveyTable';
import { QueryParamsProvider, useQueryParams } from '../../hooks/queryParams/useOfferFilters';
import { useTranslation } from 'react-i18next';
import SidebarSearchField from '../../components/layout/SidebarSearchField';

export default function JobSurveyListPage() {
  const { t } = useTranslation(['routes', 'orders']);

  return (
    <>
      <PageTitle title={t('routes:job-surveys') ?? ''} />
      <Box sx={{
        display: 'flex',
        gap: 5
      }}>
        <QueryParamsProvider>
          <JobSurveyListFilters />
          <Card sx={{ flex: 1, marginBottom: 3 }}>
            <JobSurveyTable />
          </Card>
        </ QueryParamsProvider>
      </Box>
    </>
  );
}

const JobSurveyListFilters = () => {
  const { t } = useTranslation('common');
  const { changeParam } = useQueryParams();

  return (
    <FilterSideBar>
      <SidebarSearchField
        name="search"
        changeParam={changeParam}
        placeholder={`${t('common:search-job-survey')}`}
      />
    </FilterSideBar>
  );
};
