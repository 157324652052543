import { IconButton, TableCell } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import { PropsWithChildren, useState, MouseEvent } from 'react';

export default function TableDropdownCell({ children }: PropsWithChildren<unknown>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | undefined>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(null);
  };

  return <TableCell>
    <IconButton size="small" onClick={handleClick}>
      <MoreHorizIcon />
    </IconButton>
    <Menu open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.16))',
              mt: 1
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {children}
    </Menu>
  </TableCell>;
}
