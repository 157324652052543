import { Box, Button, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import LoginLayout from '../components/layout/LoginLayout';
import TextFieldShrink from '../components/layout/TextFieldShrink';
import { useRegisterUser } from '../hooks/api/useUsersList';
import { usePopup } from '../hooks/usePopupMessage';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RegisterUserModel } from '../services/models/UserModel';
import { phoneRegExp } from '../components/cart/checkout/schema/contactSchema';

const config = window.env;

const initRegisterUser: RegisterUserModel = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  userName: '',
  phoneNumber: '',
  company: '',
  baseUrl: config.baseUrl
};

export default function RegisterPage() {
  const history = useHistory();
  const registerService = useRegisterUser();
  const { setMessage } = usePopup();

  const { t } = useTranslation(['authentication', 'validation', 'common']);

  // TODO: move to schema + merge with ProfileFormPage schema
  const registerSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, `${t('validation:minimum', { name: 'Voornaam' })}`)
      .max(50, `${t('validation:maximum', { name: 'Voornaam' })}`)
      .required(`${t('validation:required', { name: 'Voornaam' })}`),
    lastName: Yup.string()
      .min(2, `${t('validation:minimum', { name: 'Achternaam' })}`)
      .max(50, `${t('validation:maximum', { name: 'Achternaam' })}`)
      .required(`${t('validation:required', { name: 'Achternaam' })}`),
    emailAddress: Yup.string()
      .email(`${t('validation:invalid-email')}`)
      .required(`${t('validation:required', { name: 'Email' })}`),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, `${t('validation:invalid-phone')}`)
      .required(`${t('validation:required', { name: 'Telefoonnummer' })}`),
    company: Yup.string()
      .min(2, `${t('validation:minimum', { name: 'Bedrijfsnaam' })}`)
      .max(50, `${t('validation:maximum', { name: 'Bedrijfsnaam' })}`)
      .required(`${t('validation:required', { name: 'Bedrijfsnaam' })}`)
  });

  const formik = useFormik({
    initialValues: initRegisterUser,
    validationSchema: registerSchema,
    onSubmit: (values, { setSubmitting }) => {
      registerService.mutate(
        values, {
          onSuccess: () => {
            setMessage({
              message: t('messages.signup-success'),
              type: 'success',
              open: true
            });
            history.push('/gebruikers');
          },
          onError: () => {
            setSubmitting(false);
            setMessage({
              message: t('messages.signup-error'),
              type: 'error',
              open: true
            });
          }
        }
      );
    }
  });

  return (
    <LoginLayout>
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{
          marginX: { xs: 2, sm: 10 }
        }}
      >
        <Stack
          gap={2}
          sx={{
            marginTop: 5,
            minWidth: { xs: '300px', sm: '340px' },
            width: { xs: '100%', sm: 'unset' }
          }}
        >
          <Typography variant="h2" sx={{ textAlign: 'center', marginBottom: 2 }}>
            {t('request-account')}
          </Typography>
          <TextFieldShrink
            fullWidth
            label={`${t('common:first-name')}`}
            name="firstName"
            error={formik.errors.firstName !== undefined && formik.touched.firstName}
            helperText={formik.errors.firstName}
            onChange={formik.handleChange}
            value={formik.values.firstName}
            disabled={formik.isSubmitting}
          />
          <TextFieldShrink
            fullWidth
            label={`${t('common:last-name')}`}
            value={formik.values.lastName}
            name="lastName"
            error={formik.errors.lastName !== undefined && formik.touched.lastName}
            helperText={formik.errors.lastName}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
          />
          <TextFieldShrink
            fullWidth
            label={`${t('common:email')}`}
            value={formik.values.emailAddress}
            name="emailAddress"
            error={formik.errors.emailAddress !== undefined && formik.touched.emailAddress}
            helperText={formik.errors.emailAddress}
            onChange={(e: React.ChangeEvent<any>)=>{
              formik.handleChange(e);
              formik.setFieldValue('userName', e.target.value);
            }}
            disabled={formik.isSubmitting}
          />
          <TextFieldShrink
            fullWidth
            label={`${t('common:phone')}`}
            value={formik.values.phoneNumber}
            name="phoneNumber"
            error={formik.errors.phoneNumber !== undefined && formik.touched.phoneNumber}
            helperText={formik.errors.phoneNumber}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
          />
          <TextFieldShrink
            fullWidth
            label={`${t('common:company-name')}`}
            value={formik.values.company}
            name="company"
            error={formik.errors.company !== undefined && formik.touched.company}
            helperText={formik.errors.company}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={()=>{
              formik.handleSubmit();
            }}
          >
            {t('common:button.request')}
          </Button>
          <Button fullWidth onClick={ ()=>{ history.push('/login'); }}>
            {t('common:button.return')}
          </Button>
        </Stack>
      </Box>
    </LoginLayout>
  );
}
