import { SearchOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from '../../theme';

interface EmptyCartProps {
  title?: string | null;
}

export default function EmptyCart({ title }: EmptyCartProps) {
  const { t } = useTranslation(['checkout']);
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 150,
      flexDirection: 'column',
      marginBottom: 10
    }}>
      <SearchOff sx={{
        fontSize: 200,
        color: colors.blue
      }} />
      <Typography variant="subtitle1">{title ? title : t('checkout:no-items')}</Typography>
    </Box>
  );
}
